import React from 'react';
import styles from './loading.module.scss';

const Loading = () => (
  <svg
    width={100}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140.32 138.18"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M32.74,121.06l1.17.74Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M35.21,122.58l1.25.71Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M30.32,119.37c.31.24.64.45,1,.68C31,119.82,30.64,119.61,30.32,119.37Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M19.48,108.72l.43.54Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M17.54,106l.6.87C17.94,106.6,17.73,106.31,17.54,106Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M38.74,124.47l-1-.48c.31.16.62.34.94.49Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M21.63,111.35l.16.18Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'none' }}
          d="M16.49,104.41l-.41-.67h0C16.21,104,16.36,104.19,16.49,104.41Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M19.91,109.26c.54.69,1.11,1.39,1.72,2.09C21,110.67,20.47,110,19.91,109.26Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M27.93,117.51c.8.66,1.6,1.28,2.39,1.86C29.51,118.77,28.71,118.15,27.93,117.51Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M18.14,106.89c.42.6.87,1.21,1.34,1.83C19,108.12,18.57,107.51,18.14,106.89Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          id={styles.streak3}
          d="M105.18,65.68a2.77,2.77,0,0,0-3.93-3.91l-62.51,62.7a53.71,53.71,0,0,0,5.5,2.33Q74.72,96.25,105.18,65.68Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M16.49,104.41c.33.53.68,1.06,1.05,1.61C17.18,105.49,16.83,105,16.49,104.41Z"
        />
        <path
          className="cls-2"
          id="streak1"
          style={{ fill: '#ef5223' }}
          d="M94.89,24.69A2.77,2.77,0,1,0,91,20.78L13.35,98.63a54.09,54.09,0,0,0,2.73,5.11Z"
        />
        <path
          className="cls-2"
          id="streak2"
          style={{ fill: '#ef5223' }}
          d="M27.93,117.51l93.85-94.13a2.77,2.77,0,0,0-3.93-3.91l-94,94.28c-.71-.72-1.39-1.46-2.06-2.22A53.9,53.9,0,0,0,27.93,117.51Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M36.46,123.29c.45.25.89.47,1.33.7Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M31.28,120.05c.49.34,1,.69,1.46,1Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M33.91,121.8l1.3.78Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          d="M116.41,75.6s0,.05,0,.08a54.24,54.24,0,0,1-54.09,54.25,53.13,53.13,0,0,1-9.32-.81,52.54,52.54,0,0,1-5.64-1.31c-1.24-.36-2.29-.71-3.13-1a53.71,53.71,0,0,1-5.5-2.33h0c-.32-.15-.63-.33-.94-.49s-.88-.45-1.33-.7l-1.25-.71-1.3-.78-1.17-.74c-.48-.32-1-.67-1.46-1-.32-.23-.65-.44-1-.68-.79-.58-1.59-1.2-2.39-1.86a53.9,53.9,0,0,1-6.14-6l-.16-.18c-.61-.7-1.18-1.4-1.72-2.09l-.43-.54c-.47-.62-.92-1.23-1.34-1.83l-.6-.87c-.37-.55-.72-1.08-1.05-1.61-.13-.22-.28-.43-.42-.66h0a54.09,54.09,0,0,1-2.73-5.11c-.25-.53-.59-1.26-.95-2.15a55,55,0,0,1-2.79-8.72A62.25,62.25,0,0,1,8.25,75.68,54,54,0,0,1,71,22.13L77.41,15a62.52,62.52,0,1,0,47.26,60.65,63.41,63.41,0,0,0-.43-7.2Z"
        />
        <path
          className="cls-2"
          id="bullet3"
          style={{ fill: '#ef5223' }}
          d="M107.79,52.71a6.68,6.68,0,1,0,6.68-6.88A6.79,6.79,0,0,0,107.79,52.71Z"
        />
        <path
          className="cls-2"
          style={{ fill: '#ef5223' }}
          id="bullet1"
          d="M105,13.76a6.79,6.79,0,0,0,6.68-6.88,6.68,6.68,0,1,0-13.36,0A6.79,6.79,0,0,0,105,13.76Z"
        />
        <path
          className="cls-2"
          id="bullet2"
          style={{ fill: '#ef5223' }}
          d="M133.64,1A6.79,6.79,0,0,0,127,7.85a6.68,6.68,0,1,0,13.35,0A6.79,6.79,0,0,0,133.64,1Z"
        />
      </g>
    </g>
  </svg>
);

export default Loading;
