import React, { useState, useEffect, useContext } from 'react';
import { number, PropTypes } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { UserContext } from '../../providers/UserProvider';
import styles from './ClubSchedules.module.scss';
import RosterItem from '../../components/RosterItem/RosterItem';
import {
  createActivity,
  createAttendee,
  createNotGoing,
  listActivities,
  listActivitiesOfType,
  listAttendees,
  deleteActivity,
} from '../../services/club/activity';
import Accordion from '../../components/Activities/Accordion';

import { getRoles } from '../../services/club/membership';
import { getClub } from '../../services/club/club';
import NewActivityForm from '../../components/Activities/NewActivityForm';
import { readDate } from '../../services/util/formatters';
import JsonImportForm from '../../components/Activities/JsonImportForm';
import ScorecardModal from '../../components/Activities/ScorecardModal';

function ClubSchedules({ match }) {
  const { cid } = match.params;
  const [club, setClub] = useState(null);

  const location = useLocation();
  const { pathname, search } = location;
  const query = new URLSearchParams(search);
  const [scorecardModalShow, setScorecardModalShow] = useState(false);
  const [rsvpModalShow, setRsvpModalShow] = useState(false);
  const [dataForRsvpModal, setDataForRsvpModal] = useState({
    title: '',
    type: '',
  });

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({
    title: '',
    type: '',
    aid: '',
    cid: '',
  });

  const [dataForScorecardModal, setDataForScorecardModal] = useState({
    aid: '',
    uid: '',
    cid: '',
  });

  const [user] = useContext(UserContext);
  // The roles for the current user
  const [roles, setRoles] = useState(null);

  // Get user's club roles
  useEffect(() => {
    getRoles(cid, user.uid).then((result) => {
      if (result.data) {
        setRoles(result.data);
      } else {
        setRoles([]);
        console.error(result.error);
      }
    });
  }, [user.uid]);

  // Get club information
  useEffect(() => {
    getClub(cid).then((result) => {
      if (result.data) {
        setClub(result.data);
      } else {
        setClub({});
        console.error(result.error);
      }
    });
  }, []);

  const tabs = [
    {
      id: 0,
      name: 'Schedule',
    },
    {
      id: 1,
      name: 'Practices',
    },
    {
      id: 2,
      name: 'Past Activities',
    },
  ];

  // {
  //   id: 2,
  //   name: 'Competitions',
  // },
  // {
  //   id: 3,
  //   name: 'Not Going',
  // },
  return (
    roles &&
    club && (
      <div className={styles.clubschedules}>
        {/* Club Schedules hero */}
        <div className={styles.clubschedules_hero}>
          {/* Breadcrumbs */}
          <p>
            /{' '}
            <Link to={`${pathname.replace('/club-schedules', '')}`}>
              {club.clubName} Home
            </Link>{' '}
            / <span>Schedules</span>
          </p>
          <h1>Schedules</h1>
        </div>
        {/* Club Schedules body */}
        <div id="clubSchedulesBody" className={styles.clubschedules_body}>
          <Tabs
            defaultIndex={parseInt(query.get('tab'), 10) || 0}
            selectedTabClassName={styles.clubschedules_body_tab__selected}
          >
            <TabList className={styles.clubschedules_body_tablist}>
              {tabs.map((tab) => (
                <Tab key={tab.id} className={styles.clubschedules_body_tab}>
                  <Link
                    to={`${pathname}?tab=${tab.id}`}
                    key={tab.id}
                    className={styles.clubschedules_body_tab_link}
                  >
                    {tab.name}
                  </Link>
                </Tab>
              ))}
            </TabList>

            {/* Club Schedule */}
            <TabPanel>
              <ClubSchedule
                cid={cid}
                club={club}
                roles={roles}
                setRsvpModalShow={setRsvpModalShow}
                setDataForRsvpModal={setDataForRsvpModal}
                scorecardModalShow={scorecardModalShow}
                setScorecardModalShow={setScorecardModalShow}
                dataForScorecardModal={dataForScorecardModal}
                setDataForScorecardModal={setDataForScorecardModal}
                setDeleteModalData={setDeleteModalData}
                setDeleteModalShow={setDeleteModalShow}
              />
            </TabPanel>
            {/* Practices */}
            <TabPanel>
              <Practices
                cid={cid}
                user={user}
                club={club}
                roles={roles}
                setRsvpModalShow={setRsvpModalShow}
                setDataForRsvpModal={setDataForRsvpModal}
                scorecardModalShow={scorecardModalShow}
                setScorecardModalShow={setScorecardModalShow}
                dataForScorecardModal={dataForScorecardModal}
                setDataForScorecardModal={setDataForScorecardModal}
                setDeleteModalData={setDeleteModalData}
                setDeleteModalShow={setDeleteModalShow}
              />
            </TabPanel>
            <TabPanel>
              <ClubSchedule
                past
                cid={cid}
                user={user}
                club={club}
                roles={roles}
                setRsvpModalShow={setRsvpModalShow}
                setDataForRsvpModal={setDataForRsvpModal}
                scorecardModalShow={scorecardModalShow}
                setScorecardModalShow={setScorecardModalShow}
                dataForScorecardModal={dataForScorecardModal}
                setDataForScorecardModal={setDataForScorecardModal}
                setDeleteModalData={setDeleteModalData}
                setDeleteModalShow={setDeleteModalShow}
              />
            </TabPanel>
            {/* Competitions */}
            {/* <TabPanel>
              <Competitions cid={cid} />
            </TabPanel> */}
            {/* Not Going */}
            {/* <TabPanel>
              <NotGoing cid={cid} club={club} roles={roles} />
            </TabPanel> */}
          </Tabs>
        </div>
        {/* Invite Modal */}
        <ScorecardModal
          uid={user.uid}
          cid={cid}
          disciplines={club.disciplines}
          scorecardModalShow={scorecardModalShow}
          setScorecardModalShow={setScorecardModalShow}
          dataForScorecardModal={dataForScorecardModal}
        />
        <Modal
          show={rsvpModalShow}
          onHide={() => setRsvpModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header className="d-flex flex-column">
            <div className="d-flex justify-content-between w-100">
              <Modal.Title as="h3" className={styles.invite_modal_title}>
                {dataForRsvpModal.title !== 'undefined'
                  ? dataForRsvpModal.title
                  : 'Change activity attendance'}
              </Modal.Title>
              <Button
                variant="outline-danger"
                onClick={() => setRsvpModalShow(false)}
              >
                Close
              </Button>
            </div>
            <div className="d-flex justify-content-start w-100">
              {`${dataForRsvpModal.month} ${dataForRsvpModal.day}`}
            </div>
          </Modal.Header>
          <Modal.Body>
            <form>
              <label htmlFor="inviteName" className={styles.invite_form_label}>
                Attendance
              </label>
              <select
                className={styles.activity_form_input}
                type="text"
                name="isAttending"
                id="isAttending"
                onChange={() => {
                  const reasonFeild = document.getElementById('reasonArea');
                  const select = document.getElementById('isAttending');

                  if (select.value === 'true') {
                    reasonFeild.style.display = 'none';
                  } else {
                    reasonFeild.style.display = 'block';
                  }
                }}
              >
                <option value="false">Not Going</option>
                <option value="true">Going</option>
              </select>
              <div id="reasonArea">
                <label className={styles.invite_form_label} htmlFor="reason">
                  Reason
                </label>
                <textarea
                  className={styles.activity_form_textarea}
                  name="reason"
                  id="reason"
                  rows="4"
                />
              </div>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  const isAttending = document.getElementById('isAttending')
                    .value;
                  console.log('IS ATTENDING', isAttending);
                  dataForRsvpModal.handleRsvpChange(isAttending);
                  const reason = document.getElementById('reason').value;
                  if (isAttending === 'true') {
                    createAttendee(
                      cid,
                      dataForRsvpModal.aid,
                      user.uid,
                      user.displayName,
                      true,
                      null,
                      roles
                    ).then((res) => {
                      console.log('CREATE ATTENDEE ', res);
                      setRsvpModalShow(false);
                      dataForRsvpModal.getAttendanceList();
                    });
                  } else if (isAttending === 'false') {
                    if (reason.length > 0) {
                      createAttendee(
                        cid,
                        dataForRsvpModal.aid,
                        user.uid,
                        user.displayName,
                        false,
                        reason,
                        roles
                      ).then(() => {
                        setRsvpModalShow(false);
                        dataForRsvpModal.getAttendanceList();
                      });
                    } else {
                      alert('Please provide a reason for your absence');
                    }
                  }
                }}
              >
                SAVE
              </button>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header className="d-flex flex-column">
            <div className="d-flex justify-content-between w-100">
              <Modal.Title as="h3" className={styles.invite_modal_title}>
                Delete &quot;
                {deleteModalData.title !== 'undefined'
                  ? deleteModalData.title
                  : 'Change activity attendance'}
                &quot;?
              </Modal.Title>
              <Button
                variant="outline-danger"
                onClick={() => setDeleteModalShow(false)}
              >
                Close
              </Button>
            </div>
            <div className="d-flex justify-content-start w-100">
              {`${deleteModalData.month} ${deleteModalData.day}`}
            </div>
          </Modal.Header>
          <Modal.Body>
            <form className="d-flex justify-content-around">
              <Button
                variant="secondary"
                onClick={() => setDeleteModalShow(false)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deleteActivity(deleteModalData.cid, deleteModalData.aid).then(
                    (res) => {
                      if (!res.error) {
                        setDeleteModalShow(false);
                        deleteModalData.handleDeletedActivity(
                          deleteModalData.aid
                        );
                      }
                    }
                  );
                }}
              >
                Delete
              </Button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  );
}

ClubSchedules.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cid: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function ClubSchedule({
  cid,
  club,
  roles,
  scorecardModalShow,
  setScorecardModalShow,
  setRsvpModalShow,
  setDataForRsvpModal,
  setDataForScorecardModal,
  dataForScorecardModal,
  setDeleteModalShow,
  setDeleteModalData,
  past = null,
}) {
  const [activities, setActivities] = useState(null);
  const [lastActivity, setLastActivity] = useState(null);
  const [pastActivityPages, setpastActivityPages] = useState(1);

  let ACTIVITY_LIMIT = 5;

  // Modal state
  const [modalShow, setModalShow] = useState(false);
  const [jsonModalShow, setJsonModalShow] = useState(false);
  const [user] = useContext(UserContext);

  if (past) {
    ACTIVITY_LIMIT *= pastActivityPages;
  }

  function loadMoreActivities() {
    listActivities(cid, lastActivity, ACTIVITY_LIMIT, past).then((result) => {
      if (result.data) {
        console.log('result.data', result.data, activities);
        setActivities({ ...activities, ...result.data });
      } else if (result.error) {
        console.error(result.error);
      }
      setLastActivity(result.first);
    });
  }

  useEffect(() => {
    loadMoreActivities();
  }, [pastActivityPages]);

  useEffect(() => {
    listActivities(cid, null, ACTIVITY_LIMIT, past).then((result) => {
      if (result.data) {
        setActivities(result.data);
        console.log('resutlt last', result.last);
      } else if (result.error) {
        console.error(result.error);
      }
      setLastActivity(result.last);
    });
  }, []);

  let sortedActivities;

  if (activities) {
    const activityArray = Object.keys(activities).map((key) =>
      Object.assign(activities[key], { aid: key })
    );
    if (!past) {
      sortedActivities = activityArray.sort(
        (a, b) => readDate(a.startTime) - readDate(b.startTime)
      );
    } else {
      sortedActivities = activityArray.sort(
        (a, b) => readDate(b.startTime) - readDate(a.startTime)
      );
    }
  }
  return (
    <>
      {roles.some((role) => ['owner', 'admin'].includes(role)) && (
        <div className={styles.clubschedules_invite_wrapper}>
          <RosterItem
            title="CREATE A NEW CLUB ACTIVITY"
            isButton
            action={() => setModalShow(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </RosterItem>
        </div>
      )}
      {roles.some((role) => ['admin'].includes(role)) && (
        <div className={styles.clubschedules_invite_wrapper}>
          <RosterItem
            title="IMPORT JSON ACTIVITIES"
            isButton
            action={() => setJsonModalShow(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </RosterItem>
        </div>
      )}
      <section>
        <h2 className="h3">Activities</h2>
        <div className={`${styles.activityGrid} d-none d-sm-grid`}>
          <div className={styles.activityHeadingRow}>
            <div>DATE</div>
            <div>EVENT</div>
            <div>LOCATION</div>
            <div>VENUE</div>
            <div></div>
            <div></div>
          </div>
        </div>
        {activities &&
          (Object.keys(activities).length > 0 ? (
            sortedActivities.map((activity) => (
              <Accordion
                uid={user.uid}
                cid={cid}
                key={activity.aid}
                id={activity.aid}
                roles={roles}
                type={activity.type}
                paymentRequired={activity.paymentRequired}
                start={activity.startTime}
                end={activity.endTime}
                location={activity.location}
                title={activity.title}
                scorecardModalShow={scorecardModalShow}
                dataForScorecardModal={dataForScorecardModal}
                setRsvpModalShow={setRsvpModalShow}
                setScorecardModalShow={setScorecardModalShow}
                setDataForRsvpModal={setDataForRsvpModal}
                setDataForScorecardModal={setDataForScorecardModal}
                setDeleteModalShow={setDeleteModalShow}
                setDeleteModalData={setDeleteModalData}
                activities={activities}
                setActivities={setActivities}
              />
            ))
          ) : (
            <p>No activities found</p>
          ))}
        <div className="d-flex justify-content-center">
          {console.log('lastActivity', lastActivity)}
          {lastActivity !== 0 && lastActivity !== null && !past && (
            <Button onClick={() => loadMoreActivities()}>Load More</Button>
          )}
          {past &&
            activities &&
            Object.keys(activities).length >= ACTIVITY_LIMIT && (
              <Button
                onClick={() => setpastActivityPages(pastActivityPages + 1)}
              >
                Load More
              </Button>
            )}
        </div>
      </section>
      {/* Activity Modal */}
      {roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title as="h3" className={styles.invite_modal_title}>
              Create a New Club Activity
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(false)}
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <NewActivityForm
              cid={cid}
              setModalShow={setModalShow}
              activities={activities}
              setActivities={setActivities}
            />
          </Modal.Body>
        </Modal>
      )}
      {/* Json Import Modal */}
      {roles.some((role) => ['admin'].includes(role)) && (
        <Modal
          show={jsonModalShow}
          onHide={() => setJsonModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title as="h3" className={styles.invite_modal_title}>
              Import Using JSON
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setJsonModalShow(false)}
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <JsonImportForm
              cid={cid}
              setJsonModalShow={setJsonModalShow}
              activities={activities}
              setActivities={setActivities}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

ClubSchedule.propTypes = {
  past: PropTypes.bool,
  cid: PropTypes.string.isRequired,
  club: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  scorecardModalShow: PropTypes.bool.isRequired,
  setScorecardModalShow: PropTypes.any.isRequired,
  setRsvpModalShow: PropTypes.any.isRequired,
  setDataForRsvpModal: PropTypes.any.isRequired,
  dataForScorecardModal: PropTypes.any.isRequired,
  setDataForScorecardModal: PropTypes.any.isRequired,
  setDeleteModalShow: PropTypes.any.isRequired,
  setDeleteModalData: PropTypes.any.isRequired,
};

ClubSchedule.defaultProps = {
  past: null,
};

function Practices({
  cid,
  user,
  club,
  roles,
  scorecardModalShow,
  setScorecardModalShow,
  setRsvpModalShow,
  setDataForRsvpModal,
  setDataForScorecardModal,
  dataForScorecardModal,
  setDeleteModalShow,
  setDeleteModalData,
}) {
  const [practices, setPractices] = useState(null);
  const [lastActivity, setLastActivity] = useState(null);

  useEffect(() => {
    listActivitiesOfType(cid, 'practice').then((result) => {
      if (result.data) {
        setLastActivity(result.last);
        setPractices(result.data);
      } else if (result.error) {
        console.error(result.error);
      }
    });
  }, []);

  function loadMoreActivities() {
    listActivities(cid, lastActivity, 5).then((result) => {
      if (result.data) {
        console.log('result.data', result.data, practices);
        setPractices({ ...practices, ...result.data });

        // Get rid of the button if no new results were found
        if (
          result.data[
            Object.keys(result.data)[Object.keys(result.data).length - 1]
          ].aid ===
          practices[Object.keys(practices)[Object.keys(practices).length - 1]]
            .aid
        ) {
          setLastActivity(null);
        } else {
          setLastActivity(result.last);
        }
      } else if (result.error) {
        setLastActivity(result.last);
        console.error(result.error);
      }
    });
  }

  return (
    <section>
      <h2 className="h3">Practices</h2>
      <div className={`${styles.activityGrid} d-none d-sm-grid`}>
        <div className={styles.activityHeadingRow}>
          <div>DATE</div>
          <div>EVENT</div>
          <div>LOCATION</div>
          <div>VENUE</div>
          <div></div>
          <div></div>
        </div>
      </div>
      {practices &&
        (Object.keys(practices).length > 0 ? (
          Object.keys(practices).map((aid) => {
            const practice = practices[aid];
            return (
              <Accordion
                uid={user.uid}
                cid={cid}
                key={aid}
                id={aid}
                roles={roles}
                type={practice.type}
                paymentRequired={practice.paymentRequired}
                start={practice.startTime}
                end={practice.endTime}
                location={practice.location}
                title={practice.title}
                scorecardModalShow={scorecardModalShow}
                dataForScorecardModal={dataForScorecardModal}
                setRsvpModalShow={setRsvpModalShow}
                setScorecardModalShow={setScorecardModalShow}
                setDataForRsvpModal={setDataForRsvpModal}
                setDataForScorecardModal={setDataForScorecardModal}
                setDeleteModalShow={setDeleteModalShow}
                setDeleteModalData={setDeleteModalData}
                activities={practices}
                setActivities={setPractices}
              />
            );
          })
        ) : (
          <p>No practices found</p>
        ))}
      <div className="d-flex justify-content-center">
        {console.log('lastActivity', lastActivity)}
        {lastActivity !== 0 && lastActivity !== null && (
          <Button onClick={() => loadMoreActivities()}>Load More</Button>
        )}
      </div>
    </section>
  );
}

Practices.propTypes = {
  cid: PropTypes.string.isRequired,
  user: PropTypes.any.isRequired,
  club: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  scorecardModalShow: PropTypes.bool.isRequired,
  setScorecardModalShow: PropTypes.any.isRequired,
  setRsvpModalShow: PropTypes.any.isRequired,
  setDataForRsvpModal: PropTypes.any.isRequired,
  dataForScorecardModal: PropTypes.any.isRequired,
  setDataForScorecardModal: PropTypes.any.isRequired,
  setDeleteModalShow: PropTypes.any.isRequired,
  setDeleteModalData: PropTypes.any.isRequired,
};

function Competitions({ cid }) {
  const [competitions, setCompetitions] = useState(null);

  useEffect(() => {
    // TODO: List competitions
    setCompetitions([]);
  }, []);

  return (
    <>
      <section>
        <h2 className="h3">Competitions</h2>
        {competitions &&
          (Object.keys(competitions).length > 0 ? (
            Object.keys(competitions).map((aid) => {
              const competition = competitions[aid];
              return <div key={aid}>{competition}</div>;
            })
          ) : (
            <p>No competitions found</p>
          ))}
      </section>
    </>
  );
}

Competitions.propTypes = {
  cid: PropTypes.string.isRequired,
};

function NotGoing({ cid, club, roles }) {
  const [activities, setActivities] = useState([]);
  const [notGoings, setNotGoings] = useState([]);

  useEffect(() => {
    // TODO: List activities and their notGoings
    setActivities([]);
    setNotGoings([]);
  }, []);

  return (
    <section>
      <h2 className="h3">Activities</h2>
      {activities &&
        (Object.keys(activities).length > 0 ? (
          Object.keys(activities).map((aid) => {
            const activity = activities[aid];
            return (
              <div key={aid}>
                {activity}
                <h3 className="h4">Not Goings</h3>
                {notGoings &&
                  (Object.keys(notGoings).length > 0 ? (
                    Object.keys(notGoings).map((uid) => {
                      const notGoing = notGoings[uid];
                      return <div key={aid}>{notGoing}</div>;
                    })
                  ) : (
                    <p>No not going submissions found</p>
                  ))}
              </div>
            );
          })
        ) : (
          <p>No activities found</p>
        ))}
    </section>
  );
}

NotGoing.propTypes = {
  cid: PropTypes.string.isRequired,
  club: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

export default ClubSchedules;
