import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styles from '../../pages/ClubSchedules/ClubSchedules.module.scss';
import { createActivity } from '../../services/club/activity';
import { listClubTeamsByStatus } from '../../services/club/team';
import { toDateTimeLocalString } from '../../services/util/formatters';
import { Disciplines, Associations } from '../../services/util/enum';

const NewActivityForm = (data) => {
  const { setModalShow, cid, activities, setActivities } = data;

  const today = toDateTimeLocalString(new Date());

  const [activity, setActivityData] = useState({
    title: '',
    startTime: today,
    endTime: today,
    location: '',
    parkName: '',
    city: '',
    state: 'AL',
    street: '',
    zip: '',
    type: 'practice', // Competition/Practice
    mandatory: false, // True = Yes
    cost: null,
    associations: {},
    squadingComplete: null, // True = Green
    paymentCollected: null, // True = Green, unneeded for practices
    attendeeCount: null, // Count in attendees subtable
  });

  // Teams State
  const [teams, setTeams] = useState(null);
  const [checkedTids, setCheckedTids] = useState([]);
  const [checkedAssociations, setCheckedAssociations] = useState([]);

  useEffect(() => {
    listClubTeamsByStatus(cid, true).then((result) => {
      if (result.data) {
        setTeams(result.data);
      } else if (result.error) {
        setTeams([]);
        console.error(result.error);
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Get the selected teams
    const teamDocs = [];

    checkedTids.forEach((tid) => {
      teamDocs.push(teams[tid]);
    });

    // Create temp activity to throw on page before next DB call
    const newActivity = {
      cid,
      title: activity.title,
      startTime: activity.startTime,
      endTime: activity.endTime,
      location: {
        parkName: activity.parkName,
        city: activity.city,
        state: activity.state,
        street: activity.street,
        zip: activity.zip,
      },
      type: activity.type, // Competition/Practice
      mandatory: false, // True = Yes
      cost: null,
      associations: checkedAssociations,
      squaddingComplete: null, // True = Green
      paymentCollected: null, // True = Green, unneeded for practices
      attendeeCount: null, // Count in attendees subtable
      requiredTids: checkedTids,
      requiredTeams: teamDocs,
    };

    console.log({ activity, newActivity });

    createActivity(newActivity).then((res) => {
      if (res.error) {
        console.error('An error occurred while creating activity: ', res.error);
      } else {
        // Temporarily throw an object on the page
        if (activities) {
          setActivities(Object.assign(activities, { [res.data]: newActivity }));
        } else {
          setActivities({ [res.data]: newActivity });
        }
        setModalShow(false);
      }
    });
  };

  const onChange = (e) => {
    // Check if the input changed was a checkbox
    if (e.target.type === 'checkbox') {
      // It it was, assign its value in the association object
      const newAssociations = Object.assign(activity.associations, {
        [e.target.name]: e.target.checked,
      });
      setActivityData((prevState) => ({
        ...prevState,
        associations: newAssociations,
      }));
    } else {
      setActivityData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <label htmlFor="title" className={styles.activity_form_label}>
            Activity Name
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="title"
            id="title"
            required
            onChange={onChange}
            value={activity.title}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label
            htmlFor="startTime"
            required
            className={styles.activity_form_label}
          >
            Start Time
          </label>
          <input
            className={styles.activity_form_input}
            type="datetime-local"
            required
            name="startTime"
            id="startTime"
            value={activity.startTime}
            onChange={onChange}
          />
        </div>
        <div className="col">
          <label htmlFor="endTime" className={styles.activity_form_label}>
            End Time
          </label>
          <input
            className={styles.activity_form_input}
            type="datetime-local"
            name="endTime"
            id="endTime"
            value={activity.endTime}
            onChange={onChange}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="parkName" className={styles.activity_form_label}>
            Venue Name
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="parkName"
            required
            id="parkName"
            value={activity.parkName}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="city" className={styles.activity_form_label}>
            City
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="city"
            required
            id="city"
            value={activity.city}
            onChange={onChange}
          />
        </div>
        <div className="col">
          <label htmlFor="state" className={styles.activity_form_label}>
            State
          </label>
          <select
            onChange={onChange}
            name="state"
            value={activity.state}
            className={styles.activity_form_input}
            id="state"
          >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="street" className={styles.activity_form_label}>
            Street
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="street"
            required
            id="street"
            value={activity.street}
            onChange={onChange}
          />
        </div>
        <div className="col-4">
          <label htmlFor="zip" className={styles.activity_form_label}>
            Zip
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            pattern="[0-9]{5}"
            name="zip"
            required
            id="zip"
            value={activity.zip}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="type" className={styles.activity_form_label}>
            Type
          </label>
          <select
            name="type"
            value={activity.type}
            onChange={onChange}
            id="type"
            className={styles.activity_form_input}
          >
            <option key="newActivitypracice" value="practice">
              Practice
            </option>
            {Disciplines.values().map((discipline) => (
              <option key={`newActivity${discipline}`} value={discipline}>
                {Disciplines[discipline].name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="associations" className={styles.activity_form_label}>
            Associations
          </label>
        </div>
      </div>
      {Associations.values().map((association) => (
        <Form.Check
          key={`newActivity${association}`}
          inline
          type="checkbox"
          className={styles.activity_form_input_checkbox}
          label={Associations[association].name}
          checked={checkedAssociations.includes(association)}
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedAssociations([...checkedAssociations, association]);
            } else {
              setCheckedAssociations(
                checkedAssociations.filter((id) => id !== association)
              );
            }
          }}
        />
      ))}
      <div className="row">
        <div className="col">
          <label
            htmlFor="associations"
            className={styles.activity_form_label}
            style={{ marginTop: '1em' }}
          >
            Select Required Teams
          </label>
        </div>
      </div>
      {teams &&
        (Object.keys(teams).length > 0 ? (
          Object.keys(teams).map((tid) => {
            const team = teams[tid];
            return (
              <Form.Check
                key={tid}
                inline
                type="checkbox"
                className={styles.activity_form_input_checkbox}
                label={team.name}
                checked={checkedTids.includes(tid)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckedTids([...checkedTids, tid]);
                  } else {
                    setCheckedTids(checkedTids.filter((id) => id !== tid));
                  }
                }}
              />
            );
          })
        ) : (
          <p>No teams found in the club.</p>
        ))}
      <button
        className={styles.activity_form_button}
        type="submit"
        onClick={handleSubmit}
      >
        CREATE CLUB ACTIVITY
      </button>
    </form>
  );
};

export default NewActivityForm;
