import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { doSignInWithEmailAndPassword } from '../../services/auth';
import styles from './SignIn.module.scss';
import shotdeckSplash from '../../images/DashboardHero@2x.png';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'userEmail') {
      setEmail(value);
    } else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  return (
    <div className={styles.login}>
      {/* Login hero */}
      <div className={styles.login_hero_wrapper}>
        <div>
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
              stroke="white"
              strokeWidth="1"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </Link>
        </div>
        <div className="d-flex flex-grow-1 justify-content-center">
          <div
            className={styles.login_hero}
            // style={{ backgroundImage: `url(${shotdeckSplash})` }}
          />
        </div>
      </div>
      {/* Login form */}
      <div className={styles.login_form}>
        <label htmlFor="userEmail" className={styles.login_form_label}>
          Email:
        </label>
        <div className={styles.login_form_input_group}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            id="svg"
            className={`${styles.login_form_input_icon} bi bi-envelope`}
            viewBox="0 0 16 16"
          >
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
          </svg>
          <input
            className={styles.login_form_input}
            type="email"
            name="userEmail"
            value={email}
            placeholder="Email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
        </div>
        <label htmlFor="userPassword" className={styles.login_form_label}>
          Password:
        </label>
        <div className={styles.login_form_input_group}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className={`${styles.login_form_input_icon} bi bi-lock`}
            viewBox="0 0 16 16"
          >
            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
          </svg>
          <input
            className={styles.login_form_input}
            type="password"
            name="userPassword"
            value={password}
            placeholder="Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
        </div>
        <div className={styles.login_form_pwreset_wrapper}>
          <Link className={styles.login_form_pwreset} to="password-reset">
            Forgot Password?
          </Link>
        </div>
        {error !== null && (
          <p align="center" className={styles.login_form_error}>
            {error}
          </p>
        )}
        {/* Login actions */}
        <button
          className={styles.login_form_button}
          type="button"
          onClick={(event) => {
            doSignInWithEmailAndPassword(event, email, password, setError);
          }}
        >
          LOG IN
        </button>
        <div className={styles.login_form_or}>or</div>
        <div className={styles.login_form_link_wrapper}>
          <Link className={styles.login_form_link} to="sign-up">
            SIGN UP
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
