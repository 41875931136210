import { firestore, currentTime } from '../../firebase';
import { listQuery } from '../util/pagination';

const CLUBS_REF = firestore.collection('clubs');

/**
 *
 * @param {String} cid club id -- TODO: rewrite this for when activites don't live under club
 * @param {String} aid activity id
 * @param {String} uid user id
 * @param {String} notes Notes on the score
 * @returns data, error
 */
export const createUpdateScorecard = async ({
  cid,
  aid,
  uid,
  events,
  notes = '',
  startingAverage = 0,
}) => {
  let data = null;
  let error = null;

  try {
    const docRef = await CLUBS_REF.doc(cid)
      .collection('activities')
      .doc(aid)
      .collection('scorecards')
      .doc(uid)
      .set(
        {
          cid: cid,
          aid: aid,
          uid: uid,
          dateTime: new Date(),
          events: events,
          notes: notes,
          startingAverage: startingAverage,
        },
        { merge: false }
      );
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
//  * Create Scores
//  * @param {String} aid activity Id
//  * @param {String} cid club Id
//  * @param {{}} scoreObjects scoreObjects JSON objects
//  * @returns data, error
//  */
// export const createScorecard = async (aid, cid, scoreObjects) => {
//   let data = null;
//   let error = null;

//   // Get a new write batch
//   const batch = firestore.batch();

//   const scoreDocs = {};

//   console.log('Trying to create', scoreObjects);

//   let dateTime = new Date();

//   scoreObjects.forEach((scoreObject) => {
//     const scoreRef = CLUBS_REF.doc(cid)
//       .collection('activities')
//       .doc(aid)
//       .collection('scores')
//       .doc();

//     const scoreDoc = {
//       cid: [scoreObject.cid], // Set as an array for future states where multiple clubs will be on one score
//       aid: scoreObject.aid,
//       uid: scoreObject.uid,
//       dateTime: dateTime,
//       scores: scoreObject.scores,
//       notes: scoreObject.notes || '',
//       startingAverage: scoreObject.startingAverage || 0,
//     };

//     // Add a second between writes, to keep the sequential order of things
//     dateTime = new Date(dateTime.getTime() + 1000);

//     batch.set(scoreRef, scoreDoc);
//   });

//   try {
//     await batch.commit();
//     data = scoreDocs;
//   } catch (err) {
//     error = err;
//   }

//   return { data, error };
// };

/**
 * Get Score
 * @param {String} cid Club Id
 * @param {String} aid Activity Id
 * @param {String} uid UserId
 * @returns data, error
 */
export const getScorecard = async (cid, aid, uid) => {
  let data;
  let error;

  await CLUBS_REF.doc(cid)
    .collection('activities')
    .doc(aid)
    .collection('scorecards')
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        data = doc.data();
        // console.log('Document data:', doc.data());
        return doc.data();
      }
      return doc.data();
      // doc.data() will be undefined in this case
      // console.log('No such document!');
    })
    .catch((err) => {
      console.log('Error getting document:', err);
      error = err;
    });

  return { data, error };
};

/**
 * delete a scorecard
 * @param {string} cid Club Id
 * @param {string} aid Activity Id
 * @param {string} uid User  ID
 */
export const deleteScorecard = async (cid, aid, uid) => {
  let error = null;

  try {
    await CLUBS_REF.doc(cid)
      .collection('activities')
      .doc(aid)
      .collection('scorecards')
      .doc(uid)
      .delete();
  } catch (err) {
    error = err;
  }

  return { error };
};
