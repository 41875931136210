import { firestore, currentTime } from '../../firebase';
import { listQuery, listQueryList } from '../util/pagination';

/**
 * @file Functions for handling club invites
 * Club invites user --> User (creates account) and then accepts/declines invite
 */

const CLUBS_REF = firestore.collection('clubs');

export const getInvite = async (cid, email) => {
  let data = null;
  let error = null;
  try {
    const invite = await CLUBS_REF.doc(cid)
      .collection('invites')
      .doc(email)
      .get();
    if (invite.exists) {
      data = invite.data();
    } else {
      throw new Error(
        `There was an error creating the invite.  Please try again`
      );
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

const listClubInvites = async (query) => {
  const { data, first, last, error } = await listQuery(
    query,
    'email',
    'invites'
  );

  return { data, first, last, error };
};

const listClubInvitesList = async (query) => {
  const { data, first, last, error } = await listQueryList(
    query,
    'email',
    'invites'
  );

  return { data, first, last, error };
};

/**
 * List pending club invites given a uid (for a user)
 * @param {string} email - the email of a user
 * @returns {object} { data, first, last, error }
 * @function */
export const listPendingClubInvitesForUser = async (email) => {
  const query = firestore
    .collectionGroup('invites')
    .where('email', '==', email)
    .where('status', '==', 'pending');

  return listClubInvitesList(query);
};

/**
 * List declined club pre-registrations given an email (for a user)
 * @param {string} email - the email of a user
 * @returns {object} { data, first, last, error }
 * @function */
export const listDeclinedClubInvitesForUser = async (email) => {
  const query = firestore
    .collectionGroup('invites')
    .where('email', '==', email)
    .where('status', '==', 'declined');

  return listClubInvitesList(query);
};

/**
 * List pending club invites for a given club (for club admins)
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listPendingClubInvites = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('invites')
    .where('status', '==', 'pending');

  return listClubInvites(query);
};

/**
 * List pending athlete club invites for a given club (for club admins)
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listPendingAthleteClubInvites = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('invites')
    .where('status', '==', 'pending')
    .where('roles', 'array-contains', 'athlete');

  return listClubInvites(query);
};

/**
 * List pending admin club invites for a given club (for club admins)
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listPendingAdminClubInvites = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('invites')
    .where('status', '==', 'pending')
    .where('roles', 'array-contains-any', ['coach', 'admin', 'owner']);

  return listClubInvites(query);
};

/**
 * List declined club invites for a given club (for club admins)
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listDeclinedClubInvites = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('invites')
    .where('status', '==', 'declined');

  return listClubInvites(query);
};

/**
 * Create an invite
 * @param {string} displayName - the name of the invited member
 * @param {string} email - email to use for the invite
 * @param {string} title - the title of the invitee (if club admin)
 * @param {array} roles - roles within the club
 * @param {string} cid - the id of a club
 * @param {string} clubId - the club identifier (slug)
 * @param {string} clubName - the name of the club
 * @param {string} logoUrl - the logo url of the club
 * @returns {object} { data, error }
 * @function */
export const createInvite = async ({
  displayName,
  email,
  title = 'Athlete',
  roles = ['athlete'],
  cid,
  clubId,
  clubName,
  logoUrl,
}) => {
  const invite = {
    displayName,
    email,
    title,
    roles,
    status: 'pending',
    cid,
    clubId,
    clubName,
    logoUrl,
  };

  let data = null;
  let error = null;

  const requestDoc = {
    ...invite,
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    await CLUBS_REF.doc(cid).collection('invites').doc(email).set(requestDoc);
    data = email;
  } catch (err) {
    error = err;
  }

  console.log(data);
  return { data, error };
};

/**
 * Accept an invite (invited user who now has an account/already had an account)
 * @param {string} cid - the id of a club
 * @param {string} email - the email of invitee
 * @param {string} uid - the id of the user (now with an account, add the uid to the document)
 * @returns {object} { data, error }
 * @function */
export const setInviteAccepted = async (cid, email, uid) => {
  let data = null;
  let error = null;

  const update = { uid, status: 'accepted', lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid).collection('invites').doc(email).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Decline an invite (invited user)
 * @param {string} cid - id of a club
 * @param {string} email - the email of invitee
 * @param {string} uid - id of an athlete
 * @returns {object} { data, error }
 * @function */
export const setInviteDeclined = async (cid, email, uid) => {
  let data = null;
  let error = null;

  const update = { uid, status: 'declined', lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid).collection('invites').doc(email).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};
