import { firestore, currentTime } from '../../firebase';
import { listQuery } from '../util/pagination';

/**
 * @file Functions for handling clubs
 */

const CLUBS_REF = firestore.collection('clubs');

/**
 * Get a club by id
 * @param {*} cid
 * @returns {object} { data, error }
 * @function */
export const getClub = async (cid) => {
  let data = null;
  let error = null;
  try {
    const profile = await CLUBS_REF.doc(cid).get();
    if (profile.exists) {
      data = profile.data();
    } else {
      throw new Error(`A club does not exist with id: ${cid}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Get a club by it's club identifier ("search" functionality)
 * @param {*} clubId
 * @returns {object} { data, error }
 * @function */
export const getClubByClubId = async (clubId) => {
  let data = null;
  let error = null;
  try {
    const query = await CLUBS_REF.where('clubId', '==', clubId).get();

    if (!query.empty) {
      const snapshot = query.docs[0];
      const cid = snapshot.id;
      data = { cid, ...snapshot.data() };
    } else {
      throw new Error(`A club does not exist with clubId: ${clubId}.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * List clubs given a query (ideal for pagination)
 * @param {*} query - the query to call and list clubs (discoverability?)
 * @returns {object} { data, first, last, error }
 * @function */
export const listClubs = async (query) => {
  const { data, first, last, error } = await listQuery(
    query,
    'clubId',
    'clubs'
  );
  return { data, first, last, error };
};

/**
 * Create a club (Currently disabled in security rules)
 * @param {string} clubId - the unique, human-readable club identifier
 * @param {string} clubName - club display name
 * @param {object} owner - { displayName, uid, photoUrl, email } - object of info for owner/creator of the club
 * @param {object} location - { name, street, city, state, zip } - object of info for location of the club
 * @param {string} logoUrl - logo for the club
 * @param {string[]} disciplines [...] - array of disciplines for the club
 * @param {string} introduction - the introduction tagline-y text for a club
 * @function */
export const createClub = async ({
  clubId,
  clubName,
  owner,
  location,
  logoUrl,
  disciplines,
  introduction = '',
}) => {
  let data = null;
  let error = null;

  const clubDoc = {
    clubId: clubId, // enforce unique (subdomain?) // Add to UI?
    clubName: clubName,
    owner: {
      displayName: owner.displayName,
      uid: owner.uid,
      photoUrl: owner.photoUrl,
      email: owner.email,
    },
    location: {
      street: location.street,
      city: location.city,
      state: location.state,
      zip: location.zip,
    }, // Add to UI?
    logoUrl: logoUrl,
    introduction: introduction,
    disciplines: disciplines,
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    // Check if there is a club with that ID already
    const query = await CLUBS_REF.where('clubId', '==', clubId).get();

    // If there is, throw error
    if (!query.empty) {
      throw new Error(`A club with the id "${clubId}" already exists`);
    } else {
      // Create the club
      const docRef = await CLUBS_REF.add(clubDoc);

      // Double-check that the club was created
      const newClub = await docRef.get();
      if (newClub.exists) {
        data = newClub.id;
      } else {
        throw new Error(`An error occurred for club: ${docRef.id}`);
      }
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update a club given an id
 * @param {string} cid
 * @param {object} updatedData - the updated data to be appended to a club
 * @returns {object} { data, error }
 * @function */
export const updateClub = async (
  cid,
  { clubId, clubName, owner, location, logoUrl, disciplines, introduction = '' }
) => {
  let data = null;
  let error = null;
  const clubDoc = {
    clubName: clubName,
    owner: {
      displayName: owner.displayName,
      uid: owner.uid,
      photoUrl: owner.photoUrl,
      email: owner.email,
    },
    location: {
      street: location.street,
      city: location.city,
      state: location.state,
      zip: location.zip,
    }, // Add to UI?
    logoUrl: logoUrl,
    introduction: introduction,
    disciplines: disciplines,
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    await CLUBS_REF.doc(cid).update({
      ...clubDoc,
      lastUpdated: currentTime,
    });
    data = clubDoc;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * delete a club given an id
 * @param {string} cid
 */
export const deleteClub = async (cid) => {
  let data = null;
  let error = null;

  try {
    await CLUBS_REF.doc(cid).delete();
  } catch (err) {
    error = err;
  }

  return { error };
};
