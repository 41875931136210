import { firestore, currentTime } from '../../firebase';
import { listQuery } from '../util/pagination';

/**
 * @file Functions for handling club pre-registrations
 * Athlete pre-registers --> Club accepts/declines
 */

const CLUBS_REF = firestore.collection('clubs');

const listClubPreRegistrations = async (query) => {
  const { data, first, last, error } = await listQuery(
    query,
    'uid',
    'preRegistrations'
  );

  return { data, first, last, error };
};

/**
 * List pending club pre-registrations given a uid (for an athlete)
 * @param {string} uid - the id of a user
 * @returns {object} { data, first, last, error }
 * @function */
export const listPendingClubPreRegistrationsForAthlete = async (uid) => {
  const query = firestore
    .collectionGroup('preRegistrations')
    .where('uid', '==', uid)
    .where('status', '==', 'pending');

  return listClubPreRegistrations(query);
};

/**
 * List declined club pre-registrations given a uid (for an athlete)
 * @param {string} uid - the id of a user
 * @returns {object} { data, first, last, error }
 * @function */
export const listDeclinedClubPreRegistrationsForAthlete = async (uid) => {
  const query = firestore
    .collectionGroup('preRegistrations')
    .where('uid', '==', uid)
    .where('status', '==', 'declined');

  return listClubPreRegistrations(query);
};

/**
 * List pending club pre-registrations for a given club (for club admins)
 * @param {*} cid
 * @returns {object} { data, first, last, error }
 * @function */
export const listPendingClubPreRegistrations = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('preRegistrations')
    .where('status', '==', 'pending');

  return listClubPreRegistrations(query);
};

/**
 * List declined club pre-registrations for a given club (for club admins)
 * @param {*} cid
 * @returns {object} { data, first, last, error }
 * @function */
export const listDeclinedClubPreRegistrations = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('preRegistrations')
    .where('status', '==', 'declined');

  return listClubPreRegistrations(query);
};

/**
 * Create a pre-registration for an athlete
 * @param {string} uid - the id of a user (athlete)
 * @param {string} name - the name of the athlete
 * @param {string} email - the email of the athlete
 * @param {datetime} birthday - the athlete's birthday
 * @param {number} currentGrade - the athlete's current grade
 * @param {string} experience - the athlete's current experience (free text)
 * @param {boolean} introductionSession - whether athlete needs introduction or not
 * @param {array} disciplines - athlete's preferred disciplines
 * @param {string} cid - the id of a club
 * @param {string} clubId - the club identifier (slug)
 * @param {string} clubName - the name of the club
 * @param {string} logoUrl - the logo url of the club
 * @returns {object} { data, error }
 * @function */
export const createPreRegistration = async (
  uid,
  name,
  email,
  birthday,
  currentGrade,
  experience = [],
  introductionSession = false,
  disciplines = [],
  cid,
  clubId,
  clubName,
  logoUrl
) => {
  let data = null;
  let error = null;

  const preRegInfo = {
    uid,
    name,
    email,
    birthday,
    currentGrade,
    experience,
    introductionSession,
    disciplines,
    roles: ['athlete'],
    status: 'pending',
    cid,
    clubId,
    clubName,
    logoUrl,
  };

  const preRegistrationDoc = {
    ...preRegInfo,
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    const docRef = await CLUBS_REF.doc(cid)
      .collection('preRegistrations')
      .doc(uid)
      .set(preRegistrationDoc);

    const newRequest = await docRef.get();

    if (newRequest.exists) {
      data = { [newRequest.id]: newRequest.data() };
    } else {
      throw new Error(`An error occurred for pre-registration: ${docRef.id}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Accept an athlete's pre-registration(club admin)
 * @param {*} cid - the id of a club
 * @param {*} email - the id of an athlete
 * @function */
export const setPreRegistrationAccepted = async (cid, uid) => {
  let data = null;
  let error = null;

  const update = { status: 'accepted', lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid)
      .collection('preRegistrations')
      .doc(uid)
      .update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Decline an athlete's pre-registration(club admin)
 * @param {*} cid - id of a club
 * @param {*} uid - id of an athlete
 * @function */
export const setPreRegistrationDeclined = async (cid, uid) => {
  let data = null;
  let error = null;

  const update = { status: 'declined', lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid)
      .collection('preRegistrations')
      .doc(uid)
      .update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};
