import { auth } from '../../firebase';

/**
 * @file Functions for handling authentiation
 * NOTE: This file requires state to be passed as parameters, though other files do not
 */

/**
 * Sign up a user with display name, email, and password (TODO: Add profile photo)
 * @param {string} email
 * @param {string} password
 * @param {string} confirmPassword
 * @param {function} setError
 * @param {function} setEmail
 * @param {function} setPassword
 * @param {function} setConfirmPassword
 * @function */
export async function doCreateUserWithEmailAndPassword(
  email,
  password,
  confirmPassword,
  setError,
  setEmail,
  setPassword,
  setConfirmPassword
) {
  // Ensure passwords match
  if (password !== confirmPassword) {
    setConfirmPassword('');
    setError('Passwords do not match. Try again.');
    return;
  }

  try {
    // create the user account
    await auth.createUserWithEmailAndPassword(email, password);
    setError(null);
  } catch (error) {
    setError('Error signing up with email and password');
  } finally {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  }
}

/**
 * Sign in a user with an email and password
 * @param {object} event
 * @param {string} email
 * @param {string} password
 * @param {function} setError
 * @function */
export const doSignInWithEmailAndPassword = (
  event,
  email,
  password,
  setError
) => {
  event.preventDefault();
  auth.signInWithEmailAndPassword(email, password).catch(() => {
    setError('Error signing in with password and email');
  });
};

/**
 * Send a reset password email for the given email
 * @param {object} event
 * @param {string} email
 * @param {function} setEmailHasBeenSent
 * @param {function} setError
 * @function */
export const doSendPasswordResetEmail = (
  event,
  email,
  setEmailHasBeenSent,
  setError
) => {
  event.preventDefault();
  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      setError(null);
      setEmailHasBeenSent(true);
      setTimeout(() => {
        setEmailHasBeenSent(false);
      }, 3000);
    })
    .catch(() => {
      setError('Error resetting password');
    });
};
