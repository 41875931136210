import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Spinner } from 'react-bootstrap';
import { app } from '../../firebase';

const FileUpload = ({ setFileUrl, folder, setPauseFunc }) => {
  const [fileInput, setFileInput] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const setUrl = (url) => {
    setShowSpinner(false);
    setPauseFunc(false);
    setFileUrl(url);
  };

  const fileSelectedHandler = async (e) => {
    setShowSpinner(true);
    setPauseFunc(true);
    const file = e.target.files[0];
    const storageRef = app
      .storage()
      .ref(`${folder}/${new Date().getTime().toString() + file.name}`);
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const url = await fileRef.getDownloadURL();
    setUrl(url);
    console.log(url);
  };

  return (
    <>
      <input
        className="d-none"
        // eslint-disable-next-line no-return-assign
        ref={(fileIn) => setFileInput(fileIn)}
        type="file"
        onChange={fileSelectedHandler}
      />
      <Button
        style={{ backgroundColor: '#707070', border: '1px solid #707070' }}
        type="button"
        onClick={() => fileInput.click()}
      >
        {showSpinner && (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">uploading...</span>
          </Spinner>
        )}
        {!showSpinner && (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="20"
              fill="currentColor"
              viewBox="0 0 16 20"
            >
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
            </svg>
          </span>
        )}
      </Button>{' '}
      &nbsp;
    </>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  folder: PropTypes.string,
  setFileUrl: PropTypes.any.isRequired,
  setPauseFunc: PropTypes.any.isRequired,
};

FileUpload.defaultProps = {
  folder: 'images',
};
