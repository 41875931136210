import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import styles from './navbar.module.scss';
import { timeSince } from '../../services/util/formatters';

import { listNotifications } from '../../services/profile/notification';

import logoWhite from '../../images/shotdeck-logo-white.png';
import logoBlack from '../../images/shotdeck-logo@2x.png';

/**
 * Returns HTML for an individual dropdown item
 * @param {string} imgSrc the image to be displayed on the item
 * @param {string} type the type of the item
 * @param {string} text the text within the item
 * @param {Date} time the time the item was created
 * @param {string} link where clicking on the item takes the user
 */
const DropdownItem = (data) => {
  const { imgSrc, type, text, time, link, viewed } = data;

  return (
    <a href={link}>
      <div
        className={`${styles.dropdownItem} ${viewed ? 'viewed' : ''} ${
          styles[type]
        }`}
      >
        <div>
          <img
            className={styles.image}
            width="50"
            alt=""
            src="https://www.gravatar.com/avatar/1af5611e73593875af3ae695edc56bf4?s=48&d=identicon&r=PG&f=1"
          />
        </div>
        <div className="w-100">
          <div className="d-flex justify-content-between">
            <div className={styles.title}>{type}</div>
            <div className={styles.time}>{timeSince(time)}</div>
          </div>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </a>
  );
};

const Navbar = () => {
  const [user] = useContext(UserContext);
  const { uid } = user;
  const [notifications, setNotifications] = useState(null);
  const [unviewedNotificationNumber, setUnviewedNotificationNumber] = useState(
    ''
  );

  const [notificationsVisibility, setNotificationsVisibility] = useState(
    Boolean
  );

  function toggleNotifications() {
    setNotificationsVisibility(!notificationsVisibility);
  }

  useEffect(() => {
    const numToReturn = 5;
    listNotifications(uid, numToReturn).then((result) => {
      if (result.data) {
        setNotifications(result.data);

        const unviewedNum = Object.keys(result.data).filter(
          (d) => !result.data[d].viewed
        ).length;
        setUnviewedNotificationNumber(
          `${unviewedNum}${unviewedNum === numToReturn ? '+' : ''}`
        );
      }
    });
    window.addEventListener('click', (e) => {
      const dropdown = document.getElementById('notificationsDropdown');

      if (dropdown) {
        if (
          !dropdown.contains(e.target) &&
          !dropdown.classList.value.includes('navbar_hidden')
        ) {
          setNotificationsVisibility(false);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.navBar}>
      <Link to="/dashboard">
        <img src={logoBlack} alt="ShotDeck Logo" className={styles.siteLogo} />
      </Link>
      <div className="d-flex container justify-content-end">
        <div className="row">
          <div className="col-12 d-flex">
            <div className={styles.item}>
              {/* Commenting out for Phase 1 launch */}
              {/* <div className={styles.numberBubble}>1</div> */}
              <Link className={styles.navAnchor} to="/profile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className={styles.navIcon}
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
              </Link>
            </div>
            {/* <div
              className={styles.item}
              id="notificationsDropdown"
              role="button"
              tabIndex={0}
              onClick={toggleNotifications}
              onKeyPress={toggleNotifications}
            >
              {unviewedNotificationNumber > 0 && (
                <div className={styles.numberBubble}>
                  {unviewedNotificationNumber}
                </div>
              )}
              <label className={styles.navAnchor}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className={styles.navIcon}
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg>
              </label>
              <div
                className={`${styles.dropdownNav} ${
                  !notificationsVisibility && styles.hidden
                }`}
              >
                <div className="d-flex align-items-center p-y-30 p-l-20 p-r-40 justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="fs-24 txt-bold m-r-15">Notifications</span>
                    {unviewedNotificationNumber > 0 && (
                      <div className={styles.numberBubbleInline}>
                        {unviewedNotificationNumber}
                      </div>
                    )}
                  </div>
                  <div
                    tabIndex={0}
                    role="button"
                    onClick={toggleNotifications}
                    onKeyPress={toggleNotifications}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      width="24"
                      viewBox="2 2 12 12"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                </div>
                {notifications &&
                  Object.keys(notifications).map((key) => {
                    const notification = notifications[key];
                    return (
                      <DropdownItem
                        key={key}
                        imgSrc={null}
                        type={notification.type}
                        text={notification.text}
                        time={notification.time.toDate()}
                        link={notification.link}
                        viewed={notification.viewed}
                      />
                    );
                  })}
              </div>
            </div> */}
            {/* <div className={styles.item}>
              <div className={styles.numberBubble}>3</div>
              <Link className={styles.navAnchor} to="/chat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className={styles.navIcon}
                  viewBox="0 0 16 16"
                >
                  <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
