import React, { useState, useEffect, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from '../../pages/ClubSchedules/ClubSchedules.module.scss';
import { UserContext } from '../../providers/UserProvider';
import { getClubByClubId, createClub } from '../../services/club/club';
import { toDateTimeLocalString } from '../../services/util/formatters';
import { Disciplines, Associations } from '../../services/util/enum';

const UpdateClubForm = (data) => {
  const [user] = useContext(UserContext);
  const [checkedDisciplines, setCheckedDisciplines] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const { setModalShow, cid } = data;

  const [club, setClubData] = useState({
    clubIdIsAuto: true,
    clubName: '',
    owner: null,
    street: '',
    city: '',
    state: '',
    zip: '',
    logoUrl: '',
    introduction: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create temp activity to throw on page before next DB call
    const newClub = {
      clubName: club.clubName,
      owner: user,
      location: {
        street: club.street,
        city: club.city,
        state: club.state,
        zip: club.zip,
      }, // Add to UI?
      logoUrl: club.logoUrl,
      introduction: club.introduction,
      disciplines: checkedDisciplines,
    };

    console.log('new club', newClub);

    // updateClub(newClub).then((res) => {
    //   if (res.error) {
    //     console.error('An error occurred while creating club: ', res.error);
    //   } else {
    //     history.push(`/dashboard`);
    //   }
    // });
  };

  const onChange = (e) => {
    // Check if the input changed was a checkbox
    if (e.target.type === 'checkbox') {
      // It it was, assign its value in the association object
      const newAssociations = Object.assign(club.associations, {
        [e.target.name]: e.target.checked,
      });
      setClubData((prevState) => ({
        ...prevState,
        associations: newAssociations,
      }));
    } else {
      // Auto-generate the clubId based off the club name
      if (e.target.name === 'clubName' && club.clubIdIsAuto) {
        setClubData((prevState) => ({
          ...prevState,
          clubId: e.target.value
            .replace(/ /g, '')
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase(),
        }));
      }

      if (e.target.name === 'clubId' && club.clubIdIsAuto) {
        // If modifying the clubId to be different than the automatic
        setClubData((prevState) => ({
          ...prevState,
          clubIdIsAuto: false,
        }));
      } else if (
        e.target.name === 'clubId' &&
        e.target.value === '' &&
        !club.clubIdIsAuto
      ) {
        // Reset if emptied out
        setClubData((prevState) => ({
          ...prevState,
          clubIdIsAuto: true,
        }));
      }

      // General catch-all
      setClubData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };
  return (
    <form className="container" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <label htmlFor="clubName" className={styles.activity_form_label}>
            Club Name
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="clubName"
            id="clubName"
            required
            onChange={onChange}
            value={club.clubName}
          />
        </div>
        <div className="col">
          <label htmlFor="clubId" className={styles.activity_form_label}>
            Club ID
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="clubId"
            id="clubId"
            disabled
            required
            onChange={onChange}
            value={club.clubId}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="introduction" className={styles.activity_form_label}>
            Introduction
          </label>
          <textarea
            className={styles.activity_form_input}
            type="text"
            name="introduction"
            id="introduction"
            onChange={onChange}
            value={club.introduction}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="city" className={styles.activity_form_label}>
            City
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="city"
            required
            id="city"
            value={club.city}
            onChange={onChange}
          />
        </div>
        <div className="col">
          <label htmlFor="state" className={styles.activity_form_label}>
            State
          </label>
          <select
            onChange={onChange}
            name="state"
            required
            value={club.state}
            className={styles.activity_form_input}
            id="state"
          >
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="street" className={styles.activity_form_label}>
            Street
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="street"
            required
            id="street"
            value={club.street}
            onChange={onChange}
          />
        </div>
        <div className="col-4">
          <label htmlFor="zip" className={styles.activity_form_label}>
            Zip
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            pattern="[0-9]{5}"
            name="zip"
            required
            id="zip"
            value={club.zip}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label
            htmlFor="clubDisciplines"
            className={styles.activity_form_label}
          >
            Disciplines:
          </label>
          <br />
          {Disciplines.values().map((disciplines) => (
            <Form.Check
              key={`clubDiscipline${disciplines}`}
              inline
              type="checkbox"
              className={styles.activity_form_input_checkbox}
              label={Disciplines[disciplines].name}
              checked={checkedDisciplines.includes(disciplines)}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedDisciplines([...checkedDisciplines, disciplines]);
                } else {
                  setCheckedDisciplines(
                    checkedDisciplines.filter((id) => id !== disciplines)
                  );
                }
              }}
            />
          ))}
        </div>
        <div className="col-5">
          <label htmlFor="logoUrl" className={styles.activity_form_label}>
            Logo URL
          </label>
          <input
            className={styles.activity_form_input}
            type="text"
            name="logoUrl"
            id="logoUrl"
            value={club.logoUrl}
            onChange={onChange}
          />
        </div>
      </div>
      <button className={styles.activity_form_button} type="submit">
        CREATE CLUB
      </button>
    </form>
  );
};

export default UpdateClubForm;
