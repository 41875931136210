// From https://medium.com/@obaranovskyi/variable-immutability-with-object-freeze-seal-and-preventextensions-methods-7e0d2ebfd920
/**
 * Deep Freeze prevents existing properties, or their enumerability, configurability,
 * or writability, from being changed when they have objects as their value
 * @param {*} object
 * @returns Frozen object
 */
export function deepFreeze(object) {
  Object.getOwnPropertyNames(object).forEach((name) => {
    const prop = object[name];

    if (prop && typeof prop === 'object') deepFreeze(prop);
  });

  return Object.freeze(object);
}

// ****************************************************************************
// =============================== DISCIPLINES ================================
// ****************************************************************************

/**
 * The source of truth for all Disciplines across the site
 * @returns Objects containing the name and IDs of shooting disciplines
 */
export function Disciplines() {
  return Disciplines;
}

Disciplines.values = function () {
  return Object.keys(Disciplines)
    .filter((key) => typeof Disciplines[key] !== 'function')
    .sort((key1, key2) => Disciplines[key1] - Disciplines[key2]);
};
Disciplines.keys = function () {
  return Disciplines.values().map((key) => Disciplines[key]);
};
Disciplines.getByValue = function (val) {
  return Disciplines.values().find((dayValue) => Disciplines[dayValue] === val);
};

const disciplines = [
  {
    name: 'Trap',
    id: 'trap',
    shotsPerRound: 25,
    fields: [
      {
        id: 'class',
        name: 'Class',
      },
      {
        id: 'trapHandicap',
        name: 'Trap Handicap',
      },
      {
        id: 'ataNumber',
        name: 'ATA Number',
      },
    ],
  },
  {
    name: 'Skeet',
    id: 'skeet',
    shotsPerRound: 25,
  },
  {
    name: 'Sporting Clays',
    id: 'sportingClays',
    shotsPerRound: 50,
  },
  {
    name: '5-Stand',
    id: 'fiveStand',
    shotsPerRound: 25,
  },
  {
    name: 'FITASC',
    id: 'fitasc',
    shotsPerRound: 25,
  },
  {
    name: 'iSkeet',
    id: 'iskeet',
    shotsPerRound: 25,
  },
  {
    name: 'Bunker',
    id: 'bunker',
    shotsPerRound: 25,
  },
];

disciplines.forEach((discipline) => {
  console.log('discipline', discipline);
  Object.defineProperty(Disciplines, discipline.id, {
    configurable: false,
    writable: false,
    enumerable: true,
    value: deepFreeze({
      id: discipline.id,
      name: discipline.name,
      shotsPerRound: discipline.shotsPerRound,
    }),
  });
});

// ****************************************************************************
// =============================== CATEGORIES ================================
// ****************************************************************************

/**
 * The source of truth for all Categories across the site
 * @returns Objects containing the name and IDs of categories
 */
export function Categories() {
  return Categories;
}

Categories.values = function () {
  return Object.keys(Categories)
    .filter((key) => typeof Categories[key] !== 'function')
    .sort((key1, key2) => Categories[key1] - Categories[key2]);
};
Categories.keys = function () {
  return Categories.values().map((key) => Categories[key]);
};
Categories.getByValue = function (val) {
  return Categories.values().find((dayValue) => Categories[dayValue] === val);
};

const categories = [
  {
    name: 'Adult',
    id: 'adult',
  },
  {
    name: 'College',
    id: 'college',
  },
  {
    name: 'High School',
    id: 'highSchool',
  },
  {
    name: '12 Gauge',
    id: '12gauge',
  },
  {
    name: '20 Gauge',
    id: '20gauge',
  },
];

categories.forEach((category) => {
  console.log('category', category);
  Object.defineProperty(Categories, category.id, {
    configurable: false,
    writable: false,
    enumerable: true,
    value: deepFreeze({
      id: category.id,
      name: category.name,
    }),
  });
});

// ****************************************************************************
// =============================== ASSOCIATIONS ================================
// ****************************************************************************

/**
 * The source of truth for all Associations across the site
 * @returns Objects containing the name and IDs of shooting associations
 */
export function Associations() {
  return Associations;
}

Associations.values = function () {
  return Object.keys(Associations)
    .filter((key) => typeof Associations[key] !== 'function')
    .sort((key1, key2) => Associations[key1] - Associations[key2]);
};
Associations.keys = function () {
  return Associations.values().map((key) => Associations[key]);
};
Associations.getByValue = function (val) {
  return Associations.values().find(
    (dayValue) => Associations[dayValue] === val
  );
};

const associations = [
  {
    name: 'NSCA',
    id: 'nsca',
  },
  {
    name: 'NSSA',
    id: 'nssa',
  },
  {
    name: 'ATA',
    id: 'ata',
  },
  {
    name: 'USA Shooting',
    id: 'usaShooting',
  },
  {
    name: '4-H',
    id: '4h',
  },
  {
    name: 'USAYESS',
    id: 'usayess',
  },
  {
    name: 'SCTP',
    id: 'sctp',
  },
  {
    name: 'USA Clay Target',
    id: 'usaClayTarget',
  },
  {
    name: 'ACUI',
    id: 'acui',
  },
];

associations.forEach((association) => {
  console.log('association', association);
  Object.defineProperty(Associations, association.id, {
    configurable: false,
    writable: false,
    enumerable: true,
    value: deepFreeze({
      id: association.id,
      name: association.name,
    }),
  });
});
