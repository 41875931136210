import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

// Main screens
import SignIn from '../pages/SignIn/SignIn';
import SignUp from '../pages/SignUp/SignUp';
import PasswordReset from '../pages/PasswordReset/PasswordReset';
import Dashboard from '../pages/Dashboard/Dashboard';
import Profile from '../pages/Profile/Profile';
import NotFound from '../pages/NotFound/NotFound';
import ClubHome from '../pages/ClubHome/ClubHome';
import ClubEditor from '../pages/ClubEditor/ClubEditor';
import ClubBuilder from '../pages/ClubBuilder/ClubBuilder';
import ClubRoster from '../pages/ClubRoster/ClubRoster';
import ClubSchedules from '../pages/ClubSchedules/ClubSchedules';
import TeamRoster from '../pages/TeamRoster/TeamRoster';

export default function Routes({ appProps }) {
  return (
    <Switch>
      {/* Anyone can access these routes */}
      <UnauthenticatedRoute
        path="/"
        exact
        component={SignIn}
        appProps={appProps}
      />

      <UnauthenticatedRoute
        path="/sign-up"
        exact
        component={SignUp}
        appProps={appProps}
      />

      <UnauthenticatedRoute
        path="/password-reset"
        exact
        component={PasswordReset}
        appProps={appProps}
      />

      {/* Must be authenticated to access these routes */}
      <AuthenticatedRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clubs/:cid"
        exact
        component={ClubHome}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/club-builder"
        exact
        component={ClubBuilder}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clubs/:cid/club-roster"
        exact
        component={ClubRoster}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clubs/:cid/club-editor"
        exact
        component={ClubEditor}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clubs/:cid/club-schedules"
        exact
        component={ClubSchedules}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/profile"
        exact
        component={Profile}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/clubs/:cid/teams/:tid"
        exact
        component={TeamRoster}
        appProps={appProps}
      />

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}

Routes.defaultProps = {
  appProps: null,
};

Routes.propTypes = {
  appProps: PropTypes.any,
};
