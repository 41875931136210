import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';

// Don't worry, its okay to store firebase cridentials here.
const firebaseConfig = {
  apiKey: 'AIzaSyAYM_GWDlPTCLmNMrLCr5YzNgkg2niQjQE',
  authDomain: 'shotdeckapp.firebaseapp.com',
  projectId: 'shotdeckapp',
  storageBucket: 'shotdeckapp.appspot.com',
  messagingSenderId: '782653728850',
  appId: '1:782653728850:web:542f6eeab333264871178b',
  measurementId: 'G-VVQE69K2TB',
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const currentTime = firebase.firestore.FieldValue.serverTimestamp();
