import { firestore, currentTime } from '../../firebase';
import { listQuery, listQueryWithUniqueProperty } from '../util/pagination';

/**
 * @file Functions for handling club memberships
 */

const CLUBS_REF = firestore.collection('clubs');

/**
 * List club memberships given a uid
 * @param {string} uid - the id of a user
 * @returns {object} { data, first, last, error }
 * @function */
export const listCurrentClubMembershipsForUser = async (uid) => {
  const { data, first, last, error } = await listQueryWithUniqueProperty(
    firestore
      .collectionGroup('memberships')
      .where('uid', '==', uid)
      .where('status', '==', 'current'),
    'clubId',
    'clubs'
  );

  return { data, first, last, error };
};

/**
 * List the current memberships within a club
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listCurrentClubMemberships = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('memberships')
    .where('status', '==', 'current');
  const { data, first, last, error } = await listQuery(
    query,
    'uid',
    'memberships'
  );
  return { data, first, last, error };
};

/**
 * List the current athlete memberships within a club
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listCurrentAthleteClubMemberships = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('memberships')
    .where('roles', 'array-contains', 'athlete')
    .where('status', '==', 'current');
  const { data, first, last, error } = await listQuery(
    query,
    'uid',
    'memberships'
  );
  return { data, first, last, error };
};

/**
 * List the current athlete memberships within a club
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listCurrentCoachClubMemberships = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('memberships')
    .where('roles', 'array-contains', 'coach')
    .where('status', '==', 'current');
  const { data, first, last, error } = await listQuery(
    query,
    'uid',
    'memberships'
  );
  return { data, first, last, error };
};

/**
 * List the current admin memberships within a club
 * @param {string} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listCurrentAdminClubMemberships = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('memberships')
    .where('roles', 'array-contains-any', ['coach', 'admin', 'owner'])
    .where('status', '==', 'current');
  const { data, first, last, error } = await listQuery(
    query,
    'uid',
    'memberships'
  );
  return { data, first, last, error };
};

/**
 * List the past memberships within a club
 * @param {string} cid - the id of a user
 * @returns {object} { data, first, last, error }
 * @function */
export const listPastClubMemberships = async (cid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('memberships')
    .where('status', '==', 'past');
  const { data, first, last, error } = await listQuery(
    query,
    'uid',
    'memberships'
  );
  return { data, first, last, error };
};

/**
 * Get the roles of a user within a specific club
 * @param {*} cid - the id of a club
 * @param {*} uid - the id of a user
 * @returns {object} { data, error }
 * @function */
export const getRoles = async (cid, uid) => {
  let data = null;
  let error = null;
  try {
    const membershipRef = await CLUBS_REF.doc(cid)
      .collection('memberships')
      .where('uid', '==', uid)
      .where('status', '==', 'current')
      .get();
    if (!membershipRef.empty) {
      const snapshot = membershipRef.docs[0];
      data = snapshot.data().roles;
    } else {
      throw new Error(`No current membership found`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Create a membership for a user within a club
 * @param {*} cid - the id of a club
 * @param {*} uid - the id of a user
 * @param {*} member - the member object to create
 * @returns {object} { data, error }
 * @function */
export const createMembership = async (cid, uid, member) => {
  let data = null;
  let error = null;

  const membershipDoc = {
    ...member,
    status: 'current',
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    const docRef = await CLUBS_REF.doc(cid)
      .collection('memberships')
      .doc(uid)
      .set(membershipDoc);

    const newMembership = await docRef.get();

    if (newMembership.exists) {
      data = { [newMembership.id]: newMembership.data() };
    } else {
      throw new Error(`An error occurred for membership: ${docRef.id}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update a membership
 * TODO: May want to only update roles here
 * @param {*} cid - the id of a club
 * @param {*} uid - the id of a user
 * @param {*} updatedData - the updated object
 * @returns {object} { data, error }
 * @function */
export const updateMembership = async (cid, uid, updatedData) => {
  let data = null;
  let error = null;

  const update = { ...updatedData, lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid).collection('memberships').doc(uid).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update a membership status to current
 * @param {*} cid - the id of a club
 * @param {*} uid - the id of a user
 * @returns {object} { data, error }
 * @function */
export const updateMembershipStatusToCurrent = async (cid, uid) => {
  let data = null;
  let error = null;

  const update = { status: 'current', lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid).collection('memberships').doc(uid).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update a membership status to past (alumni)
 * @param {*} cid - the id of a club
 * @param {*} uid - the id of a user
 * @returns {object} { data, error }
 * @function */
export const updateMembershipStatusToPast = async (cid, uid) => {
  let data = null;
  let error = null;

  const update = { status: 'past', lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid).collection('memberships').doc(uid).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};
