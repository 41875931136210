import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { readDate } from '../../services/util/formatters';
import styles from '../../pages/ClubSchedules/ClubSchedules.module.scss';
import { getScorecard } from '../../services/activity/scores';
import { listAttendees, getAttendee } from '../../services/club/activity';

const Accordion = (data) => {
  const {
    uid,
    id,
    title,
    start,
    end,
    location,
    paymentRequired,
    setRsvpModalShow,
    setDataForRsvpModal,
    setDataForScorecardModal,
    setScorecardModalShow,
    setDeleteModalShow,
    setDeleteModalData,
    roles,
    cid,
    rsvpText,
    type,
    activities,
    setActivities,
  } = data;

  // const today = toDateTimeLocalString(new Date());
  const dateTime =
    new Date().getDate() > new Date(start).getDate() &&
    new Date().getDate < new Date(end).getDate()
      ? new Date()
      : end;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [attendingList, setAttendingList] = useState(null);
  const [attendingCoachesList, setAttendingCoachesList] = useState(null);
  const [notAttendingList, setNotAttendingList] = useState(null);
  const [notAttendingCoachesList, setNotAttendingCoachesList] = useState(null);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [hasRecordedScores, setHasRecordedScores] = useState(false);

  const endDate = readDate(end);
  const startDate = readDate(start);

  const monthOptions = { month: 'short' };

  let month = startDate.toLocaleDateString('default', monthOptions);
  if (endDate) {
    const monthTo = endDate.toLocaleDateString('default', monthOptions);
    if (month !== monthTo) {
      month = `${month} - ${monthTo}`;
    }
  }

  let day = new Date(startDate).getDate();
  if (endDate) {
    const dayTo = new Date(endDate).getDate();
    if (day !== dayTo) {
      day = `${day} - ${dayTo}`;
    }
  }

  function handleDeletedActivity() {
    const tempActivites = {};
    Object.keys(activities).forEach((key) => {
      if (key !== id) {
        tempActivites[key] = activities[key];
      }
    });

    setActivities(tempActivites);
  }

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  function getAttendanceList() {
    listAttendees(cid, id).then((result) => {
      if (result.data) {
        const allAttendees = Object.keys(result.data).map(
          (key) => result.data[key]
        );

        console.log(allAttendees);

        const notGoing = allAttendees.filter(
          (d) => d.roles?.includes('athlete') && d.isAttending === false
        );
        const going = allAttendees.filter(
          (d) => d.roles?.includes('athlete') && d.isAttending === true
        );

        const coachesNotGoing = allAttendees.filter(
          (d) => d.roles?.includes('coach') && d.isAttending === false
        );
        const coachesGoing = allAttendees.filter(
          (d) => d.roles?.includes('coach') && d.isAttending === true
        );

        setAttendingList(going);
        setNotAttendingList(notGoing);

        setAttendingCoachesList(coachesGoing);
        setNotAttendingCoachesList(coachesNotGoing);
      } else if (result.error) {
        console.error(result.error);
      }
    });
  }

  const handleRsvpChange = (rsvp) => {
    if (rsvp === 'true') {
      setRsvpStatus('Going');
    } else setRsvpStatus('Not Going');
  };

  useEffect(() => {
    getScorecard(cid, id, uid).then((res) => {
      if (res.data) {
        setHasRecordedScores(true);
      }
    });

    getAttendee(cid, id, uid).then((res) => {
      if (res.data) {
        console.log('RSVP STATUS', res.data);
        if (res.data.isAttending) {
          setRsvpStatus('Going');
        } else setRsvpStatus('Not Going');
      } else {
        setRsvpStatus('RSVP');
      }
    });
  }, []);

  return (
    <div
      className={styles.activityGrid}
      style={{ opacity: rsvpStatus === 'Not Going' ? 0.6 : 1 }}
    >
      <div className={styles.activityRow}>
        <div className="d-flex justify-content-center flex-column">
          <div className={styles.eventMonth}>{month}</div>
          <div className={styles.eventDay}>{day}</div>
        </div>
        <div className="d-flex justify-content-center flex-column">
          <div className={`d-block ${styles.eventLocationSmall}`}>
            {data.event_location}
          </div>
          <div
            className={`${styles.eventName} d-flex w-100 justify-content-center flex-column`}
          >
            <p className="fs-12 m-b-0 text-uppercase">{type}</p>
            <a href={data.event_button_url}>{title}</a>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-column">
          {`${location.city}, ${location.state.toUpperCase()}`}
        </div>
        <div className="d-flex justify-content-center flex-column">
          {location.parkName}
        </div>
        <div className=" d-flex justify-content-between justify-content-md-center flex-column text-end">
          <div
            className="d-flex w-100 p-r-5"
            style={{ backgroundColor: 'transparent' }}
          >
            <button
              type="button"
              onClick={() => {
                setDataForRsvpModal({
                  title: title,
                  month: month,
                  day: day,
                  aid: id,
                  getAttendanceList: () => getAttendanceList(),
                  handleRsvpChange: (d) => handleRsvpChange(d),
                });
                setRsvpModalShow(true);
              }}
              onKeyPress={() => setRsvpModalShow(true)}
              className={`btn 
              ${rsvpStatus === 'Going' ? 'btn-success' : ''}
              ${rsvpStatus === 'Not Going' ? 'btn-danger' : ''}
              ${rsvpStatus === 'RSVP' ? 'btn-primary' : ''}
            } d-block text-nowrap w-100 m-5`}
            >
              {rsvpStatus}
            </button>
            {(roles.includes('owner') || roles.includes('admin')) && (
              <Button
                variant="danger"
                onClick={() => {
                  setDeleteModalData({
                    title: title,
                    month: month,
                    day: day,
                    aid: id,
                    cid: cid,
                    handleDeletedActivity: () => handleDeletedActivity(),
                  });
                  setDeleteModalShow(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="20"
                  fill="currentColor"
                  viewBox="0 2 16 16"
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
              </Button>
            )}
          </div>
          <div className=" w-100 d-flex">
            <button
              type="button"
              onClick={() => {
                setDataForScorecardModal({
                  title: title,
                  month: month,
                  day: day,
                  dateTime: dateTime,
                  aid: id,
                  setHasRecordedScores: (d) => setHasRecordedScores(d),
                });
                setScorecardModalShow(true);
              }}
              onKeyPress={() => setScorecardModalShow(true)}
              className={`btn ${
                hasRecordedScores ? 'btn-secondary' : 'btn-primary'
              } d-flex justify-content-center text-center w-100 m-5`}
            >
              {hasRecordedScores ? 'Edit Scorecard' : 'Record Scores'}
            </button>
          </div>
          {/* {roles.some((role) =>
            console.log('roles', ['coach', 'athlete'].includes(role))
          )} */}
          {/* {roles.some(
            (role) =>
              ['coach', 'athlete'].includes(role) && (
                <button
                  type="button"
                  onClick={() => {
                    setDataForRsvpModal({
                      title: title,
                      month: month,
                      day: day,
                      aid: id,
                      getAttendanceList: () => getAttendanceList(),
                    });
                    setRsvpModalShow(true);
                  }}
                  onKeyPress={() => setRsvpModalShow(true)}
                  className="btn btn-primary d-block text-nowrap"
                >
                  {rsvpText}
                </button>
              )
          )} */}
        </div>
        <div className="d-flex p-0 position-relative">
          <div
            role="button"
            tabIndex={0}
            className="w-100 d-flex align-items-center justify-content-end"
            style={{ height: '100%' }}
            onClick={() => {
              getAttendanceList();
              toggleDrawer();
            }}
            onKeyPress={() => {
              getAttendanceList();
              toggleDrawer();
            }}
          >
            <label className={styles.toggleLabel} htmlFor={`toggle${id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-three-dots-vertical"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </label>
          </div>
        </div>
        <div
          className={`${styles.attendingRow} ${drawerOpen ? styles.open : ''}`}
        >
          <div className="container-fluid">
            <div className="row text-start align-items-start p-t-20">
              <div className="col-12 col-lg-6 m-b-30">
                <p>Coaches Attending:</p>
                {attendingCoachesList &&
                  attendingCoachesList.map((coach) => (
                    <p key={coach.uid} className={styles.athleteNameAttending}>
                      {coach.displayName}
                      {paymentRequired === true &&
                        (coach.paymentReceived === false ||
                          typeof coach.paymentReceived === 'undefined') && (
                          <sup>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              className={styles.notPaidAlert}
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                          </sup>
                        )}
                    </p>
                  ))}
              </div>
              <div className="col-12 col-lg-6 m-b-30">
                <p>Coaches Not Attending:</p>
                {notAttendingCoachesList &&
                  notAttendingCoachesList.map((coach) => (
                    <p key={coach.uid} className={styles.athleteNameAttending}>
                      {coach.displayName}
                      {paymentRequired === true &&
                        (coach.paymentReceived === false ||
                          typeof coach.paymentReceived === 'undefined') && (
                          <sup>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              className={styles.notPaidAlert}
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                          </sup>
                        )}
                    </p>
                  ))}
              </div>
              <div className="col-12 col-lg-6 p-b-10">
                <p>Athletes Attending:</p>
                {attendingList &&
                  attendingList.map((athlete) => (
                    <p
                      key={athlete.uid}
                      className={styles.athleteNameAttending}
                    >
                      {athlete.displayName}
                      {paymentRequired === true &&
                        (athlete.paymentReceived === false ||
                          typeof athlete.paymentReceived === 'undefined') && (
                          <sup>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              className={styles.notPaidAlert}
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                          </sup>
                        )}
                    </p>
                  ))}
              </div>
              <div className="col-12 col-lg-6 p-b-10">
                <p>Athletes Not Attending:</p>
                {notAttendingList &&
                  notAttendingList.map((athlete) => (
                    <div key={athlete.uid}>
                      <p className={styles.athleteNameNotAttending}>
                        {athlete.displayName}
                      </p>
                      {(roles.includes('coach') || roles.includes('admin')) && (
                        <p className="fs-12 text-muted">{athlete.reason}</p>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
