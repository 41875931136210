/**
 * @file Utilties to be re-used in the app services
 */

/**
 * Get a query given a collection ref, field to orderBy, and pageSize to limit to
 * @param {*} ref - collection to query
 * @param {*} field - field to order on
 * @param {*} pageSize - number of results to fetch per page
 * @function */
export const getPaginatedQuery = (ref, field, pageSize = 10) => {
  ref.orderBy(field).limit(pageSize);
};

/**
 *
 * @param {*} ref - collection to query
 * @param {*} field - field to order on
 * @param {*} pageSize - number of results to fetch per page
 * @param {*} last - the last result to start this query with
 * @function */
export const getNextPaginatedQuery = (ref, field, pageSize = 10, last) => {
  ref.orderBy(field).startAfter(last).limit(pageSize);
};

/**
 *
 * @param {*} ref - collection to query
 * @param {*} field - field to order on
 * @param {*} pageSize - number of results to fetch per page
 * @param {*} first - the first result to stop this query before
 * @function */
export const getPrevPaginatedQuery = (ref, field, pageSize = 10, first) => {
  ref.orderBy(field).endBefore(first).limitToLast(pageSize);
};

/**
 * Execute a query and return results
 * @param {*} query - the query to execute
 * @param {*} uniqueProperty - a unique property to use to keep track of pagination
 * @param {*} collectionName - the collection name to display in an error message
 * @function */
export const listQuery = async (query, uniqueProperty, collectionName) => {
  let data = null;
  let first = null;
  let last = null;
  let error = null;

  try {
    const querySnapshots = await query.get();
    if (!querySnapshots.empty) {
      const results = {};
      querySnapshots.forEach((doc) => {
        results[doc.id] = doc.data();
      });
      // References to be used
      // eslint-disable-next-line prefer-destructuring
      first = querySnapshots.docs[0];
      last = querySnapshots.docs[querySnapshots.docs.length - 1];

      data = results;
    } else {
      throw new Error(`Unable to find any ${collectionName}.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, first, last, error };
};

/**
 * Execute a query and return results
 * @param {*} query - the query to execute
 * @param {*} uniqueProperty - a unique property to use to keep track of pagination
 * @param {*} collectionName - the collection name to display in an error message
 * @function */
export const listQueryWithUniqueProperty = async (
  query,
  uniqueProperty,
  collectionName
) => {
  let data = null;
  let first = null;
  let last = null;
  let error = null;

  try {
    const querySnapshots = await query.get();
    if (!querySnapshots.empty) {
      const results = {};
      querySnapshots.forEach((doc) => {
        results[doc.data()[uniqueProperty] || doc.id] = doc.data();
      });
      // References to be used
      // eslint-disable-next-line prefer-destructuring
      first = querySnapshots.docs[0];
      last = querySnapshots.docs[querySnapshots.docs.length - 1];

      data = results;
    } else {
      throw new Error(`Unable to find any ${collectionName}.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, first, last, error };
};

/**
 * Execute a query and return results as a list (good for collection group queries)
 * @param {*} query - the query to execute
 * @param {*} uniqueProperty - a unique property to use to keep track of pagination
 * @param {*} collectionName - the collection name to display in an error message
 * @function */
export const listQueryList = async (query, uniqueProperty, collectionName) => {
  let data = null;
  let first = null;
  let last = null;
  let error = null;

  try {
    const querySnapshots = await query.get();
    if (!querySnapshots.empty) {
      const results = [];
      querySnapshots.forEach((doc) => {
        results.push(doc.data());
      });
      // References to be used
      first = querySnapshots.docs[0].data()[uniqueProperty];
      last = querySnapshots.docs[querySnapshots.docs.length - 1].data()[
        uniqueProperty
      ];

      data = results;
    } else {
      throw new Error(`Unable to find any ${collectionName}.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, first, last, error };
};
