import React, { useContext, useState, useEffect } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import ClubCard from '../../components/ClubCard/ClubCard';
import styles from './Dashboard.module.scss';
import { listClubs, getClubByClubId } from '../../services/club/club';
import { listCurrentClubMembershipsForUser } from '../../services/club/membership';
import {
  listPendingClubInvitesForUser,
  setInviteAccepted,
  setInviteDeclined,
} from '../../services/club/invite';
import { firestore } from '../../firebase';
import RosterItem from '../../components/RosterItem/RosterItem';

const Dashboard = () => {
  const [user] = useContext(UserContext);
  const { email, displayName, uid, photoUrl } = user;
  const [clubs, setClubs] = useState(null);
  const [invites, setInvites] = useState(null);

  useEffect(() => {
    // IMPLEMENT TO GET SPECIFIC CLUBS FOR USER
    /*
      listCurrentClubMembershipsForUser(uid).then((result) => {
        if (result.data) {
          console.log('MY CLUBS', result.data);
        }
      });
    */

    // Get all clubs on the platform
    listClubs(firestore.collection('clubs')).then((result) => {
      if (result.data) {
        let clubIdentificationList = [];
        let clubList = result.data;
        const initialKeys = Object.keys(result.data);

        // Get my clubs
        listCurrentClubMembershipsForUser(uid).then((returnedData) => {
          if (returnedData.data) {
            const secondaryKeys = Object.keys(returnedData.data);

            secondaryKeys.forEach((key) => {
              clubIdentificationList.push(returnedData.data[key].clubId);
            });

            initialKeys.forEach((key) => {
              let clubID = clubList[key].clubId;

              if ( clubIdentificationList.includes(clubID) ) {
                clubList[key].myClub = true;
              } else {
                clubList[key].myClub = false;
              }
            });
            setClubs(clubList);
          } else if (returnedData.error) {
            console.error('Error listing my clubs', returnedData.error);
          }
        });
      } else if (result.error) {
        console.error('Error listing clubs', result.error);
      }
    });
  }, []);

  useEffect(() => {
    listPendingClubInvitesForUser(email).then((result) => {
      if (result.data) {
        setInvites(result.data);
      } else if (result.error) {
        console.error(result.error);
      }
    });
  }, []);

  return (
    <div className={styles.dashboard}>
      {/* Dashboard hero */}
      <div className={styles.dashboard_hero}>
        <div className={styles.dashboard_hero_column}>
          <h1 className={styles.dashboard_hero_heading}>
            Hey, {displayName || email.replace(/@[^@]+$/, '')}!
          </h1>
          <p className={styles.dashboard_hero_desc}>
            Select a club to get started
          </p>
        </div>
        {console.log({ photoUrl })}
        {/* <div
          className={styles.dashboard_hero_bg}
          style={{
            backgroundImage: `url(${photoUrl.replace(
              /\.(....?)\?alt/,
              '_340x340.$1?alt'
            )})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        /> */}
      </div>
      {/* Dashboard clubs */}
      <div className={styles.dashboard_clubs}>
        <h2 className={styles.dashboard_clubs_heading}>My Clubs</h2>
        {clubs &&
          Object.keys(clubs).map((cid) => {
            const club = clubs[cid];
            const isMyClub = club.myClub;
            if (isMyClub === true) {
              return <ClubCard club={club} myClub cid={cid} key={cid} />;
            }
            return <div key={cid} />;
          })}
        <h2 className={styles.dashboard_clubs_heading}>Find a Club</h2>
        <div className={styles.dashboard_clubFinder}>
          {clubs &&
            Object.keys(clubs).map((cid) => {
              const club = clubs[cid];
              const isMyClub = club.myClub;
              if (isMyClub === false) {
                return (
                  <ClubCard club={club} myClub={false} cid={cid} key={cid} />
                );
              }
              return <div key={cid} />;
            })}
        </div>
        <Link to="/club-builder">
          <RosterItem title="CREATE A NEW CLUB" isButton>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </RosterItem>
        </Link>
        {invites && invites.length > 0 && (
          <h2 className={styles.dashboard_clubs_heading}>Invites</h2>
        )}
        {invites &&
          invites.length > 0 &&
          invites.map((invite) => (
            <div key={invite.cid} className={styles.dashboard_clubs_invite}>
              <div className={styles.dashboard_clubs_invite_left}>
                <h3 className="h4">
                  <strong>{invite.clubName}</strong>
                </h3>
                <p>
                  <strong>Title:</strong> {invite.title}
                </p>
                <p>
                  <strong>Role(s):</strong>{' '}
                  <span>{invite.roles.join(', ')}</span>
                </p>
              </div>
              <div className={styles.dashboard_clubs_invite_right}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Accept invite</Tooltip>}
                  // TODO: findDOMNode error should be resolved: https://github.com/react-bootstrap/react-bootstrap/issues/5519
                >
                  <button
                    type="button"
                    onClick={() => {
                      setInviteAccepted(invite.cid, email, uid).then(
                        (result) => {
                          if (result.error) {
                            console.error(result.error);
                          } else if (result.data) {
                            setInvites(
                              invites.filter((item) => item.cid !== invite.cid)
                            );
                          }
                        }
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="green"
                      className="bi bi-check-square-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                    </svg>
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Decline invite</Tooltip>}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setInviteDeclined(invite.cid, email, uid).then(
                        (result) => {
                          if (result.error) {
                            console.error(result.error);
                          } else if (result.data) {
                            setInvites(
                              invites.filter((item) => item.cid !== invite.cid)
                            );
                          }
                        }
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="red"
                      className="bi bi-x-square-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                    </svg>
                  </button>
                </OverlayTrigger>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
