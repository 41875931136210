import { firestore, currentTime } from '../../firebase';
import { readDate, toTimestamp } from '../util/formatters';
import { listQuery } from '../util/pagination';

const CLUBS_REF = firestore.collection('clubs');

// Create Activity
/**
 *
 * @param {number} cid club id
 * @param {string} title title of the activity
 * @param {Date} startTime
 * @param {Date} endTime
 * @param {string} parkName name of the shooting park
 * @param {string} city
 * @param {string} state
 * @param {string} street
 * @param {string} zip
 * @param {string} type Practice / Competition / Meeting
 * @param {boolean} mandatory
 * @param {number} cost
 * @param {Array} associations affiliated shooting associations
 * @param {boolean} squaddingComplete
 * @param {boolean} paymentCollected
 * @param {number} attendeeCount
 */
export const createActivity = async ({
  cid,
  title,
  startTime,
  endTime,
  location,
  type,
  mandatory = false,
  cost,
  associations,
  squaddingComplete,
  paymentCollected,
  attendeeCount,
  requiredTids,
  requiredTeams,
}) => {
  let data = null;
  let error = null;

  const activity = {
    title: title,
    startTime: new Date(startTime),
    endTime: new Date(endTime),
    location: {
      parkName: location.parkName,
      city: location.city,
      state: location.state,
      street: location.street,
      zip: location.zip,
    },
    type: type, // Competition/Practice
    mandatory: mandatory, // True = Yes
    cost: cost,
    associations: associations,
    squadingComplete: squaddingComplete, // True = Green
    paymentCollected: paymentCollected, // True = Green, unneeded for practices
    attendeeCount: attendeeCount, // Count in attendees subtable
    requiredTids: requiredTids,
    requiredTeams: requiredTeams,
  };
  console.log(activity);

  try {
    const docRef = await CLUBS_REF.doc(cid)
      .collection('activities')
      .add(activity);

    if (docRef) {
      data = docRef.id;
    } else {
      throw new Error(`An error occurred while creating the activity.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Batch writes multiple activities from an array of JSON objects
 * @param {*} cid Club ID
 * @param {*} activityObjects JSON objects
 * @returns data and error
 */
export const createActivities = async (cid, activityObjects) => {
  let data = null;
  let error = null;

  // Get a new write batch
  const batch = firestore.batch();

  const activityDocs = {};

  console.log('Trying to create', activityObjects);

  activityObjects.forEach((activity) => {
    const activityRef = CLUBS_REF.doc(cid).collection('activities').doc();

    const activityDoc = {
      title: activity.title || '',
      startTime: new Date(activity.startTime),
      endTime: new Date(activity.endTime),
      location: {
        parkName: activity.location.parkName || '',
        city: activity.location.city || '',
        state: activity.location.state || '',
        street: activity.location.street || '',
        zip: activity.location.zip || '',
      },
      type: activity.type || '',
      mandatory: activity.mandatory || false,
      cost: activity.cost || '',
      associations: activity.associations || '',
    };

    batch.set(activityRef, activityDoc);
  });

  try {
    await batch.commit();
    data = activityDocs;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

// Read Activity
/**
 * Get an activity
 * @param {*} cid Club id
 * @param {*} aid activity id
 * @returns The activity
 */
export const getActivity = async (cid, aid) => {
  const query = CLUBS_REF.doc(cid).collection('activities').doc(aid);
  let data;
  let error;

  query
    .get()
    .then((doc) => {
      if (doc.exists) {
        data = doc.data();
        console.log('Document data:', doc.data());
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch((err) => {
      console.log('Error getting document:', err);
      error = err;
    });

  return { data, error };
};

// Update Activity

/** List Activities
 *
 * @param {string} cid club id
 * @param {number} limit how many items to return per page, default 10
 */
export const listActivities = async (cid, lastDoc = 0, limit = 5, past) => {
  console.log('lastDoc', lastDoc, limit, past);
  let query;
  if (past) {
    // For grabbing past events
    query = CLUBS_REF.doc(cid)
      .collection('activities')
      .where('endTime', '<', new Date())
      .orderBy('endTime', 'desc')
      // .endBefore(lastDoc)
      // .startAfter(lastDoc)
      .limit(limit);
  } else {
    // This is for grabbing future events
    query = CLUBS_REF.doc(cid)
      .collection('activities')
      .where('endTime', '>=', new Date())
      .orderBy('endTime')
      .startAfter(lastDoc)
      .limit(limit);
  }

  let { data, first, last, error } = await listQuery(
    query,
    'aid',
    'activities'
  );

  if (data && Object.keys(data).length < limit) {
    last = null;
  }

  return { data, first, last, error };
};
/**
 * Returns activities of a specific type
 * @param {string} cid club id
 * @param {string} type the type of an activity to return
 */
export const listActivitiesOfType = async (
  cid,
  type,
  lastDoc = 0,
  limit = 5
) => {
  let query = CLUBS_REF.doc(cid)
    .collection('activities')
    .where('type', '==', type)
    .where('endTime', '>=', new Date())
    .orderBy('endTime')
    .startAfter(lastDoc)
    .limit(limit);

  const { data, first, last, error } = await listQuery(
    query,
    'id',
    'activities'
  );
  return { data, first, last, error };
};

/** Create Attendees
 *
 * @param {*} cid club id
 * @param {*} aid activity id
 * @param {*} uid user id.
 * @param {bool} isAttending going or not
 * @param {string} displayName name of user
 * @param {boolean} paymentReceived
 * @param {array} role
 */
export const createAttendee = async (
  cid,
  aid,
  uid,
  displayName,
  isAttending,
  reason,
  roles
) => {
  let data = null;
  let error = null;

  try {
    const docRef = CLUBS_REF.doc(cid)
      .collection('activities')
      .doc(aid)
      .collection('attendees')
      .doc(uid)
      .set(
        {
          uid: uid,
          displayName: displayName,
          isAttending: isAttending,
          reason: reason,
          roles: roles,
        },
        { merge: true }
      );
    if (docRef.exists) {
      data = docRef;
    } else {
      throw new Error(`An error occurred while creating the attendee.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update Attendees
 * @param {string} cid club id
 * @param {string} aid activity id
 * @param {string} uid user id
 * @param {*} updatedData data
 */
export const updateAttendee = async (cid, aid, uid, updatedData) => {
  let data = null;
  let error = null;

  const update = { ...updatedData, lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid)
      .collection('activities')
      .doc(aid)
      .where('uid', '==', uid)
      .update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * List Attendees
 * @param {*} cid club id
 * @param {*} aid activity id
 */
export const listAttendees = async (cid, aid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('activities')
    .doc(aid)
    .collection('attendees');
  const { data, first, last, error } = await listQuery(
    query,
    'id',
    'attendees'
  );
  return { data, first, last, error };
};

/**
 * Create Not Going
 * @param {string} cid club id
 * @param {string} uid user id
 * @param {string} aid activity id
 * @param {string} displayName name of user
 * @param {string} reason reason for absence
 */
export const createNotGoing = async (cid, aid, uid, displayName, reason) => {
  let data = null;
  let error = null;

  const notGoing = {
    uid: uid,
    displayName: displayName,
    reason: reason,
  };

  try {
    const docRef = CLUBS_REF.doc(cid)
      .collection('activities')
      .doc(aid)
      .collection('notGoings')
      .doc(uid)
      .set({ notGoing }, { merge: true });
    if (docRef.exists) {
      data = docRef;
    } else {
      throw new Error(`An error occurred while creating the notGoing.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

// Read Activity
/**
 * Get an activity
 * @param {*} cid Club id
 * @param {*} aid activity id
 * @returns The activity
 */
export const getAttendee = async (cid, aid, uid) => {
  const query = CLUBS_REF.doc(cid)
    .collection('activities')
    .doc(aid)
    .collection('attendees')
    .doc(uid);
  let data;
  let error;

  await query
    .get()
    .then((doc) => {
      if (doc.exists) {
        data = doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch((err) => {
      console.log('Error getting document:', err);
      error = err;
    });

  return { data, error };
};

/**
 * delete a club given an id
 * @param {string} cid
 */
export const deleteActivity = async (cid, aid) => {
  let data = null;
  let error = null;

  try {
    await CLUBS_REF.doc(cid).collection('activities').doc(aid).delete();
  } catch (err) {
    error = err;
  }

  return { error };
};
