import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { UserContext } from '../../providers/UserProvider';
import {
  getTeam,
  listClubTeamMembers,
  addClubTeamMembers,
} from '../../services/club/team';
import {
  getRoles,
  listCurrentAthleteClubMemberships,
  listCurrentCoachClubMemberships,
} from '../../services/club/membership';
import { formatDisciplines } from '../../services/util/formatters';
import RosterItem from '../../components/RosterItem/RosterItem';
import styles from './TeamRoster.module.scss';

const TeamRoster = ({ match }) => {
  const { cid, tid } = match.params;
  const location = useLocation();
  const { pathname } = location;
  const [user] = useContext(UserContext);
  // Team
  const [team, setTeam] = useState(null);
  // Members
  const [athleteMembers, setAthleteMembers] = useState(null);
  const [coachMembers, setCoachMembers] = useState(null);
  // The roles for the current user
  const [roles, setRoles] = useState(null);
  // Modal state
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [memberships, setMemberships] = useState(null);
  const [checkedUids, setCheckedUids] = useState([]);
  const [error, setError] = useState(null);

  // Get team informaton based on the url
  useEffect(() => {
    getTeam(cid, tid).then((result) => {
      if (result.data) {
        setTeam(result.data);
      } else if (result.error) {
        console.error('Error getting team: ', result.error);
      }
    });
  }, []);

  // Get user's team roles
  useEffect(() => {
    getRoles(cid, user.uid).then((result) => {
      if (result.data) {
        setRoles(result.data);
      } else {
        setRoles([]);
        console.error(result.error);
      }
    });
  }, [user.uid]);

  // Get team members that are athletes
  useEffect(() => {
    listClubTeamMembers(cid, tid, true).then((result) => {
      if (result.data) {
        setAthleteMembers(result.data);
      } else if (result.error) {
        setAthleteMembers([]);
        console.error('Error getting athlete team members: ', result.error);
      }
    });
  }, []);

  // Get team members that are coaches
  useEffect(() => {
    listClubTeamMembers(cid, tid, false).then((result) => {
      if (result.data) {
        setCoachMembers(result.data);
      } else if (result.error) {
        setCoachMembers([]);
        console.error('Error getting coach team members: ', result.error);
      }
    });
  }, []);

  const listAthletes = () => {
    listCurrentAthleteClubMemberships(cid).then((res) => {
      if (res.data) {
        setMemberships(res.data);
      } else {
        console.error(res.error);
        setMemberships([]);
      }
    });
  };

  const listCoaches = () => {
    listCurrentCoachClubMemberships(cid).then((res) => {
      if (res.data) {
        setMemberships(res.data);
      } else {
        console.error(res.error);
        setMemberships([]);
      }
    });
  };

  // Get team memberships for modal
  useEffect(() => {
    if (modalType === 'Athlete') {
      listAthletes();
    } else if (modalType === 'Coach') {
      listCoaches();
    }
  }, [modalType]);

  // Conditionally render team actions based on a user's roles
  function teamActionSwitch() {
    switch (true) {
      case roles.length > 0:
        return (
          <>
            <section>
              <h2 className="h3">Athletes</h2>
              {roles.some((role) =>
                ['coach', 'admin', 'owner'].includes(role)
              ) && (
                <div className={styles.teamroster_add_wrapper}>
                  <RosterItem
                    title="ADD ATHLETE MEMBER(S)"
                    isButton
                    action={() => {
                      setModalShow(true);
                      setModalType('Athlete');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </RosterItem>
                </div>
              )}
              {athleteMembers &&
                (Object.keys(athleteMembers).length > 0 ? (
                  Object.keys(athleteMembers).map((uid) => {
                    const athlete = athleteMembers[uid];
                    return (
                      <RosterItem
                        title={athlete.displayName}
                        desc={
                          formatDisciplines(athlete.disciplines) ||
                          'No disciplines specified'
                        }
                        key={uid}
                      />
                    );
                  })
                ) : (
                  <p>No athletes on the team.</p>
                ))}
            </section>
            <section>
              <h2 className="h3">Coaches</h2>
              {roles.some((role) =>
                ['coach', 'admin', 'owner'].includes(role)
              ) && (
                <div className={styles.teamroster_add_wrapper}>
                  <RosterItem
                    title="ADD COACH MEMBER(S)"
                    isButton
                    action={() => {
                      setModalShow(true);
                      setModalType('Coach');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="currentColor"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </RosterItem>
                </div>
              )}
              {coachMembers &&
                (Object.keys(coachMembers).length > 0 ? (
                  Object.keys(coachMembers).map((uid) => {
                    const coach = coachMembers[uid];
                    return (
                      <RosterItem
                        title={coach.displayName}
                        desc={coach.title || 'No title specified'}
                        key={uid}
                      />
                    );
                  })
                ) : (
                  <p>No coaches on the team.</p>
                ))}
            </section>

            {/* Add Member Modal */}
            {roles.some((role) =>
              ['coach', 'admin', 'owner'].includes(role)
            ) && (
              <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                centered
                animation={false}
              >
                <Modal.Header>
                  <Modal.Title as="h3" className={styles.member_modal_title}>
                    Add {modalType} Member(s)
                  </Modal.Title>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      setModalShow(false);
                      setModalType(null);
                      setCheckedUids([]);
                      setMemberships(null);
                      setError(null);
                    }}
                  >
                    Close
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  {memberships &&
                    (Object.keys(memberships).length > 0 ? (
                      Object.keys(memberships).map((uid) => {
                        // Check if the membership is already a member
                        // TODO: Only get memberships where not member (memberships.where('uid', 'not-in', Objects.keys(currentMembers)))
                        const currentMembers =
                          modalType === 'Athlete'
                            ? athleteMembers
                            : coachMembers;
                        if (currentMembers[uid]) return null;
                        const membership = memberships[uid];
                        return (
                          <Form.Check
                            key={uid}
                            inline
                            type="checkbox"
                            label={membership.displayName}
                            checked={checkedUids.includes(uid)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheckedUids([...checkedUids, uid]);
                              } else {
                                setCheckedUids(
                                  checkedUids.filter((id) => id !== uid)
                                );
                              }
                            }}
                          />
                        );
                      })
                    ) : (
                      <p>No {modalType}s found in the club.</p>
                    ))}
                  {error !== null && (
                    <p align="center" className={styles.member_form_error}>
                      {error}
                    </p>
                  )}
                  <button
                    className={styles.member_form_button}
                    type="button"
                    onClick={() => {
                      const isAthlete = modalType === 'Athlete';

                      addClubTeamMembers(
                        cid,
                        tid,
                        team.name,
                        isAthlete,
                        checkedUids,
                        memberships
                      ).then((res) => {
                        if (res.data) {
                          if (modalType === 'Athlete') {
                            setAthleteMembers({
                              ...athleteMembers,
                              ...res.data,
                            });
                          } else {
                            setCoachMembers({
                              ...coachMembers,
                              ...res.data,
                            });
                          }
                          setError(null);
                          setModalShow(false);
                          setModalType(null);
                          setCheckedUids([]);
                          setMemberships(null);
                        } else if (res.error) {
                          console.error(res.error);
                          setError(
                            'An error occurred while adding team members. Please try again.'
                          );
                        }
                      });
                    }}
                  >
                    ADD {modalType} MEMBER(S)
                  </button>
                </Modal.Body>
              </Modal>
            )}
          </>
        );

      default:
        return (
          <div className={styles.teamroster_nonmember}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              fill="currentColor"
              className="bi bi-lock-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
            </svg>
            <p>
              You are not a member of this club. Submit a pre-registration form
              or request club admin access on the club&apos;s home page.
            </p>
          </div>
        );
    }
  }

  return (
    <div className={styles.teamroster}>
      {/* Team Home hero */}
      <div className={styles.teamroster_hero}>
        {/* Breadcrumbs */}
        <p>
          /{' '}
          <Link to={`${pathname.replace(`/teams/${tid}`, '')}`}>Club Home</Link>{' '}
          /{' '}
          <Link
            to={`${pathname.replace(`/teams/${tid}`, '/club-roster?tab=4')}`}
          >
            Club Roster - Teams
          </Link>{' '}
          / <span>Team Roster</span>
        </p>
        <div className={styles.teamroster_hero_left}>
          {/* Team heading */}
          <div className={styles.teamroster_hero_heading}>
            <div className={styles.teamroster_hero_logo_wrapper} />
            <div className={styles.teamroster_hero_logo_heading_headings}>
              {team && <h1>{team.name}</h1>}
              {team && <p>{team.qualifications}</p>}
              {team && (
                <p>
                  <strong>Disciplines:</strong>{' '}
                  {formatDisciplines(team.disciplines) ||
                    'No disciplines specified'}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Team home roster */}
      <div className={styles.teamroster_roster}>
        {roles && teamActionSwitch(roles)}
      </div>
    </div>
  );
};

TeamRoster.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TeamRoster;
