import React, { useState, useEffect, createContext } from 'react';
import { PropTypes } from 'prop-types';
import { getCurrentUserProfile } from '../services/profile';

export const UserContext = createContext({ user: null });

const UserProvider = ({ children, setInitializing }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUserProfile(setUser, setInitializing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
  setInitializing: PropTypes.any.isRequired,
};

export default UserProvider;
