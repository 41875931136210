/* eslint-disable no-plusplus */
/**
 * Given a string in camel case, convert to title case (common for JSON keys)
 * @param {string} camelCase - input string to be converted
 * @return {string}
 * @function */
export const formatCamelToTitle = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());

/**
 * Given an array of disciplines, convert to title case
 * @param {array} disciplines - input string to be converted
 * @return {string}
 * @function */
export const formatDisciplines = (disciplines) => {
  const output = [];
  if (disciplines && disciplines.length > 0) {
    disciplines.forEach((d) => {
      switch (d) {
        case 'trap':
          output.push('Trap');
          break;
        case 'skeet':
          output.push('Skeet');
          break;
        case 'sportingClays':
          output.push('Sporting Clays');
          break;
        case 'fiveStand':
          output.push('5-Stand');
          break;
        case 'fitasc':
          output.push('FITASC');
          break;
        case 'iskeet':
          output.push('International Skeet (iskeet)');
          break;
        case 'bunkerTrap':
          output.push('Bunker Trap');
          break;
        default:
          console.error(`Unexpected discipline being formatted.`);
          break;
      }
    });
  }
  return output.join(', ');
};

/* Returns a string dictating how long it has been since a time, in human readable words
 * @param {Date} passedTime the time to check
 */
export const timeSince = (passedTime) => {
  let time = passedTime;

  if (time === null) return '';

  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }
  const timeFormats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  const seconds = (+new Date() - time) / 1000;
  const token = 'ago';
  const listChoice = 1;

  if (seconds === 0) {
    return 'Just now';
  }

  let i = 0;
  let timeFormat;

  // eslint-disable-next-line no-cond-assign
  while ((timeFormat = timeFormats[i++]))
    if (seconds < timeFormat[0]) {
      // eslint-disable-next-line no-undef
      if (typeof timeFormat[2] === 'string') return timeFormat[listChoice];
      return `${Math.floor(seconds / timeFormat[2])} ${timeFormat[1]} ${token}`;
    }
  return time;
};

/**
 * Converts a date to a local ISO string that a datetime-local input can read
 * @param {Date} date
 */
export const toDateTimeLocalString = (date) => {
  const pad = function (num) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

/**
 * Converts almst anything to a Date object, does not validate date info
 * @param {*} date the date, string, or timestamp
 * @returns a date object
 */
export const readDate = (date) => {
  if (date === null) {
    return new Date();
  }
  // Return if already a Date object
  if (date instanceof Date) {
    return date;
  }

  // Converts timestamps to date objects
  if (typeof date === 'object') {
    const retDate = new Date(date.seconds * 1000); // access the '_seconds' attribute within the timestamp object
    return retDate;
  }

  // Return a new date if it was a string
  return new Date(date);
};

/**
 * Reads a date and returns in the format YYYY-MM-DD needed to pre-fill a date field
 * @param {*} date the date, string, or timestamp
 */
export const readDateForDateInput = (date) => {
  if (typeof date === 'undefined') return null;
  return readDate(date).toISOString().substr(0, 10);
};

/**
 * Converts almst anything to a Date object, does not validate date info
 * @param {*} date the date, string, or timestamp
 * @returns a date object
 */
export const readDateOrNull = (date) => {
  // Return if already a Date object
  if (date instanceof Date) {
    return date;
  }

  // Converts timestamps to date objects
  if (typeof date === 'object') {
    const retDate = new Date(date.seconds * 1000); // access the '_seconds' attribute within the timestamp object
    return retDate;
  }

  // eslint-disable-next-line no-restricted-globals
  if (new Date(date) !== 'Invalid Date' && new Date(date) !== 'NaN') {
    return new Date(date);
  }

  // Return a new date if it was a string
  return null;
};

// Removes any undefined objects in the data
/**
 * Removes undefined fields from an object
 * @param {Object} obj - the object to clean
 * @returns an object with no 'undefined' fields
 */
export const removeEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  console.log({ obj, newObj });
  return newObj;
};

/**
 * Converts from a date to a timestamp (like firestore returns)
 * @param {*} strDate
 * @returns
 */
export const toTimestamp = (strDate) => {
  const ms = Date.parse(strDate);
  const t = {
    seconds: ms / 1000,
  };
  return t;
};
