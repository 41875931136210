import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { UserContext } from '../../providers/UserProvider';
import { formatDisciplines } from '../../services/util/formatters';
import styles from './ClubRoster.module.scss';
import RosterItem from '../../components/RosterItem/RosterItem';

import {
  listCurrentAthleteClubMemberships,
  listCurrentAdminClubMemberships,
  listPastClubMemberships,
  getRoles,
} from '../../services/club/membership';
import {
  listDeclinedClubPreRegistrations,
  listPendingClubPreRegistrations,
} from '../../services/club/preRegistration';
import {
  listPendingAthleteClubInvites,
  listPendingAdminClubInvites,
  listDeclinedClubInvites,
  createInvite,
  getInvite,
} from '../../services/club/invite';
import { createTeam, listClubTeamsByStatus } from '../../services/club/team';
import { getClub } from '../../services/club/club';
import { sendInviteEmail } from '../../services/util/email';

function ClubRoster({ match }) {
  const { cid } = match.params;
  const [club, setClub] = useState(null);

  const location = useLocation();
  const { pathname, search } = location;
  const query = new URLSearchParams(search);

  const [user] = useContext(UserContext);
  // The roles for the current user
  const [roles, setRoles] = useState(null);

  // Get user's club roles
  useEffect(() => {
    getRoles(cid, user.uid).then((result) => {
      if (result.data) {
        setRoles(result.data);
      } else {
        setRoles([]);
        console.error(result.error);
      }
    });
  }, [user.uid]);

  // Get club information
  useEffect(() => {
    getClub(cid).then((result) => {
      if (result.data) {
        setClub(result.data);
      } else {
        setClub({});
        console.error(result.error);
      }
    });
  }, []);

  const isAdminOrOwner = () =>
    roles && roles.some((role) => ['admin', 'owner'].includes(role));

  let tabs = [];
  if (isAdminOrOwner()) {
    tabs = [
      {
        id: 0,
        name: 'Current',
      },
      {
        id: 1,
        name: 'Past',
      },
      {
        id: 2,
        name: 'Pending',
      },
      {
        id: 3,
        name: 'Admins',
      },
      {
        id: 4,
        name: 'Teams',
      },
    ];
  } else {
    tabs = [
      {
        id: 0,
        name: 'Current',
      },
      {
        id: 3,
        name: 'Admins',
      },
      {
        id: 4,
        name: 'Teams',
      },
    ];
  }

  return (
    roles &&
    club && (
      <div className={styles.clubroster}>
        {/* Club Roster hero */}
        <div className={styles.clubroster_hero}>
          {/* Breadcrumbs */}
          <p>
            /{' '}
            <Link to={`${pathname.replace('/club-roster', '')}`}>
              {club.clubName} Home
            </Link>{' '}
            / <span>Roster</span>
          </p>
          <h1>Roster</h1>
        </div>
        {/* Club Roster body */}
        <div className={styles.clubroster_body}>
          <Tabs
            defaultIndex={parseInt(query.get('tab'), 10) || 0}
            selectedTabClassName={styles.clubroster_body_tab__selected}
          >
            <TabList className={styles.clubroster_body_tablist}>
              {tabs.map((tab) => {
                if (roles.includes('athlete') && tab.id === 2) return null;
                return (
                  <Tab key={tab.id} className={styles.clubroster_body_tab}>
                    <Link
                      to={`${pathname}?tab=${tab.id}`}
                      key={tab.id}
                      className={styles.clubroster_body_tab_link}
                    >
                      {tab.name}
                    </Link>
                  </Tab>
                );
              })}
            </TabList>

            {/* Current */}
            <TabPanel>
              <CurrentClubRoster cid={cid} club={club} roles={roles} />
            </TabPanel>
            {/* Past */}
            {isAdminOrOwner() && (
              <TabPanel>
                <PastClubRoster cid={cid} roles={roles} />
              </TabPanel>
            )}
            {/* Pending */}
            {isAdminOrOwner() && (
              <TabPanel>
                <PendingClubRoster club={club} cid={cid} />
              </TabPanel>
            )}
            {/* Coaches */}
            <TabPanel>
              <AdminsClubRoster cid={cid} club={club} roles={roles} />
            </TabPanel>
            {/* Coaches */}
            <TabPanel>
              <TeamsClubRoster cid={cid} club={club} roles={roles} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    )
  );
}

ClubRoster.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cid: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function CurrentClubRoster({ cid, club, roles }) {
  const [memberships, setMemberships] = useState([]);
  // Modal state
  const [modalShow, setModalShow] = useState(false);
  const [inviteName, setInviteName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'inviteName') {
      setInviteName(value);
    } else if (name === 'inviteEmail') {
      setInviteEmail(value);
    }
  };

  useEffect(() => {
    listCurrentAthleteClubMemberships(cid).then((result) => {
      if (result.data) {
        setMemberships(result.data);
      } else if (result.error) {
        setMemberships([]);
        console.error(result.error);
      }
    });
  }, []);

  return (
    <>
      {roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <div className={styles.clubroster_invite_wrapper}>
          <RosterItem
            title="INVITE A NEW ATHLETE"
            isButton
            action={() => setModalShow(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </RosterItem>
        </div>
      )}
      <section>
        <h2 className="h3">Current Athletes</h2>
        {memberships &&
          (Object.keys(memberships).length > 0 ? (
            Object.keys(memberships).map((uid) => {
              const member = memberships[uid];
              return (
                <RosterItem
                  title={member.displayName}
                  desc={
                    formatDisciplines(member.disciplines) ||
                    'No disciplines specified'
                  }
                  key={uid}
                />
              );
            })
          ) : (
            <p>No members found</p>
          ))}
      </section>
      {/* Invite Modal */}
      {roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title as="h3" className={styles.invite_modal_title}>
              Invite a New Athlete
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(false)}
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="inviteName" className={styles.invite_form_label}>
              Name
            </label>
            <input
              className={styles.invite_form_input}
              type="text"
              name="inviteName"
              id="inviteName"
              value={inviteName}
              placeholder="Name"
              onChange={onChangeHandler}
            />
            <label htmlFor="inviteEmail" className={styles.invite_form_label}>
              Email
            </label>
            <input
              className={styles.invite_form_input}
              type="email"
              name="inviteEmail"
              id="inviteEmail"
              value={inviteEmail}
              placeholder="Email"
              onChange={onChangeHandler}
            />
            <div className="d-flex justify-content-around">
              {error !== null && (
                <p align="center" className={styles.invite_form_error}>
                  {error}
                </p>
              )}
              {error === `${inviteEmail} has already been invited.` && (
                <Button
                  onClick={() => {
                    toast.info(`Invite email sent to ${inviteEmail}`, {
                      autoClose: 3000,
                    });
                    sendInviteEmail(inviteEmail, club.clubName, inviteName);
                  }}
                >
                  Resend Invite
                </Button>
              )}
            </div>
            <button
              className={styles.invite_form_button}
              type="button"
              onClick={() => {
                // Checks if the invite already exists, then creates it if not.
                getInvite(cid, inviteEmail).then((r) => {
                  if (!r.data) {
                    createInvite({
                      displayName: inviteName,
                      email: inviteEmail,
                      cid,
                      clubId: club.clubId,
                      clubName: club.clubName,
                      logoUrl: club.logoUrl,
                    }).then((res) => {
                      if (res.data) {
                        setError(null);
                        setInviteName('');
                        setInviteEmail('');
                        setModalShow(false);
                      } else if (res.error) {
                        console.log(res.error);
                        setError(
                          'An error occured when sending the invite. Please try again.'
                        );
                      }
                    });
                  } else {
                    setError(`${inviteEmail} has already been invited.`);
                  }
                });
              }}
            >
              SEND EMAIL INVITE
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

CurrentClubRoster.propTypes = {
  cid: PropTypes.string.isRequired,
  club: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

function PastClubRoster({ cid, roles }) {
  const [alumni, setAlumni] = useState(null);
  const [preRegistrations, setPreRegistrations] = useState(null);
  const [invites, setInvites] = useState(null);

  useEffect(() => {
    listPastClubMemberships(cid).then((result) => {
      if (result.data) {
        setAlumni(result.data);
      } else if (result.error) {
        setAlumni([]);
        console.error(result.error);
      }
    });
  }, []);

  useEffect(() => {
    if (!roles.includes('athlete')) {
      listDeclinedClubPreRegistrations(cid).then((result) => {
        if (result.data) {
          setPreRegistrations(result.data);
        } else if (result.error) {
          setPreRegistrations([]);
          console.error(result.error);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!roles.includes('athlete')) {
      listDeclinedClubInvites(cid).then((result) => {
        if (result.data) {
          setInvites(result.data);
        } else if (result.error) {
          setInvites([]);
          console.error(result.error);
        }
      });
    }
  }, []);

  return (
    <>
      <section>
        <h2 className="h3">Alumni</h2>
        {alumni &&
          (Object.keys(alumni).length > 0 ? (
            Object.keys(alumni).map((uid) => {
              const member = alumni[uid];
              return (
                <RosterItem
                  title={member.displayName}
                  desc={member.title || 'Athlete'}
                  key={uid}
                />
              );
            })
          ) : (
            <p>No alumni found</p>
          ))}
      </section>
      {!roles.includes('athlete') && (
        <section>
          <h2 className="h3">Declined</h2>
          <h3 className="h4">Pre-Registrations</h3>
          {preRegistrations &&
            (Object.keys(preRegistrations).length > 0 ? (
              Object.keys(preRegistrations).map((uid) => {
                const member = preRegistrations[uid];
                return <RosterItem title={member.displayName} key={uid} />;
              })
            ) : (
              <p>No declined pre-registrations found</p>
            ))}
          <h3 className="h4">Invites (Declined by Invitee)</h3>
          {invites &&
            (Object.keys(invites).length > 0 ? (
              Object.keys(invites).map((uid) => {
                const member = invites[uid];
                return (
                  <RosterItem
                    title={member.displayName}
                    desc={member.title}
                    key={uid}
                  />
                );
              })
            ) : (
              <p>No declined invites found</p>
            ))}
        </section>
      )}
    </>
  );
}

PastClubRoster.propTypes = {
  cid: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
};

function PendingClubRoster({ cid, club }) {
  const [preRegistrations, setPreRegistrations] = useState(null);
  const [invitedAthletes, setInvitedAthletes] = useState(null);
  const [invitedAdmins, setInvitedAdmins] = useState(null);

  useEffect(() => {
    listPendingClubPreRegistrations(cid).then((result) => {
      if (result.data) {
        setPreRegistrations(result.data);
      } else if (result.error) {
        setPreRegistrations([]);
        console.error(result.error);
      }
    });
  }, []);

  useEffect(() => {
    listPendingAthleteClubInvites(cid).then((result) => {
      if (result.data) {
        setInvitedAthletes(result.data);
      } else if (result.error) {
        setInvitedAthletes([]);
        console.error(result.error);
      }
    });
  }, []);

  useEffect(() => {
    listPendingAdminClubInvites(cid).then((result) => {
      if (result.data) {
        setInvitedAdmins(result.data);
      } else if (result.error) {
        setInvitedAdmins([]);
        console.error(result.error);
      }
    });
  }, []);

  return (
    <>
      <section>
        <h2 className="h3">Pending Athletes</h2>
        <h3 className="h4">Pre-Registrations</h3>
        {preRegistrations &&
          (Object.keys(preRegistrations).length > 0 ? (
            Object.keys(preRegistrations).map((uid) => {
              const member = preRegistrations[uid];
              return <RosterItem title={member.displayName} key={uid} />;
            })
          ) : (
            <p>No pending pre-registrations found</p>
          ))}
        <h3 className="h4">Invited</h3>
        {invitedAthletes &&
          (Object.keys(invitedAthletes).length > 0 ? (
            Object.keys(invitedAthletes).map((email) => {
              const member = invitedAthletes[email];
              return (
                <RosterItem
                  title={member.displayName}
                  desc={member.title}
                  key={email}
                  ctaText="Resend Email"
                  ctaAction={() => {
                    toast.info(`Invite email sent to ${email}`, {
                      autoClose: 3000,
                    });
                    sendInviteEmail(email, club.clubName, member.title);
                  }}
                />
              );
            })
          ) : (
            <p>No invited athletes found</p>
          ))}
      </section>
      <section>
        <h2 className="h3">Pending Admins</h2>
        <h3 className="h4">Invited</h3>
        {invitedAdmins &&
          (Object.keys(invitedAdmins).length > 0 ? (
            Object.keys(invitedAdmins).map((email) => {
              const member = invitedAdmins[email];
              return (
                <RosterItem
                  title={member.displayName}
                  desc={member.title}
                  key={email}
                  ctaText="Resend Email"
                  ctaAction={() =>
                    sendInviteEmail(email, club.clubName, member.title)
                  }
                />
              );
            })
          ) : (
            <p>No invited admins found</p>
          ))}
      </section>
    </>
  );
}

PendingClubRoster.propTypes = {
  cid: PropTypes.string.isRequired,
  club: PropTypes.object.isRequired,
};

function AdminsClubRoster({ cid, club, roles }) {
  const [memberships, setMemberships] = useState([]);

  // Modal state
  const [modalShow, setModalShow] = useState(false);
  const [inviteName, setInviteName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteTitle, setInviteTitle] = useState('');
  const [error, setError] = useState(null);

  const [checkedRoles, setCheckedRoles] = useState({
    coach: false,
    admin: false,
    owner: false,
  });

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'inviteName') {
      setInviteName(value);
    } else if (name === 'inviteEmail') {
      setInviteEmail(value);
    } else if (name === 'inviteTitle') {
      setInviteTitle(value);
    }
  };

  useEffect(() => {
    listCurrentAdminClubMemberships(cid).then((result) => {
      if (result.data) {
        setMemberships(result.data);
      } else if (result.error) {
        setMemberships([]);
        console.error(result.error);
      }
    });
  }, []);

  return (
    <>
      {roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <div className={styles.clubroster_invite_wrapper}>
          <RosterItem
            title="INVITE A NEW ADMIN"
            isButton
            action={() => setModalShow(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </RosterItem>
        </div>
      )}
      <section>
        <h2 className="h3">Admins</h2>
        {memberships &&
          Object.keys(memberships).map((uid) => {
            const member = memberships[uid];
            return (
              <RosterItem
                title={member.displayName}
                desc={member.title ? member.title : 'No title specified'}
                key={uid}
              />
            );
          })}
      </section>
      {/* Invite Modal */}
      {roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title as="h3" className={styles.invite_modal_title}>
              Invite a New Admin
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(false)}
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="inviteName" className={styles.invite_form_label}>
              Name
            </label>
            <input
              className={styles.invite_form_input}
              type="text"
              name="inviteName"
              id="inviteName"
              value={inviteName}
              placeholder="Name"
              onChange={onChangeHandler}
            />
            <label htmlFor="inviteEmail" className={styles.invite_form_label}>
              Email
            </label>
            <input
              className={styles.invite_form_input}
              type="email"
              name="inviteEmail"
              id="inviteEmail"
              value={inviteEmail}
              placeholder="Email"
              onChange={onChangeHandler}
            />
            <label htmlFor="inviteTitle" className={styles.invite_form_label}>
              Title
            </label>
            <input
              className={styles.invite_form_input}
              type="text"
              name="inviteTitle"
              id="inviteTitle"
              value={inviteTitle}
              placeholder="Title"
              onChange={onChangeHandler}
            />
            <label htmlFor="inviteRoles" className={styles.invite_form_label}>
              Roles
            </label>
            <div>
              <Form.Check
                inline
                type="checkbox"
                label="Coach"
                checked={checkedRoles.coach}
                onChange={(e) =>
                  setCheckedRoles({ ...checkedRoles, coach: e.target.checked })
                }
              />
              <Form.Check
                inline
                type="checkbox"
                label="Admin"
                checked={checkedRoles.admin}
                onChange={(e) => {
                  setCheckedRoles({
                    ...checkedRoles,
                    admin: e.target.checked,
                  });
                }}
              />
              <Form.Check
                inline
                type="checkbox"
                label="Owner"
                checked={checkedRoles.owner}
                onChange={(e) =>
                  setCheckedRoles({ ...checkedRoles, owner: e.target.checked })
                }
              />
            </div>
            {error !== null && (
              <p align="center" className={styles.invite_form_error}>
                {error}
              </p>
            )}
            <button
              className={styles.invite_form_button}
              type="button"
              onClick={() => {
                createInvite({
                  displayName: inviteName,
                  email: inviteEmail,
                  roles: Object.keys(checkedRoles).filter(
                    (role) => checkedRoles[role] === true
                  ),
                  title: inviteTitle,
                  cid,
                  clubId: club.clubId,
                  clubName: club.clubName,
                  logoUrl: club.logoUrl,
                }).then((res) => {
                  if (res.data) {
                    setError(null);
                    setInviteName('');
                    setInviteEmail('');
                    setInviteTitle('');
                    setCheckedRoles({
                      coach: false,
                      admin: false,
                      owner: false,
                    });
                    setModalShow(false);
                  } else if (res.error) {
                    setError(
                      'An error occured when sending the invite. Please try again.'
                    );
                  }
                });
              }}
            >
              SEND EMAIL INVITE
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

AdminsClubRoster.propTypes = {
  cid: PropTypes.string.isRequired,
  club: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

function TeamsClubRoster({ cid, club, roles }) {
  const [activeTeams, setActiveTeams] = useState(null);
  const [inactiveTeams, setInactiveTeams] = useState(null);

  // Modal state
  const [modalShow, setModalShow] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [error, setError] = useState(null);

  const initialDisciplinesState = {
    trap: false,
    skeet: false,
    sportingClays: false,
    fiveStand: false,
    fitasc: false,
    iskeet: false,
    bunkerTrap: false,
  };

  const history = useHistory();

  const [checkedDisciplines, setCheckedDisciplines] = useState(
    initialDisciplinesState
  );

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'teamName') {
      setTeamName(value);
    } else if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    } else if (name === 'qualifications') {
      setQualifications(value);
    }
  };

  useEffect(() => {
    listClubTeamsByStatus(cid, true).then((result) => {
      if (result.data) {
        setActiveTeams(result.data);
      } else if (result.error) {
        setActiveTeams([]);
        console.error(result.error);
      }
    });
  }, []);

  useEffect(() => {
    listClubTeamsByStatus(cid, false).then((result) => {
      if (result.data) {
        setInactiveTeams(result.data);
      } else if (result.error) {
        setInactiveTeams([]);
        console.error(result.error);
      }
    });
  }, []);

  return (
    <>
      {roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <div className={styles.clubroster_invite_wrapper}>
          <RosterItem
            title="CREATE A NEW TEAM"
            isButton
            action={() => setModalShow(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </RosterItem>
        </div>
      )}
      <section>
        <h2 className="h3">Active Teams</h2>
        {activeTeams &&
          (Object.keys(activeTeams).length > 0 ? (
            Object.keys(activeTeams).map((tid) => {
              const team = activeTeams[tid];
              return (
                <RosterItem
                  title={team.name}
                  desc={
                    formatDisciplines(team.disciplines) ||
                    'No disciplines specified'
                  }
                  key={tid}
                  isButton
                  action={() => {
                    history.push(`/clubs/${cid}/teams/${tid}`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    className="bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fillRule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                </RosterItem>
              );
            })
          ) : (
            <p>No active teams found</p>
          ))}

        <h2 className="h3">Inactive Teams</h2>
        {inactiveTeams &&
          (Object.keys(inactiveTeams).length > 0 ? (
            Object.keys(inactiveTeams).map((tid) => {
              const team = inactiveTeams[tid];
              return (
                <RosterItem
                  title={team.name}
                  desc={
                    formatDisciplines(team.disciplines) ||
                    'No disciplines specified'
                  }
                  key={tid}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fillRule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                </RosterItem>
              );
            })
          ) : (
            <p>No inactive teams found</p>
          ))}
      </section>
      {/* Create Team Modal */}
      {roles.some((role) => ['coach', 'admin', 'owner'].includes(role)) && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title as="h3" className={styles.invite_modal_title}>
              Create a New Team
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setModalShow(false)}
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="teamName" className={styles.invite_form_label}>
              What is your team&apos;s name?
            </label>
            <input
              className={styles.invite_form_input}
              type="text"
              name="teamName"
              id="teamName"
              value={teamName}
              placeholder="Team Name"
              onChange={onChangeHandler}
            />
            <label htmlFor="startDate" className={styles.invite_form_label}>
              What is the season start date?
            </label>
            <input
              className={styles.invite_form_input}
              type="date"
              name="startDate"
              id="startDate"
              value={startDate}
              placeholder="Choose date"
              onChange={onChangeHandler}
            />
            <label htmlFor="endDate" className={styles.invite_form_label}>
              What is the season end date?
            </label>
            <input
              className={styles.invite_form_input}
              type="date"
              name="endDate"
              id="endDate"
              value={endDate}
              placeholder="Choose date"
              onChange={onChangeHandler}
            />
            <label
              htmlFor="qualifications"
              className={styles.invite_form_label}
            >
              What qualifications are required for this team?
            </label>
            <textarea
              className={styles.invite_form_input}
              type="textarea"
              name="qualifications"
              id="qualifications"
              value={qualifications}
              placeholder="Team qualifications"
              onChange={onChangeHandler}
            />
            <label htmlFor="inviteRoles" className={styles.invite_form_label}>
              Select any disciplines relevant to this team.
            </label>
            <div>
              <Form.Check
                inline
                type="checkbox"
                label="Trap"
                checked={checkedDisciplines.trap}
                onChange={(e) =>
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    trap: e.target.checked,
                  })
                }
              />
              <Form.Check
                inline
                type="checkbox"
                label="Skeet"
                checked={checkedDisciplines.skeet}
                onChange={(e) => {
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    skeet: e.target.checked,
                  });
                }}
              />
              <Form.Check
                inline
                type="checkbox"
                label="Sporting Clays"
                checked={checkedDisciplines.sportingClays}
                onChange={(e) =>
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    sportingClays: e.target.checked,
                  })
                }
              />
              <Form.Check
                inline
                type="checkbox"
                label="5-Stand"
                checked={checkedDisciplines.fiveStand}
                onChange={(e) => {
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    fiveStand: e.target.checked,
                  });
                }}
              />
              <Form.Check
                inline
                type="checkbox"
                label="FITASC"
                checked={checkedDisciplines.fitasc}
                onChange={(e) => {
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    fitasc: e.target.checked,
                  });
                }}
              />
              <Form.Check
                inline
                type="checkbox"
                label="International Skeet (iskeet)"
                checked={checkedDisciplines.iskeet}
                onChange={(e) => {
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    iskeet: e.target.checked,
                  });
                }}
              />
              <Form.Check
                inline
                type="checkbox"
                label="Bunker Trap"
                checked={checkedDisciplines.bunkerTrap}
                onChange={(e) => {
                  setCheckedDisciplines({
                    ...checkedDisciplines,
                    bunkerTrap: e.target.checked,
                  });
                }}
              />
            </div>
            {error !== null && (
              <p align="center" className={styles.invite_form_error}>
                {error}
              </p>
            )}
            <button
              className={styles.invite_form_button}
              type="button"
              onClick={() => {
                createTeam(cid, {
                  name: teamName,
                  seasonStartDate: new Date(startDate),
                  seasonEndDate: new Date(endDate),
                  qualifications,
                  disciplines: Object.keys(checkedDisciplines).filter(
                    (disc) => checkedDisciplines[disc] === true
                  ),
                }).then((res) => {
                  if (res.data) {
                    setError(null);
                    setTeamName('');
                    setStartDate('');
                    setEndDate('');
                    setQualifications('');
                    setCheckedDisciplines(initialDisciplinesState);
                    setModalShow(false);
                    setActiveTeams({ ...activeTeams, ...res.data });
                  } else if (res.error) {
                    console.log(res.error);
                    setError(
                      'An error occured creating the team. Please try again.'
                    );
                  }
                });
              }}
            >
              CREATE TEAM
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

TeamsClubRoster.propTypes = {
  cid: PropTypes.string.isRequired,
  club: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

export default ClubRoster;
