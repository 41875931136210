import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { PropTypes } from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getRoles } from '../../services/club/membership';
import styles from '../ClubSchedules/ClubSchedules.module.scss';
import { UserContext } from '../../providers/UserProvider';
import {
  getClubByClubId,
  getClub,
  deleteClub,
  updateClub,
} from '../../services/club/club';
import { toDateTimeLocalString } from '../../services/util/formatters';
import { Disciplines, Associations } from '../../services/util/enum';
import FileUpload from '../../components/FileUpload/FileUpload';

const ClubEditor = ({ match }) => {
  const { cid } = match.params;
  const [user] = useContext(UserContext);
  const [checkedDisciplines, setCheckedDisciplines] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  const [club, setClubData] = useState(null);

  const [roles, setRoles] = useState(null);
  const [pauseFunc, setPauseFunc] = useState(false);

  // Get user's club roles
  useEffect(() => {
    getRoles(cid, user.uid).then((result) => {
      if (result.data) {
        setRoles(result.data);
      } else {
        setRoles([]);
        console.error(result.error);
      }
    });
  }, [user.uid]);

  // Get club informaton based on the url
  useEffect(() => {
    getClub(cid).then((result) => {
      if (result.data) {
        setClubData({
          clubId: result.data.clubId,
          clubName: result.data.clubName,
          owner: user,
          street: result.data.location.street,
          city: result.data.location.city,
          state: result.data.location.state,
          zip: result.data.location.zip,
          logoUrl: result.data.logoUrl,
          introduction: result.data.introduction,
        });
        setCheckedDisciplines(result.data.disciplines);
      } else {
        // setError(result.err)
      }
    });
  }, []);

  const handleDeleteChange = (e) => {
    e.preventDefault();
    setDeleteConfirmation(e.target.value);
  };

  const handleDeletion = (e) => {
    deleteClub(cid).then((res) => {
      if (res.error) {
        console.error('An error occurred while deleting club: ', res.error);
      } else {
        history.push(`/dashboard`);
      }
    });
  };

  const setClubPhotoUrl = (url) => {
    setClubData({
      ...club,
      logoUrl: url,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create temp activity to throw on page before next DB call
    const newClub = {
      clubName: club.clubName,
      owner: user,
      location: {
        street: club.street,
        city: club.city,
        state: club.state,
        zip: club.zip,
      }, // Add to UI?
      logoUrl: club.logoUrl,
      introduction: club.introduction,
      disciplines: checkedDisciplines,
    };

    console.log('new club', newClub);

    updateClub(cid, newClub).then((res) => {
      if (res.error) {
        console.error('An error occurred while creating club: ', res.error);
      } else {
        history.push(`/clubs/${cid}`);
      }
    });
  };

  const onChange = (e) => {
    // Check if the input changed was a checkbox
    if (e.target.type === 'checkbox') {
      // It it was, assign its value in the association object
      const newAssociations = Object.assign(club.associations, {
        [e.target.name]: e.target.checked,
      });
      setClubData((prevState) => ({
        ...prevState,
        associations: newAssociations,
      }));
    } else {
      // Auto-generate the clubId based off the club name
      if (e.target.name === 'clubName' && club.clubIdIsAuto) {
        setClubData((prevState) => ({
          ...prevState,
          clubId: e.target.value
            .replace(/ /g, '')
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase(),
        }));
      }

      if (e.target.name === 'clubId' && club.clubIdIsAuto) {
        // If modifying the clubId to be different than the automatic
        setClubData((prevState) => ({
          ...prevState,
          clubIdIsAuto: false,
        }));
      } else if (
        e.target.name === 'clubId' &&
        e.target.value === '' &&
        !club.clubIdIsAuto
      ) {
        // Reset if emptied out
        setClubData((prevState) => ({
          ...prevState,
          clubIdIsAuto: true,
        }));
      }

      // General catch-all
      setClubData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  // Conditionally render club actions based on a user's roles
  function clubActionSwitch() {
    switch (true) {
      case roles.some((ai) => ['owner'].includes(ai)):
        return (
          <div className={styles.clubschedules}>
            <div className={styles.clubschedules_hero}>
              {/* Breadcrumbs */}
              <p>
                /{' '}
                <Link to={`${pathname.replace('/club-editor', '')}`}>
                  {club?.clubName} Home
                </Link>{' '}
                / <span>Club Editor</span>
              </p>
              <h1>Club Editor</h1>
            </div>

            <form className="container" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="clubName"
                    className={styles.activity_form_label}
                  >
                    Club Name
                  </label>
                  <input
                    className={styles.activity_form_input}
                    type="text"
                    name="clubName"
                    id="clubName"
                    required
                    onChange={onChange}
                    value={club?.clubName}
                  />
                </div>
                <div className="col">
                  <label
                    htmlFor="clubId"
                    className={styles.activity_form_label}
                  >
                    Club ID
                  </label>
                  <input
                    className={styles.activity_form_input}
                    type="text"
                    name="clubId"
                    id="clubId"
                    required
                    disabled
                    onChange={onChange}
                    value={club?.clubId}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="introduction"
                    className={styles.activity_form_label}
                  >
                    Introduction
                  </label>
                  <textarea
                    className={styles.activity_form_input}
                    type="text"
                    name="introduction"
                    id="introduction"
                    onChange={onChange}
                    value={club?.introduction}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="city" className={styles.activity_form_label}>
                    City
                  </label>
                  <input
                    className={styles.activity_form_input}
                    type="text"
                    name="city"
                    required
                    id="city"
                    value={club?.city}
                    onChange={onChange}
                  />
                </div>
                <div className="col">
                  <label htmlFor="state" className={styles.activity_form_label}>
                    State
                  </label>
                  <select
                    onChange={onChange}
                    name="state"
                    required
                    value={club?.state}
                    className={styles.activity_form_input}
                    id="state"
                  >
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="street"
                    className={styles.activity_form_label}
                  >
                    Street
                  </label>
                  <input
                    className={styles.activity_form_input}
                    type="text"
                    name="street"
                    required
                    id="street"
                    value={club?.street}
                    onChange={onChange}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="zip" className={styles.activity_form_label}>
                    Zip
                  </label>
                  <input
                    className={styles.activity_form_input}
                    type="text"
                    pattern="[0-9]{5}"
                    name="zip"
                    required
                    id="zip"
                    value={club?.zip}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="clubDisciplines"
                    className={styles.activity_form_label}
                  >
                    Disciplines:
                  </label>
                  <br />
                  {Disciplines.values().map((disciplines) => (
                    <Form.Check
                      key={`clubDiscipline${disciplines}`}
                      inline
                      type="checkbox"
                      className={styles.activity_form_input_checkbox}
                      label={Disciplines[disciplines].name}
                      checked={checkedDisciplines.includes(disciplines)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedDisciplines([
                            ...checkedDisciplines,
                            disciplines,
                          ]);
                        } else {
                          setCheckedDisciplines(
                            checkedDisciplines.filter(
                              (id) => id !== disciplines
                            )
                          );
                        }
                      }}
                    />
                  ))}
                </div>
                <div className="col-5 d-flex align-items-center justify-content-end">
                  <div className="w-100">
                    <label
                      htmlFor="logoUrl"
                      className={styles.activity_form_label}
                    >
                      Logo URL
                    </label>
                    <input
                      className={styles.activity_form_input}
                      type="text"
                      name="logoUrl"
                      id="logoUrl"
                      value={club?.logoUrl}
                      onChange={onChange}
                    />
                  </div>
                  <div className="p-l-10 d-flex align-items-center">
                    <FileUpload
                      folder="clubLogos"
                      setFileUrl={setClubPhotoUrl}
                      setPauseFunc={setPauseFunc}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  className={` w-auto ${styles.delete_club_button}`}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                  type="button"
                >
                  DELETE CLUB
                </button>

                <button
                  className={`${styles.activity_form_button} w-auto  p-x-20`}
                  disabled={pauseFunc ? 'disabled' : ''}
                  type="submit"
                >
                  SAVE
                </button>
              </div>
            </form>
            <Modal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              size="lg"
              centered
              animation={false}
            >
              <Modal.Header>
                <Modal.Title as="h3" className={styles.invite_modal_title}>
                  Delete {club?.clubName}?
                </Modal.Title>
                <Button
                  variant="outline-danger"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Close
                </Button>
              </Modal.Header>
              <Modal.Body>
                Type the name of the club to proceed with deletion:
                <input
                  type="text"
                  value={deleteConfirmation}
                  onChange={handleDeleteChange}
                  className={styles.activity_form_input}
                />
                {club && (
                  <button
                    className={`${styles.delete_club_button}`}
                    disabled={
                      deleteConfirmation === club.clubName ? '' : 'disabled'
                    }
                    onClick={() => {
                      handleDeletion();
                    }}
                    type="button"
                  >
                    DELETE CLUB
                  </button>
                )}
              </Modal.Body>
            </Modal>
          </div>
        );
      default:
        return (
          <div className="container">
            <div className="row p-t-100">
              <div className="col">
                <p>You do not have permission to edit this club</p>
                <Link to="/dashboard">Return Home</Link>
              </div>
            </div>
          </div>
        );
    }
  }

  return (
    <div className={styles.clubhome_management}>
      {roles && clubActionSwitch(roles)}
    </div>
  );
};

export default ClubEditor;

ClubEditor.propTypes = {
  match: PropTypes.object.isRequired,
};
