/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import styles from '../../pages/ClubSchedules/ClubSchedules.module.scss';
import { Disciplines } from '../../services/util/enum';
import scorecardStyles from './Scorecard.module.scss';
import {
  createUpdateScorecard,
  getScorecard,
} from '../../services/activity/scores';

const ScorecardModal = ({
  uid,
  cid,
  scorecardModalShow,
  disciplines,
  setScorecardModalShow,
  dataForScorecardModal,
}) => {
  const [scorecard, setScorecardData] = useState({
    aid: dataForScorecardModal?.aid || '',
    uid: uid,
    cid: cid,
    dateTime: dataForScorecardModal?.dateTime || null, // To be populated from Activity time if possible
    startingAverage: 0,
    notes: '',
  });

  const [numberOfEvents, setNumberOfEvents] = useState(1);
  const [eventInputs, setEventInputs] = useState();
  const [eventDatabaseData, setEventDatabaseData] = useState();
  const [eventData, setEventData] = useState({});

  // Creates an event "section"
  useEffect(() => {
    const tempInputs = [];
    const data = eventDatabaseData
      ? Object.assign(eventData, eventDatabaseData)
      : eventData;
    const maxLen = (data && Object.keys(data).length) || numberOfEvents;
    /* eslint-disable no-plusplus */
    for (let i = 1; i <= maxLen; i++) {
      tempInputs.push(
        <div className="row">
          <EventInputSection
            key={`eventInput${i}`}
            data={data && data[`event${i}`]}
            index={i}
          />
        </div>
      );
      setEventInputs(tempInputs);
      setNumberOfEvents(maxLen);
    }
  }, [eventDatabaseData]);

  const clearScorecardModal = () => {
    setScorecardModalShow(false);
    setNumberOfEvents(1);
    setScorecardData({
      aid: '',
      uid: uid,
      cid: cid,
      dateTime: null, // To be populated from Activity time if possible
      startingAverage: 0,
      notes: '',
    });
    setEventDatabaseData(null);
    setEventInputs(null);
    setEventData({});
  };

  /**
   * Combine all data and Create or Update scorecard
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    const tempScorecard = { ...scorecard };

    tempScorecard.events = eventData;
    console.log(tempScorecard);
    createUpdateScorecard(tempScorecard).then((res) => {
      if (!res.error) {
        dataForScorecardModal.setHasRecordedScores(true);
        clearScorecardModal();
      }
      const { data, error } = res;
      console.log({ data, error });
    });
  };

  /**
   * Jumps to the next input in an event
   */
  const jumpScoreInput = (e) => {
    e.target.parentNode.nextElementSibling
      ?.querySelectorAll('input')[0]
      ?.focus();
  };

  /**
   * Jumps to the next input in an event
   */
  const jumpToFirstScore = (e) => {
    setTimeout(
      () =>
        e.target.parentNode.parentNode.nextElementSibling
          ?.querySelectorAll('input')[0]
          ?.focus(),
      50
    );
  };

  /**
   * clears out an item on refocus
   */
  const handleScoreFocus = (e) => {
    if (e.target.value.length > 0) {
      e.target.value = '';
    }
  };

  // Makes sure to add any data for the modal that might be a little slow
  useEffect(() => {
    setScorecardData((prevState) => ({
      ...prevState,
      aid: dataForScorecardModal?.aid || '',
      dateTime: dataForScorecardModal?.dateTime,
    }));

    let d;
    if (
      (cid && scorecard?.aid?.length > 0) ||
      (dataForScorecardModal?.aid?.length > 0 && uid)
    ) {
      getScorecard(cid, scorecard.aid || dataForScorecardModal.aid, uid).then(
        (result) => {
          if (result.data) {
            d = result.data;
            setScorecardData((prevState) => ({
              ...prevState,
              dateTime: d.dateTime,
              notes: d.notes,
              startingAverage: d.startingAverage,
            }));
            const newEventData = d.events;
            console.log('newEventData', newEventData);
            if (newEventData) setEventDatabaseData(newEventData);
          } else {
            console.info(result.err || 'No Scorecards for ths event');
          }
        }
      );
    }
  }, [dataForScorecardModal]);

  /**
   * Update state when stuff is getting changed
   * @param {*} e
   */
  const onChange = (e) => {
    setScorecardData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  /**
   * Controls each event's inputs
   * @param {{}} index of the specific event
   */
  const EventInputSection = ({ index, data }) => {
    const [displayScoreInputs, setDisplayScoreInputs] = useState(false);
    const [denominator, setDenominator] = useState(null);
    const [scores, setScores] = useState(data?.scores || {});
    const [scoreInputs, setScoreInputs] = useState();
    const [numOfScores, setnumOfScores] = useState(4);
    const [discipline, setDiscipline] = useState(data?.discipline);

    const handleScoreInput = (e) => {
      console.log(e);
      // Prevent putting more than 2 chars in a number field
      if (e.target.value.length > e.target.maxLength) {
        // Catch a pressed key and add it to a freshly cleared number field
        e.target.value = e.nativeEvent.data;
      } else if (e.target.value.length === e.target.maxLength) {
        // Jump scores if 2 chars
        jumpScoreInput(e);
      }
      setScores((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));

      const tempScores = Object.assign(scores, {
        [e.target.name]: e.target.value,
      });

      const tempEvent = {
        index: index,
        discipline: discipline,
        scores: tempScores,
      };

      // Only update if something changed
      if (
        !eventData || // if there is no event data in state
        !eventData[`event${index}`] || // if the specific event does not exist in state
        JSON.stringify(tempEvent) !== // if the event's data does not match its equivalent in state
          JSON.stringify(eventData[`event${index}`])
      ) {
        setEventData((prevState) => ({
          ...prevState,
          [`event${index}`]: tempEvent,
        }));
      }
    };

    // ON LOAD, set all initial data from passed in data
    useEffect(() => {
      const e = { ...eventData };

      if (e && Object.keys(e).length > 0) {
        setDiscipline(e[`event${index}`]?.discipline);
        setDisplayScoreInputs(e[`event${index}`]?.discipline);
        setDenominator(
          Disciplines[e[`event${index}`]?.discipline]?.shotsPerRound
        );
        setScores(scores || e[`event${index}`]?.scores);
      }
    }, []);

    // Update score inputs when adding more inputs, or changing denominators
    // useEffect(() => {
    //   const tempInputs = [];
    //   console.log();
    //   for (let i = 1; i <= numOfScores; i++) {
    //     tempInputs.push(
    //       <div className="col" key={Math.random()}>
    //         <input
    //           type="number"
    //           className={scorecardStyles.scoreInput}
    //           name={`score${i}`}
    //           maxLength="2"
    //           min="0"
    //           onFocus={handleScoreFocus}
    //           onChange={handleScoreInput}
    //           onBlur={saveScore}
    //           value={scores && scores[`score${i}`]}
    //           max={denominator}
    //         />
    //         {console.log('score', scores && scores[`score${i}`])}
    //         <div className={scorecardStyles.inputDenominator}>
    //           {denominator}
    //         </div>
    //       </div>
    //     );
    //     // setScoreInputs(tempInputs);
    //   }
    // }, [numOfScores, denominator]);

    const handleEventDisciplineChange = (e) => {
      setDisplayScoreInputs(e.target.value !== '');
      setDenominator(Disciplines[e.target.value]?.shotsPerRound);
      setDiscipline(e.target.value);
      jumpToFirstScore(e);
    };

    /**
     * Update eventData when an event is changed
     */
    useEffect(() => {
      const tempEvent = {
        index: index,
        discipline: discipline,
        scores: scores,
      };

      // Only update if something changed
      if (
        !eventData || // if there is no event data in state
        !eventData[`event${index}`] || // if the specific event does not exist in state
        JSON.stringify(tempEvent) !== // if the event's data does not match its equivalent in state
          JSON.stringify(eventData[`event${index}`])
      ) {
        console.log('Temp Event: ', tempEvent);
        setEventData((prevState) => ({
          ...prevState,
          [`event${index}`]: tempEvent,
        }));
      }
    }, [discipline]);

    return (
      <>
        <div className="col-12 fw-bold">Event {index}</div>
        {console.log({
          eventData,
          scores,
          discipline,
          scoreInputs,
          eventInputs,
          data,
          index,
        })}
        <div
          className="col"
          style={{
            backgroundColor: '#EEE',
            borderRadius: 5,
            margin: '10px 10px',
            padding: 10,
          }}
        >
          <div className="row">
            <div className="col">
              <div className="d-flex m-b-5 justify-content-between align-items-center">
                <label
                  htmlFor="e1discipline"
                  className={styles.activity_form_label}
                >
                  Discipline:
                </label>
              </div>
              <select
                value={discipline}
                onChange={handleEventDisciplineChange}
                name="discipline"
                className={styles.activity_form_input}
              >
                <option value="" />
                {disciplines?.map((d) => (
                  <option key={d} value={d}>
                    {Disciplines[d]?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {displayScoreInputs && (
            <div className="row">
              <div className="col">
                <input
                  type="number"
                  className={scorecardStyles.scoreInput}
                  name="score1"
                  maxLength="2"
                  min="0"
                  onFocus={handleScoreFocus}
                  onChange={handleScoreInput}
                  value={scores?.score1}
                  max={denominator}
                />
                <div className={scorecardStyles.inputDenominator}>
                  {denominator}
                </div>
              </div>
              <div className="col">
                <input
                  type="number"
                  className={scorecardStyles.scoreInput}
                  name="score2"
                  maxLength="2"
                  min="0"
                  onFocus={handleScoreFocus}
                  onChange={handleScoreInput}
                  value={scores?.score2}
                  max={denominator}
                />
                <div className={scorecardStyles.inputDenominator}>
                  {denominator}
                </div>
              </div>
              <div className="col">
                <input
                  type="number"
                  className={scorecardStyles.scoreInput}
                  name="score3"
                  maxLength="2"
                  min="0"
                  onFocus={handleScoreFocus}
                  onChange={handleScoreInput}
                  value={scores?.score3}
                  max={denominator}
                />
                <div className={scorecardStyles.inputDenominator}>
                  {denominator}
                </div>
              </div>
              <div className="col">
                <input
                  type="number"
                  className={scorecardStyles.scoreInput}
                  name="score4"
                  maxLength="2"
                  min="0"
                  onFocus={handleScoreFocus}
                  onChange={handleScoreInput}
                  value={scores?.score4}
                  max={denominator}
                />
                <div className={scorecardStyles.inputDenominator}>
                  {denominator}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  EventInputSection.propTypes = {
    index: PropTypes.number.isRequired,
    data: PropTypes.any,
  };

  EventInputSection.defaultProps = {
    data: null,
  };

  return (
    <Modal
      show={scorecardModalShow}
      onHide={() => {
        setScorecardModalShow(false);
        clearScorecardModal();
      }}
      size="lg"
      centered
      animation={false}
    >
      <Modal.Header className="d-flex scorecardMoDal flex-column">
        <div className="d-flex justify-content-between w-100">
          <Modal.Title as="h3" className={styles.invite_modal_title}>
            {dataForScorecardModal !== 'undefined'
              ? dataForScorecardModal?.title
              : 'Change activity attendance'}
          </Modal.Title>
          <Button
            variant="outline-danger"
            onClick={() => {
              setScorecardModalShow(false);
              clearScorecardModal();
            }}
          >
            Close
          </Button>
        </div>
        <div className="d-flex justify-content-start w-100">
          {`${dataForScorecardModal?.month} ${dataForScorecardModal?.day}`}
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {eventInputs}
          <div className="d-flex m-b-20 align-items-center justify-content-between">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                const newEvents = [
                  ...eventInputs,
                  <div className="row" key={Math.random()}>
                    <EventInputSection
                      key={numberOfEvents + 1}
                      data={
                        eventData && eventData[`event${numberOfEvents + 1}`]
                      }
                      index={numberOfEvents + 1}
                    />
                  </div>,
                ];
                setEventInputs(newEvents);
                setNumberOfEvents(numberOfEvents + 1);
              }}
            >
              + Add Event
            </Button>
            <Button
              type="button"
              variant="danger"
              onClick={() => {
                if (numberOfEvents > 1) {
                  const newEvents = {};

                  console.log('numberOfEvents', numberOfEvents);

                  const indexToRemove = numberOfEvents - 1;

                  let i = 1;
                  Object.keys(eventData).forEach((key) => {
                    if (eventData[key].index !== indexToRemove) {
                      newEvents[`event${i}`] = Object.assign(eventData[key], {
                        index: i++,
                      });
                    }
                  });

                  const newEventInputs = [...eventInputs];
                  newEventInputs.splice(indexToRemove, 1);

                  const compressed = [];

                  newEventInputs.forEach((input) => {
                    compressed.push(input);
                  });

                  setEventInputs(compressed);

                  setEventData(newEvents);
                  setNumberOfEvents(numberOfEvents - 1);
                }
              }}
            >
              - Delete last event
            </Button>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="title" className={styles.activity_form_label}>
                Notes:
              </label>
              <textarea
                className={styles.activity_form_input}
                type="text"
                name="notes"
                id="notes"
                onChange={onChange}
                value={scorecard.notes}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={(e) => {}}
              >
                SAVE ALL
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ScorecardModal;

ScorecardModal.propTypes = {
  uid: PropTypes.string.isRequired,
  cid: PropTypes.string.isRequired,
  disciplines: PropTypes.any.isRequired,
  scorecardModalShow: PropTypes.bool.isRequired,
  setScorecardModalShow: PropTypes.any.isRequired,
  dataForScorecardModal: PropTypes.any.isRequired,
};
