import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import Navbar from '../components/Navbar/Navbar';

export default function AuthenticatedRoute({
  component: C,
  appProps,
  ...rest
}) {
  // Determines the routes
  const [user] = useContext(UserContext);

  // If authenticated and confirmed user, load authenticated routes
  if (user) {
    return (
      <>
        <Navbar />
        <Route {...rest} render={(props) => <C {...props} {...appProps} />} />
      </>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={`?redirect=${props.location.pathname}${props.location.search}`}
        />
      )}
    />
  );
}

AuthenticatedRoute.defaultProps = {
  appProps: null,
  rest: null,
  props: null,
  location: null,
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  appProps: PropTypes.any,
  rest: PropTypes.any,
  props: PropTypes.object,
  location: PropTypes.object,
};
