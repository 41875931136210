import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './ClubCard.module.scss';

// eslint-disable-next-line react/prop-types
const ClubCard = ({ club, cid, myClub }) => {
  const { clubName, title, logoUrl } = club;
  return (
    <Link
      className={`${styles.link_wrapper} ${myClub ? `${styles.myClub}` : ''}`}
      to={`/clubs/${cid}`}
    >
      <div className={styles.clubcard}>
        <div className={styles.clubcard_image_wrapper}>
          <img
            className={styles.clubcard_image}
            src={
              logoUrl ||
              'https://placeholderlogo.com/img/placeholder-logo-1.png'
            }
            alt="Club logo"
          />
        </div>
        <div className={styles.clubcard_info_wrapper}>
          <div className={styles.clubcard_info_left}>
            <h3>{clubName}</h3>
            <p>{title || 'See More...'}</p>
          </div>
          <div className={styles.clubcard_info_right}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
      </div>
    </Link>
  );
};

ClubCard.propTypes = {
  club: PropTypes.object.isRequired,
  cid: PropTypes.string.isRequired,
};

export default ClubCard;
