import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { doSendPasswordResetEmail } from '../../services/auth';
import styles from './PasswordReset.module.scss';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'userEmail') {
      setEmail(value);
    }
  };

  return (
    <div className={styles.forgotPassword}>
      {/* Password Reset hero */}
      <div className={styles.forgotPassword_hero_wrapper}>
        <div>
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
              stroke="white"
              strokeWidth="1"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </Link>
        </div>
        <div className={styles.forgotPassword_hero}>
          <h1 className={styles.forgotPassword_hero_heading}>
            Forgot Password?
          </h1>
          <div className={styles.forgotPassword_hero_bg} />
        </div>
        <div className="invisible">Spacing</div>
      </div>
      {/* Password Reset form */}
      <div className={styles.forgotPassword_form}>
        <label htmlFor="userEmail" className={styles.forgotPassword_form_label}>
          Email:
        </label>
        <div className={styles.forgotPassword_form_input_group}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            id="svg"
            className={`${styles.forgotPassword_form_input_icon} bi bi-envelope`}
            viewBox="0 0 16 16"
          >
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
          </svg>
          <input
            className={styles.forgotPassword_form_input}
            type="email"
            name="userEmail"
            id="userEmail"
            value={email}
            placeholder="Email"
            onChange={onChangeHandler}
          />
        </div>
        {emailHasBeenSent && (
          <p align="center" className={styles.forgotPassword_form_success}>
            A password reset email has been sent to you
          </p>
        )}
        {error !== null && (
          <p align="center" className={styles.forgotPassword_form_error}>
            {error}
          </p>
        )}
        {/* Forgot Password actions */}
        <button
          className={styles.forgotPassword_form_button}
          type="button"
          onClick={(event) => {
            doSendPasswordResetEmail(
              event,
              email,
              setEmailHasBeenSent,
              setError
            );
          }}
        >
          RESET PASSWORD
        </button>
        <div className={styles.forgotPassword_form_or}>or</div>
        <div className={styles.forgotPassword_form_link_wrapper}>
          <Link className={styles.forgotPassword_form_link} to="/">
            BACK TO LOGIN
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
