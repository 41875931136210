import { firestore, currentTime } from '../../firebase';
import { listQuery } from '../util/pagination';

/**
 * @file Functions for handling teams within a club.
 */

const CLUBS_REF = firestore.collection('clubs');

/**
 * Create a team within a club
 * @param {string} cid - The id of the club
 * @param {object} team - The team to create -> TODO: Specify parameters here
 * @returns {object} { data, error }
 * @function */
export const createTeam = async (cid, team) => {
  let data = null;
  let error = null;

  const teamDoc = {
    ...team,
    active: true,
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    const docRef = await CLUBS_REF.doc(cid).collection('teams').add(teamDoc);

    const newTeam = await docRef.get();

    if (newTeam.exists) {
      data = { [newTeam.id]: newTeam.data() };
    } else {
      throw new Error(`An error occurred for team: ${docRef.id}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * List the teams that a user belongs to
 * @param {string} uid - a user's id
 * @returns {object} { data, first, last, error }
 * @function */
export const listTeamsForUser = async (uid) => {
  const { data, first, last, error } = await listQuery(
    firestore.collectionGroup('members').where('uid', '==', uid),
    'teamName',
    'teams'
  );

  return { data, first, last, error };
};

/**
 * List the teams within a club
 * @param {*} cid - the id of a club
 * @returns {object} { data, first, last, error }
 * @function */
export const listClubTeams = async (cid) => {
  const query = CLUBS_REF.doc(cid).collection('teams');
  const { data, first, last, error } = await listQuery(
    query,
    'seasonStartDate',
    'teams'
  );
  return { data, first, last, error };
};

/**
 * List the active teams within a club
 * @param {string} cid - the id of a club
 * @param {boolean} active - t/f if the team is active or not
 * @returns {object} { data, first, last, error }
 * @function */
export const listClubTeamsByStatus = async (cid, active) => {
  const query = CLUBS_REF.doc(cid)
    .collection('teams')
    .where('active', '==', active);
  const { data, first, last, error } = await listQuery(
    query,
    'seasonStartDate',
    'teams'
  );
  return { data, first, last, error };
};

/**
 * Get a team by id
 * @param {string} cid - the id of a club
 * * @param {String} tid - the id of a team
 * @returns {object} { data, error }
 * @function */
export const getTeam = async (cid, tid) => {
  let data = null;
  let error = null;
  try {
    const profile = await CLUBS_REF.doc(cid).collection('teams').doc(tid).get();
    if (profile.exists) {
      data = profile.data();
    } else {
      throw new Error(`A team does not exist with id: ${tid}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update a team
 * @param {string} cid - the id of a club
 * @param {string} tid - the id of a specific team within the club
 * @param {object} updatedData - the updated data for a club
 * @returns {object} { data, error }
 * @function */
export const updateTeam = async (cid, tid, updatedData) => {
  let data = null;
  let error = null;

  const update = { ...updatedData, lastUpdated: currentTime };

  try {
    await CLUBS_REF.doc(cid).collection('teams').doc(tid).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Delete a team
 * @param {string} cid - the id of a club
 * @param {string} tid - the id of a team within a club
 * @function */
export const deleteTeam = async (cid, tid) => {
  let data = null;
  let error = null;

  try {
    await CLUBS_REF.doc(cid).collection('teams').doc(tid).delete();
    data = tid;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

// Functions for team members

/**
 * List the members within a team
 * @param {string} cid - the id of a club
 * @param {string} tid - the id of a team
 * @param {boolean} isAthlete - t/f if athlete or not
 * @returns {object} { data, first, last, error }
 * @function */
export const listClubTeamMembers = async (cid, tid, isAthlete) => {
  const query = CLUBS_REF.doc(cid)
    .collection('teams')
    .doc(tid)
    .collection('members')
    .where('isAthlete', '==', isAthlete);
  const { data, first, last, error } = await listQuery(query, 'uid', 'members');
  return { data, first, last, error };
};

/**
 * Add members of a specified type to a team (batched write)
 * @param {string} cid - the id of a club
 * @param {string} tid - the id of a team
 *  * @param {string} teamName - the name of a team
 * @param {boolean} isAthlete - t/f if athlete or not
 * @param {array} checkedUids - user ids checked in the add members form
 * @param {object} memberships - list of memberships to be filtered with checkedUids above
 * @returns {object} { data, first, last, error }
 * @function */
export const addClubTeamMembers = async (
  cid,
  tid,
  teamName,
  isAthlete,
  checkedUids,
  memberships
) => {
  let data = null;
  let error = null;

  // Get a new write batch
  const batch = firestore.batch();

  const memberDocs = {};

  checkedUids.forEach((uid) => {
    const membershipDoc = memberships[uid];
    const memberRef = CLUBS_REF.doc(cid)
      .collection('teams')
      .doc(tid)
      .collection('members')
      .doc(uid);

    const memberDoc = {
      active: true,
      isAthlete,
      displayName: membershipDoc.displayName,
      photoUrl: membershipDoc.photoUrl || '',
      uid,
      disciplines: membershipDoc.disciplines || [],
      teamName,
      title: membershipDoc.title,
    };

    memberDocs[uid] = memberDoc;

    batch.set(memberRef, memberDoc);
  });

  try {
    await batch.commit();
    data = memberDocs;
  } catch (err) {
    error = err;
  }

  return { data, error };
};
