import React, { useState, useEffect, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from '../ClubSchedules/ClubSchedules.module.scss';
import { UserContext } from '../../providers/UserProvider';
import { getClubByClubId, createClub } from '../../services/club/club';
import { toDateTimeLocalString } from '../../services/util/formatters';
import { Disciplines, Associations } from '../../services/util/enum';
import FileUpload from '../../components/FileUpload/FileUpload';

const ClubBuilder = (data) => {
  const { cid } = data;
  const [user] = useContext(UserContext);
  const [checkedDisciplines, setCheckedDisciplines] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  const [nameTaken, setNameTaken] = useState(null);

  const [pauseFunc, setPauseFunc] = useState(false);

  const [club, setClubData] = useState({
    clubId: '', // enforce unique (subdomain?) // Add to UI?
    clubIdIsAuto: true,
    clubName: '',
    owner: null,
    street: '',
    city: '',
    state: '',
    zip: '',
    logoUrl: '',
    introduction: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create temp activity to throw on page before next DB call
    const newClub = {
      clubId: club.clubId, // enforce unique (subdomain?) // Add to UI?
      clubName: club.clubName,
      owner: user,
      location: {
        street: club.street,
        city: club.city,
        state: club.state,
        zip: club.zip,
      }, // Add to UI?
      logoUrl: club.logoUrl,
      introduction: club.introduction,
      disciplines: checkedDisciplines,
    };

    console.log('new club', newClub);

    createClub(newClub).then((res) => {
      if (res.error) {
        console.error('An error occurred while creating club: ', res.error);
      } else {
        history.push(`/dashboard`);
      }
    });
  };

  const setClubPhotoUrl = (url) => {
    setClubData({
      ...club,
      logoUrl: url,
    });
  };

  const onChange = (e) => {
    // Check if the input changed was a checkbox
    if (e.target.type === 'checkbox') {
      // It it was, assign its value in the association object
      const newAssociations = Object.assign(club.associations, {
        [e.target.name]: e.target.checked,
      });
      setClubData((prevState) => ({
        ...prevState,
        associations: newAssociations,
      }));
    } else {
      // Auto-generate the clubId based off the club name
      if (e.target.name === 'clubName' && club.clubIdIsAuto) {
        setClubData((prevState) => ({
          ...prevState,
          clubId: e.target.value
            .replace(/ /g, '')
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase(),
        }));
      }

      if (e.target.name === 'clubId' && club.clubIdIsAuto) {
        // If modifying the clubId to be different than the automatic
        setClubData((prevState) => ({
          ...prevState,
          clubIdIsAuto: false,
        }));
      } else if (
        e.target.name === 'clubId' &&
        e.target.value === '' &&
        !club.clubIdIsAuto
      ) {
        // Reset if emptied out
        setClubData((prevState) => ({
          ...prevState,
          clubIdIsAuto: true,
        }));
      }

      // General catch-all
      setClubData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };
  return (
    <div className={styles.clubschedules}>
      <div className={styles.clubschedules_hero}>
        {/* Breadcrumbs */}
        <p>
          /{' '}
          <Link to={`${pathname.replace('/club-builder', '')}`}>
            {club.clubName} Home
          </Link>{' '}
          / <span>Club Builder</span>
        </p>
        <h1>Club Builder</h1>
      </div>
      <form className="container" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <label htmlFor="clubName" className={styles.activity_form_label}>
              Club Name
            </label>
            <input
              className={styles.activity_form_input}
              type="text"
              name="clubName"
              id="clubName"
              required
              onChange={onChange}
              value={club.clubName}
            />
          </div>
          <div className="col">
            <label
              htmlFor="clubId"
              className={`${styles.activity_form_label} d-flex align-items-center`}
            >
              Club ID&nbsp;&nbsp;
              {nameTaken === false && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="green"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              )}
              {nameTaken === true && (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="red"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                  </svg>
                  <span style={{ fontSize: 10, color: 'red' }}>
                    &nbsp;&nbsp;This name is taken
                  </span>
                </>
              )}
            </label>
            <input
              className={styles.activity_form_input}
              type="text"
              name="clubId"
              id="clubId"
              required
              onChange={onChange}
              value={club.clubId}
              onBlur={() => {
                getClubByClubId(club.clubId).then((res) => {
                  console.log(res);
                  if (res.data) {
                    setNameTaken(true);
                  } else {
                    setNameTaken(false);
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label
              htmlFor="introduction"
              className={styles.activity_form_label}
            >
              Introduction
            </label>
            <textarea
              className={styles.activity_form_input}
              type="text"
              name="introduction"
              id="introduction"
              onChange={onChange}
              value={club.introduction}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="city" className={styles.activity_form_label}>
              City
            </label>
            <input
              className={styles.activity_form_input}
              type="text"
              name="city"
              required
              id="city"
              value={club.city}
              onChange={onChange}
            />
          </div>
          <div className="col">
            <label htmlFor="state" className={styles.activity_form_label}>
              State
            </label>
            <select
              onChange={onChange}
              name="state"
              required
              value={club.state}
              className={styles.activity_form_input}
              id="state"
            >
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="street" className={styles.activity_form_label}>
              Street
            </label>
            <input
              className={styles.activity_form_input}
              type="text"
              name="street"
              required
              id="street"
              value={club.street}
              onChange={onChange}
            />
          </div>
          <div className="col-4">
            <label htmlFor="zip" className={styles.activity_form_label}>
              Zip
            </label>
            <input
              className={styles.activity_form_input}
              type="text"
              pattern="[0-9]{5}"
              name="zip"
              required
              id="zip"
              value={club.zip}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label
              htmlFor="clubDisciplines"
              className={styles.activity_form_label}
            >
              Disciplines:
            </label>
            <br />
            {Disciplines.values().map((disciplines) => (
              <Form.Check
                key={`clubDiscipline${disciplines}`}
                inline
                type="checkbox"
                className={styles.activity_form_input_checkbox}
                label={Disciplines[disciplines].name}
                checked={checkedDisciplines.includes(disciplines)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckedDisciplines([...checkedDisciplines, disciplines]);
                  } else {
                    setCheckedDisciplines(
                      checkedDisciplines.filter((id) => id !== disciplines)
                    );
                  }
                }}
              />
            ))}
          </div>
          <div className="col-5 d-flex align-items-center justify-content-end">
            <div className="w-100">
              <label htmlFor="logoUrl" className={styles.activity_form_label}>
                Logo URL
              </label>
              <input
                className={styles.activity_form_input}
                type="text"
                name="logoUrl"
                id="logoUrl"
                value={club.logoUrl}
                onChange={onChange}
              />
            </div>
            <div className="p-l-10 d-flex align-items-center">
              <FileUpload
                folder="clubLogos"
                setFileUrl={setClubPhotoUrl}
                setPauseFunc={setPauseFunc}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className={`${styles.activity_form_button} w-auto p-x-15`}
            disabled={pauseFunc ? 'disabled' : ''}
            type="submit"
          >
            CREATE CLUB
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClubBuilder;
