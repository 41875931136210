import React, { useState, useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { UserContext } from '../../providers/UserProvider';
import { getClub } from '../../services/club/club';
import { getRoles } from '../../services/club/membership';
import TitleCard from '../../components/TitleCard/TitleCard';
import ClubUpdateForm from '../../components/Club/UpdateClubForm';
import styles from './ClubHome.module.scss';

const ClubHome = ({ match }) => {
  const { cid } = match.params;
  const location = useLocation();
  const { pathname } = location;
  const [user] = useContext(UserContext);
  const [club, setClub] = useState(null);
  const [updateModalShow, setUpdateModalShow] = useState(false);
  // The roles for the current user
  const [roles, setRoles] = useState(null);

  // Get club informaton based on the url
  useEffect(() => {
    getClub(cid).then((result) => {
      if (result.data) {
        setClub(result.data);
      } else {
        // setError(result.err)
      }
    });
  }, []);

  // Get user's club roles
  useEffect(() => {
    getRoles(cid, user.uid).then((result) => {
      if (result.data) {
        setRoles(result.data);
      } else {
        setRoles([]);
        console.error(result.error);
      }
    });
  }, [user.uid]);

  // Conditionally render club actions based on a user's roles
  function clubActionSwitch() {
    switch (true) {
      case roles.some((ai) => ['owner'].includes(ai)):
        return (
          <>
            <section>
              <h2>Management</h2>
              <div className={styles.clubhome_card_wrapper}>
                {/* <TitleCard title="CLUB BUILDER" to="club-builder" />
                <TitleCard title="NEW COMMUNICATION" to="new-communication" /> */}
                <TitleCard title="ROSTER" icon="roster" to="club-roster" />
                <TitleCard
                  title="SCHEDULES"
                  icon="calendar"
                  to="club-schedules"
                />
                <TitleCard title="EDIT CLUB" icon="edit" to="club-editor" />
                {/* <TitleCard
                  title="SQUAD SHEET BUILDER"
                  to="squad-sheet-builder"
                /> */}
              </div>
            </section>
            {/* <section>
              <h2>Team Management</h2>
              <div className={styles.clubhome_card_wrapper}>
                <TitleCard title="TEAM BUILDER" to="team-builder" />
                <TitleCard title="TEAM ROSTER" to="team-roster" />
              </div>
            </section> */}
          </>
        );

      case roles.some((ai) => ['admin', 'athlete', 'coach'].includes(ai)):
        return (
          <section>
            <h2>Actions</h2>
            <div className={styles.clubhome_card_wrapper}>
              <TitleCard title="ROSTER" icon="roster" to="club-roster" />
              <TitleCard
                title="SCHEDULES"
                icon="calendar"
                to="club-schedules"
              />
            </div>
          </section>
        );

      default:
        return (
          <div className={styles.clubhome_nonmember}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              fill="currentColor"
              className="bi bi-lock-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
            </svg>
            <p>
              You are not a member of this club.
              {/* Submit a pre-registration form
              or request club admin access above. */}
            </p>
          </div>
        );
    }
  }

  return (
    <div className={styles.clubhome}>
      {/* Club Home hero */}
      <div className={styles.clubhome_hero}>
        <div className={styles.clubhome_hero_left}>
          {/* Club heading */}
          <div className={styles.clubhome_hero_heading}>
            {/* <div className={styles.clubhome_hero_logo_wrapper} /> */}
            <div className={styles.clubhome_hero_logo_heading_headings}>
              <p className={styles.clubhome_hero_logo_heading_headings_welcome}>
                Welcome to
              </p>
              {club && <h1>{club.clubName}</h1>}
              {club && <p>{club.bio}</p>}
            </div>
          </div>
          {/* Club member info or actions */}
          {roles && roles.length > 0 ? (
            <div className={styles.clubhome_hero_member}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="lightgreen"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <div>
                <p>You are a member of this club!</p>
                {roles.length > 0 && (
                  <p>
                    <strong>Roles:</strong> {roles.join(', ')}
                  </p>
                )}
              </div>
            </div>
          ) : (
            // (
            //   <div className={styles.clubhome_card_wrapper}>
            //     <Link to={`${pathname}/pre-registration`}>
            //       <button
            //         className={styles.clubhome_hero_member_request_button}
            //         type="button"
            //       >
            //         Athlete Pre-Registration
            //       </button>
            //     </Link>
            //     <Link to={`${pathname}/request-access`}>
            //       <button
            //         className={
            //           styles.clubhome_hero_member_request_button__secondary
            //         }
            //         type="button"
            //       >
            //         Request Admin Access
            //       </button>
            //     </Link>
            //   </div>
            // )
            <div />
          )}
        </div>

        {/* Club snapshot */}
        <div className={styles.clubhome_hero_snapshot}>
          <h2>Snapshot</h2>
          <table className={styles.clubhome_hero_snapshot_table}>
            <thead>
              <tr>
                <th>DISCIPLINE</th>
                <th># OF ATHLETES</th>
                <th>AVG.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Trap</td>
                <td> </td>
                <td> </td>
              </tr>
              <tr>
                <td>Skeet</td>
                <td> </td>
                <td> </td>
              </tr>
              <tr>
                <td>Sporting Clays</td>
                <td> </td>
                <td> </td>
              </tr>
              <tr>
                <td>5-Stand</td>
                <td> </td>
                <td> </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Update Club Modal */}
      {roles && roles.some((role) => ['admin', 'owner'].includes(role)) && (
        <Modal
          show={updateModalShow}
          onHide={() => setUpdateModalShow(false)}
          size="lg"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title as="h3" className={styles.invite_modal_title}>
              Create a New Club Activity
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setUpdateModalShow(false)}
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ClubUpdateForm cid={cid} setUpdateModalShow={setUpdateModalShow} />
          </Modal.Body>
        </Modal>
      )}

      {/* Club home management */}
      <div className={styles.clubhome_management}>
        {roles && clubActionSwitch(roles)}
      </div>
    </div>
  );
};

ClubHome.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ClubHome;
