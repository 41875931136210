import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

export default function UnauthenticatedRoute({
  component: C,
  appProps,
  ...rest
}) {
  // Determines the routes
  const [user] = useContext(UserContext);

  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect');

  // If not authenticated, load unauthenticated routes
  if (!user) {
    return (
      <Route {...rest} render={(props) => <C {...props} {...appProps} />} />
    );
  }
  // Otherwise, authenticated
  return (
    <Route
      {...rest}
      render={() => (
        <Redirect
          to={redirect === '' || redirect === null ? '/dashboard' : redirect}
        />
      )}
    />
  );
}

UnauthenticatedRoute.defaultProps = {
  appProps: null,
  rest: null,
  props: null,
  location: null,
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  appProps: PropTypes.any,
  rest: PropTypes.any,
  props: PropTypes.object,
  location: PropTypes.object,
};
