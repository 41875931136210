import { auth, firestore, currentTime } from '../../firebase';
import { readDateOrNull, removeEmpty } from '../util/formatters';
import { listQuery } from '../util/pagination';

/**
 * @file Functions for handling profiles
 */

const PROFILES_REF = firestore.collection('profiles');

/**
 * Get a user profile on sign in, and wait for user profile if it is being created
 * @param {function} setUser - hook to set the user state for user context
 * @param {function} setInitializing - hook to set the app initializing state
 * @function */
export const getCurrentUserProfile = (setUser, setInitializing) => {
  auth.onAuthStateChanged(async (userAuth) => {
    setInitializing(true);
    if (userAuth) {
      const { uid } = userAuth;

      // Build a reference to their per-user document in the profiles collection
      const profileDocRef = PROFILES_REF.doc(uid);

      // Listen to the profileDoc and set the user when it is created, then unsubscribe the listener
      const unsubscribe = profileDocRef.onSnapshot({
        next: (snapshot) => {
          const data = snapshot.data();
          const userProfile = { uid, ...data };

          if (data) {
            unsubscribe();
            setUser(userProfile);
            setInitializing(false);
          }
        },
        error: (error) => {
          unsubscribe();
          console.error(error);
        },
      });
    } else {
      setUser(null);
      setInitializing(false);
    }
  });
};

/**
 * Get a user profile by uid
 * @param {*} uid
 * @returns {object} { data, error }
 * @function */
export const getProfile = async (uid) => {
  let data = null;
  let error = null;
  try {
    const profile = await PROFILES_REF.doc(uid).get();
    if (profile.exists) {
      data = profile.data();
    } else {
      throw new Error(`A profile does not exist with uid: ${uid}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Update a user profile given a uid
 * @param {string} uid
 * @param {object} updatedData - the updated data to be appended to a user's profile
 * @returns {object} { data, error }
 * @function */
export const updateProfile = async (uid, updatedData) => {
  let data = null;
  let error = null;

  const update = { ...updatedData, lastUpdated: currentTime };

  try {
    await PROFILES_REF.doc(uid).update(update);
    data = update;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

// Update User Profile
/**
 * @param {string} uid user id
 * @param {string} displayName Display Name
 * @param {string} photoUrl Profile Photo URL
 * @param {string} grade School Grade
 * @param {string} gender Gender
 * @param {string} classification Classification
 * @param {Array} disciplines Disciplines
 * @param {Array} categories Categories
 */
export const updateUserProfile = async ({
  uid,
  displayName,
  photoUrl,
  grade,
  gender,
  birthday,
  classification,
  disciplines,
  categories,
  trapHandicap,
}) => {
  let data = null;
  let error = null;

  let userInfo = {
    uid,
    displayName: displayName,
    photoUrl: photoUrl,
    grade: grade,
    gender: gender,
    birthday: readDateOrNull(birthday),
    classification: classification,
    disciplines: disciplines,
    categories: categories,
    trapHandicap: trapHandicap,
  };
  console.log(userInfo);

  userInfo = removeEmpty(userInfo);
  console.log('clean', userInfo);

  try {
    await PROFILES_REF.doc(uid).set(userInfo, { merge: true });
    data = userInfo;
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * Get a user's profile by their email ("search" functionality)
 * @param {*} email
 * @returns {object} { data, error }
 * @function */
export const getProfileByEmail = async (email) => {
  let data = null;
  let error = null;
  try {
    const query = await PROFILES_REF.where('email', '==', email).get();
    if (!query.empty) {
      const snapshot = query.docs[0];
      data = snapshot.data();
    } else {
      throw new Error(`A profile does not exist with email: ${email}.`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

/**
 * List user profiles given a query
 * @param {*} query - the query to call and list profile results ()
 * @returns {object} { data, first, last, error }
 * @function */
export const listUserProfiles = async (query) => {
  const { data, first, last, error } = await listQuery(
    query,
    'email',
    'profiles'
  );
  return { data, first, last, error };
};
