import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styles from '../../pages/ClubSchedules/ClubSchedules.module.scss';
import { createActivities, createActivity } from '../../services/club/activity';
import { toDateTimeLocalString } from '../../services/util/formatters';

const JsonImportForm = (data) => {
  const { setJsonModalShow, cid, activities, setActivities } = data;

  const [json, setJson] = useState('');

  const handleTest = (e) => {
    // Temporarily throw an object on the page
    e.preventDefault();
    const parsedJson = JSON.parse(json);
    const newActivities = {};
    parsedJson.map((activity) =>
      Object.assign(newActivities, {
        [Math.floor(Math.random() * 9999999)]: {
          title: activity.title || '',
          startTime: new Date(activity.startTime),
          endTime: new Date(activity.endTime),
          location: {
            parkName: activity.location.parkName || '',
            city: activity.location.city || '',
            state: activity.location.state || '',
            street: activity.location.street || '',
            zip: activity.location.zip || '',
          },
          type: activity.type || '',
          mandatory: activity.mandatory || false,
          cost: activity.cost || '',
          associations: activity.associations || '',
        },
      })
    );

    if (activities) {
      setActivities({ ...activities, ...newActivities });
    } else {
      setActivities({ ...newActivities });
    }

    setJsonModalShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const parsedJson = JSON.parse(json);

    let newActivities = {};
    newActivities = parsedJson.map((activity) => ({
      title: activity.title || '',
      startTime: activity.startTime,
      endTime: activity.endTime,
      location: {
        parkName: activity.location.parkName || '',
        city: activity.location.city || '',
        state: activity.location.state || '',
        street: activity.location.street || '',
        zip: activity.location.zip || '',
      },
      type: activity.type || '',
      mandatory: activity.mandatory || false,
      cost: activity.cost || '',
      associations: activity.associations || '',
    }));

    createActivities(cid, newActivities).then((res) => {
      if (activities) {
        setActivities({ ...activities, ...newActivities });
      } else {
        setActivities({ ...newActivities });
      }
      setJsonModalShow(false);
    });
  };

  const onChange = (e) => {
    // Check if the input changed was a checkbox
    setJson(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div>
            <p>Example JSON object:</p>
            <code>
              {`[{`}
              <br />
              &nbsp;&nbsp;&quot;startTime&quot;: &quot;1/11/21&quot;,
              <br /> &nbsp;&nbsp;&quot;endTime&quot;: &quot;1/17/21&quot;,
              <br /> &nbsp;&nbsp;&quot;type&quot;: &quot;INTERNATIONAL
              SKEET&quot;,
              <br /> &nbsp;&nbsp;&quot;title&quot;: &quot;The 2021 Miami
              Cup&quot;,
              <br /> &nbsp;&nbsp;&quot;location&quot;: {`{`}
              &nbsp;&nbsp; <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&quot;parkName&quot;: &quot;Trail Glades
              Range&quot;,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&quot;zip&quot;: &quot;&quot;,
              &nbsp;&nbsp;
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;&quot;city&quot;:
              &quot;Miami&quot;, &nbsp;&nbsp;
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;&quot;state&quot;: &quot;FL&quot;
              <br />
              &nbsp;&nbsp;{`}`}
              <br />
              {`}]`}
            </code>
          </div>
          <textarea
            className={styles.activity_form_input}
            name="json"
            required
            id="json"
            cols="30"
            rows="10"
            onChange={onChange}
            value={json}
          />
          <button
            className={styles.activity_form_button}
            type="submit"
            onClick={handleSubmit}
          >
            CREATE ACTIVITIES
          </button>
          <button
            className={styles.activity_form_button}
            type="submit"
            onClick={handleTest}
          >
            TEST CREATE ACTIVITIES
          </button>
        </div>
      </div>
    </form>
  );
};

export default JsonImportForm;
