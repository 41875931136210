import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import styles from './NotFound.module.scss';

const NotFound = () => (
  <>
    <Navbar />
    <div className="max-w-screen-md my-12 mx-auto">
      <h1 className="text-3xl">Sorry, that page was not found.</h1>
      <div className="flex flex-col w-full px-4 items-center justify-center mt-4">
        <svg
          width="60%"
          viewBox="0 0 602 240"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M302.925 239.743C465.62 239.743 597.51 231.025 597.51 220.272C597.51 209.518 465.62 200.801 302.925 200.801C140.23 200.801 8.34003 209.518 8.34003 220.272C8.34003 231.025 140.23 239.743 302.925 239.743Z"
            fill="#F2F2F2"
          />
          <path
            d="M291.635 7.11755C243.639 7.11755 209.178 37.861 209.178 109.596C209.178 190.895 243.639 212.415 291.635 212.415C339.63 212.415 376.144 188.845 376.144 109.596C376.144 24.8804 339.63 7.11755 291.635 7.11755ZM291.95 185.429C258.707 185.429 241.339 165.958 241.339 109.648C241.339 59.963 259.418 33.7618 292.661 33.7618C325.904 33.7618 343.982 50.9724 343.982 109.648C343.982 164.538 325.193 185.429 291.95 185.429Z"
            fill="#2F2E41"
          />
          <path
            d="M180.438 155.711H160.594V97.3682C160.594 94.2694 159.361 91.2974 157.166 89.1063C154.971 86.9151 151.995 85.6841 148.891 85.6841H144.241C142.704 85.6841 141.182 85.9863 139.762 86.5735C138.343 87.1606 137.052 88.0213 135.966 89.1063C134.879 90.1912 134.017 91.4793 133.429 92.8969C132.841 94.3144 132.538 95.8338 132.538 97.3682V155.711H76.564C75.4125 155.711 74.2805 155.414 73.2779 154.848C72.2753 154.283 71.4361 153.468 70.8418 152.483C70.2474 151.499 69.918 150.377 69.8856 149.228C69.8532 148.079 70.1189 146.941 70.6568 145.924L129.899 33.9778C130.638 32.5821 131.085 31.0514 131.215 29.4782C131.345 27.905 131.154 26.3219 130.654 24.8244C130.154 23.3269 129.355 21.9461 128.306 20.7653C127.257 19.5845 125.979 18.6282 124.549 17.9542L121.052 16.3054C118.336 15.0251 115.232 14.836 112.38 15.7773C109.529 16.7187 107.149 18.7183 105.734 21.3624L33.4799 156.31C32.2495 158.608 31.6058 161.173 31.6058 163.779V163.779C31.6058 165.859 32.0162 167.92 32.8137 169.842C33.6112 171.764 34.7801 173.511 36.2537 174.982C37.7273 176.453 39.4766 177.62 41.402 178.416C43.3273 179.212 45.3908 179.622 47.4747 179.622H132.538V216.514C132.538 218.353 132.901 220.175 133.606 221.874C134.311 223.573 135.344 225.117 136.647 226.417C137.949 227.718 139.496 228.75 141.198 229.453C142.9 230.157 144.724 230.52 146.566 230.52H146.566C148.408 230.52 150.232 230.157 151.934 229.453C153.636 228.75 155.182 227.718 156.485 226.417C157.788 225.117 158.821 223.573 159.526 221.874C160.231 220.175 160.594 218.353 160.594 216.514V179.622H180.438C183.614 179.622 186.66 178.363 188.906 176.12C191.151 173.878 192.413 170.837 192.413 167.666V167.666C192.413 164.496 191.151 161.455 188.906 159.212C186.66 156.97 183.614 155.711 180.438 155.711V155.711Z"
            fill="#904A2D"
          />
          <path
            d="M547.9 155.711H528.055V97.3682C528.055 94.2693 526.822 91.2974 524.628 89.1063C522.433 86.9151 519.456 85.6841 516.353 85.6841H511.703C510.166 85.6841 508.644 85.9863 507.224 86.5735C505.804 87.1606 504.514 88.0213 503.427 89.1063C502.341 90.1912 501.479 91.4793 500.891 92.8969C500.302 94.3144 500 95.8338 500 97.3682V155.711H444.026C442.874 155.711 441.742 155.414 440.74 154.848C439.737 154.283 438.898 153.468 438.303 152.483C437.709 151.499 437.38 150.377 437.347 149.228C437.315 148.079 437.581 146.941 438.119 145.924L497.361 33.9778C498.099 32.5821 498.547 31.0514 498.677 29.4782C498.807 27.905 498.616 26.3219 498.116 24.8244C497.616 23.3269 496.817 21.9461 495.768 20.7653C494.718 19.5845 493.44 18.6282 492.011 17.9542L488.513 16.3054C485.798 15.0251 482.693 14.836 479.842 15.7773C476.991 16.7187 474.611 18.7183 473.195 21.3624L400.942 156.31C399.711 158.608 399.068 161.173 399.068 163.779V163.779C399.068 167.981 400.739 172.01 403.715 174.982C406.691 177.953 410.728 179.622 414.936 179.622H500V216.514C500 220.229 501.478 223.791 504.108 226.417C506.739 229.044 510.307 230.52 514.028 230.52V230.52C517.748 230.52 521.316 229.044 523.947 226.417C526.577 223.791 528.055 220.229 528.055 216.514V179.622H547.9C551.076 179.622 554.122 178.363 556.367 176.12C558.613 173.878 559.875 170.837 559.875 167.666V167.666C559.875 164.496 558.613 161.455 556.367 159.212C554.122 156.97 551.076 155.711 547.9 155.711Z"
            fill="#904A2D"
          />
          <path
            d="M185.912 148.879H166.068V90.5363C166.068 89.0019 165.765 87.4825 165.177 86.0649C164.589 84.6474 163.727 83.3593 162.64 82.2744C161.554 81.1894 160.263 80.3287 158.844 79.7416C157.424 79.1544 155.902 78.8521 154.365 78.8521H149.715C146.611 78.8521 143.635 80.0832 141.44 82.2744C139.245 84.4656 138.012 87.4374 138.012 90.5363V148.879H82.0383C80.8868 148.879 79.7548 148.582 78.7522 148.016C77.7496 147.451 76.9104 146.636 76.3161 145.651C75.7217 144.667 75.3924 143.545 75.36 142.396C75.3275 141.247 75.5932 140.109 76.1311 139.093L135.373 27.1459C136.112 25.7502 136.56 24.2195 136.689 22.6463C136.819 21.0731 136.628 19.49 136.128 17.9925C135.628 16.495 134.829 15.1142 133.78 13.9334C132.731 12.7526 131.453 11.7963 130.023 11.1223L126.526 9.47345C123.81 8.19318 120.706 8.00413 117.855 8.94544C115.003 9.88674 112.624 11.8863 111.208 14.5305L38.9542 149.478C37.7238 151.776 37.0801 154.341 37.0801 156.947V156.947C37.0801 161.149 38.752 165.179 41.728 168.15C44.704 171.121 48.7403 172.79 52.949 172.79H138.012V209.682C138.012 211.522 138.375 213.343 139.08 215.042C139.785 216.741 140.818 218.285 142.121 219.586C143.424 220.886 144.97 221.918 146.672 222.622C148.374 223.325 150.198 223.688 152.04 223.688H152.04C155.761 223.688 159.329 222.212 161.959 219.586C164.59 216.959 166.068 213.397 166.068 209.682V172.79H185.912C187.485 172.79 189.042 172.481 190.495 171.88C191.948 171.279 193.268 170.399 194.38 169.289C195.492 168.178 196.374 166.86 196.976 165.41C197.578 163.959 197.887 162.405 197.887 160.835V160.835C197.887 159.264 197.578 157.71 196.976 156.259C196.374 154.809 195.492 153.491 194.38 152.381C193.268 151.27 191.948 150.39 190.495 149.789C189.042 149.188 187.485 148.879 185.912 148.879V148.879Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M558.164 148.879H538.32V90.5363C538.32 87.4374 537.087 84.4656 534.892 82.2744C532.697 80.0832 529.721 78.8521 526.617 78.8521H521.967C520.43 78.8521 518.908 79.1544 517.488 79.7416C516.069 80.3287 514.778 81.1894 513.692 82.2744C512.605 83.3593 511.743 84.6474 511.155 86.0649C510.567 87.4825 510.264 89.0019 510.264 90.5363V148.879H454.29C453.138 148.879 452.007 148.582 451.004 148.016C450.001 147.451 449.162 146.636 448.568 145.651C447.973 144.667 447.644 143.545 447.612 142.396C447.579 141.247 447.845 140.109 448.383 139.093L507.625 27.1459C508.364 25.7502 508.811 24.2195 508.941 22.6463C509.071 21.0731 508.88 19.49 508.38 17.9925C507.88 16.495 507.081 15.1142 506.032 13.9334C504.983 12.7526 503.705 11.7963 502.275 11.1223L498.778 9.47345C496.062 8.19318 492.958 8.00413 490.106 8.94544C487.255 9.88674 484.875 11.8863 483.46 14.5305L411.206 149.478C409.975 151.776 409.332 154.341 409.332 156.947C409.332 161.149 411.004 165.179 413.98 168.15C416.956 171.121 420.992 172.79 425.201 172.79H510.264V209.682C510.264 213.397 511.742 216.959 514.373 219.586C517.003 222.212 520.571 223.688 524.292 223.688V223.688C528.012 223.688 531.58 222.212 534.211 219.586C536.842 216.959 538.32 213.397 538.32 209.682V172.79H558.164C559.737 172.79 561.294 172.481 562.747 171.88C564.199 171.279 565.52 170.399 566.632 169.289C567.744 168.178 568.626 166.86 569.227 165.41C569.829 163.959 570.139 162.405 570.139 160.835V160.835C570.139 157.664 568.877 154.623 566.632 152.381C564.386 150.138 561.34 148.879 558.164 148.879V148.879Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M302.583 0.968933C254.587 0.968933 220.127 31.7123 220.127 103.447C220.127 184.746 254.587 206.266 302.583 206.266C350.579 206.266 387.093 182.697 387.093 103.447C387.093 18.7318 350.579 0.968933 302.583 0.968933ZM302.899 179.281C269.656 179.281 252.288 159.809 252.288 103.499C252.288 53.8144 270.366 27.6132 303.61 27.6132C336.853 27.6132 354.931 44.8238 354.931 103.499C354.931 158.39 336.142 179.281 302.899 179.281V179.281Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M563.289 147.923C564.98 148.537 566.792 148.751 568.58 148.546C570.368 148.342 572.085 147.726 573.594 146.747C575.103 145.767 576.364 144.451 577.277 142.902C578.19 141.353 578.73 139.614 578.855 137.821C578.979 136.028 578.685 134.231 577.995 132.571C577.306 130.911 576.239 129.434 574.88 128.255C573.521 127.077 571.906 126.23 570.163 125.781C568.42 125.332 566.597 125.293 564.836 125.668L565.127 131.641L562.297 126.522C559.519 127.825 557.373 130.176 556.331 133.058C555.96 134.068 555.735 135.125 555.662 136.197C555.483 138.714 556.131 141.22 557.507 143.336C558.884 145.452 560.913 147.062 563.289 147.923V147.923Z"
            fill="#57B894"
          />
          <path
            d="M577.293 217.196C576.294 212.117 580.623 207.613 584.9 204.686C589.176 201.759 594.173 198.884 595.632 193.916C597.729 186.777 591.483 180.239 586.621 174.601C583.014 170.417 579.917 165.821 577.393 160.909C576.382 158.94 575.452 156.881 575.186 154.683C574.803 151.519 575.821 148.37 576.847 145.352C580.264 135.296 583.918 125.325 587.809 115.439"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M563.767 146.385C565.459 146.999 567.27 147.213 569.059 147.009C570.847 146.804 572.564 146.188 574.073 145.209C575.582 144.229 576.843 142.913 577.756 141.365C578.668 139.816 579.209 138.076 579.333 136.283C579.458 134.491 579.164 132.693 578.474 131.033C577.784 129.373 576.718 127.896 575.359 126.718C574 125.539 572.385 124.692 570.642 124.243C568.899 123.794 567.075 123.756 565.315 124.13L565.606 130.103L562.776 124.984C559.998 126.288 557.852 128.638 556.809 131.521C556.439 132.53 556.214 133.587 556.141 134.659C555.962 137.176 556.61 139.682 557.986 141.798C559.362 143.914 561.392 145.525 563.767 146.385V146.385Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M575.873 113.376C576.537 111.547 577.651 109.915 579.113 108.629C580.575 107.343 582.337 106.445 584.238 106.018L585.195 111.696L586.969 105.732C589.433 105.769 591.821 106.586 593.789 108.066C595.757 109.545 597.203 111.611 597.918 113.965C598.633 116.319 598.581 118.839 597.768 121.161C596.955 123.483 595.425 125.487 593.397 126.884C591.369 128.281 588.949 128.998 586.486 128.932C584.023 128.867 581.645 128.021 579.695 126.518C577.745 125.015 576.324 122.932 575.637 120.569C574.95 118.207 575.032 115.688 575.873 113.376H575.873Z"
            fill="#57B894"
          />
          <path
            d="M576.511 111.326C577.175 109.497 578.289 107.864 579.751 106.578C581.213 105.292 582.975 104.394 584.876 103.967L585.833 109.645L587.608 103.682C590.071 103.718 592.459 104.536 594.427 106.015C596.395 107.495 597.841 109.561 598.556 111.915C599.271 114.269 599.219 116.789 598.406 119.111C597.594 121.433 596.063 123.437 594.035 124.834C592.007 126.231 589.587 126.948 587.124 126.882C584.661 126.816 582.283 125.971 580.333 124.468C578.383 122.964 576.962 120.881 576.275 118.519C575.588 116.157 575.67 113.638 576.511 111.326H576.511Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M584.581 174.716C587.264 175.688 590.211 175.64 592.861 174.582C595.51 173.523 597.677 171.528 598.949 168.977C600.22 166.426 600.506 163.497 599.754 160.749C599.001 158 597.262 155.625 594.867 154.074L593.504 158.657L592.537 152.912C592.527 152.907 592.518 152.904 592.507 152.901C591.072 152.38 589.549 152.147 588.023 152.214C586.498 152.282 585.001 152.648 583.618 153.294C582.234 153.939 580.992 154.85 579.962 155.975C578.931 157.1 578.133 158.416 577.612 159.849C577.091 161.282 576.859 162.804 576.927 164.327C576.996 165.849 577.364 167.344 578.011 168.724C578.658 170.105 579.572 171.345 580.699 172.373C581.826 173.401 583.145 174.198 584.581 174.716H584.581Z"
            fill="#57B894"
          />
          <path
            d="M585.134 172.64C587.817 173.611 590.764 173.563 593.414 172.505C596.063 171.447 598.23 169.452 599.502 166.901C600.773 164.35 601.059 161.421 600.307 158.672C599.554 155.924 597.814 153.548 595.42 151.997L594.057 156.58L593.09 150.835C593.08 150.831 593.071 150.827 593.06 150.824C591.625 150.303 590.102 150.07 588.576 150.137C587.051 150.205 585.554 150.572 584.171 151.217C582.787 151.862 581.545 152.773 580.515 153.898C579.484 155.023 578.686 156.34 578.165 157.773C577.645 159.206 577.412 160.727 577.48 162.25C577.549 163.773 577.917 165.267 578.564 166.648C579.211 168.028 580.125 169.268 581.252 170.296C582.379 171.325 583.698 172.121 585.134 172.64H585.134Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M572.481 193.987C572.942 196.608 574.292 198.99 576.304 200.735C578.316 202.48 580.867 203.482 583.531 203.571C586.195 203.661 588.808 202.833 590.933 201.227C593.058 199.621 594.565 197.335 595.202 194.751C595.839 192.167 595.567 189.444 594.431 187.037C593.295 184.63 591.365 182.686 588.964 181.531C586.563 180.376 583.837 180.081 581.244 180.694C578.65 181.308 576.347 182.793 574.72 184.9L579.899 192.682L572.823 188.552C572.275 190.31 572.157 192.174 572.481 193.987V193.987Z"
            fill="#57B894"
          />
          <path
            d="M572.959 192.449C573.421 195.07 574.77 197.453 576.782 199.198C578.794 200.943 581.346 201.944 584.01 202.033C586.673 202.123 589.287 201.295 591.412 199.69C593.537 198.084 595.044 195.797 595.681 193.213C596.318 190.63 596.045 187.906 594.909 185.499C593.773 183.092 591.843 181.148 589.442 179.993C587.041 178.838 584.315 178.543 581.722 179.157C579.129 179.77 576.826 181.255 575.199 183.363L580.377 191.144L573.302 187.014C572.753 188.772 572.636 190.636 572.959 192.449V192.449Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M6.03028 176.191C7.14177 176.594 8.33192 176.734 9.50693 176.6C10.6819 176.466 11.8097 176.061 12.8013 175.418C13.7928 174.774 14.6212 173.91 15.221 172.892C15.8207 171.874 16.1756 170.732 16.2575 169.554C16.3394 168.376 16.1462 167.195 15.693 166.104C15.2399 165.014 14.5392 164.043 13.6462 163.269C12.7532 162.495 11.6924 161.938 10.5472 161.643C9.40203 161.348 8.2039 161.323 7.04723 161.569L7.23837 165.493L5.37879 162.13C3.55371 162.986 2.14376 164.531 1.45879 166.424C1.21552 167.088 1.06763 167.782 1.01954 168.487C0.90216 170.14 1.32782 171.787 2.23201 173.177C3.1362 174.567 4.46965 175.625 6.03028 176.191V176.191Z"
            fill="#57B894"
          />
          <path
            d="M15.2315 221.704C14.5751 218.367 17.4196 215.409 20.2292 213.485C23.0389 211.562 26.3219 209.673 27.2805 206.409C28.6583 201.719 24.5543 197.423 21.3601 193.719C18.9903 190.97 16.9552 187.95 15.2974 184.723C14.5766 183.455 14.0857 182.071 13.8472 180.632C13.5954 178.553 14.2644 176.485 14.9384 174.501C17.1837 167.895 19.5844 161.344 22.1404 154.848"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M6.34474 175.181C7.45623 175.584 8.64638 175.724 9.82138 175.59C10.9964 175.456 12.1241 175.051 13.1157 174.408C14.1073 173.764 14.9356 172.899 15.5354 171.882C16.1352 170.864 16.49 169.721 16.5719 168.543C16.6539 167.365 16.4606 166.185 16.0075 165.094C15.5543 164.003 14.8537 163.033 13.9607 162.258C13.0677 161.484 12.0068 160.928 10.8616 160.633C9.71649 160.338 8.51836 160.312 7.36169 160.559L7.55283 164.483L5.69324 161.12C3.86817 161.976 2.45822 163.52 1.77324 165.414C1.52998 166.077 1.38209 166.772 1.33399 167.476C1.21661 169.13 1.64226 170.776 2.54646 172.167C3.45065 173.557 4.7841 174.615 6.34474 175.181V175.181Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M14.2982 153.493C14.7344 152.291 15.4665 151.218 16.427 150.374C17.3875 149.529 18.5456 148.939 19.7945 148.658L20.4233 152.389L21.589 148.471C23.2075 148.495 24.7765 149.031 26.0695 150.004C27.3624 150.976 28.3125 152.333 28.7824 153.88C29.2523 155.426 29.2178 157.082 28.6839 158.608C28.15 160.133 27.1442 161.45 25.8119 162.368C24.4796 163.285 22.8896 163.757 21.2715 163.713C19.6533 163.67 18.0909 163.115 16.8096 162.127C15.5284 161.14 14.5946 159.771 14.1432 158.219C13.6918 156.667 13.7461 155.012 14.2982 153.493V153.493Z"
            fill="#57B894"
          />
          <path
            d="M14.7175 152.146C15.1537 150.944 15.8858 149.871 16.8463 149.027C17.8069 148.182 18.9649 147.592 20.2139 147.311L20.8426 151.042L22.0083 147.124C23.6268 147.148 25.1958 147.684 26.4888 148.657C27.7818 149.629 28.7318 150.986 29.2017 152.533C29.6716 154.079 29.6372 155.735 29.1032 157.261C28.5693 158.786 27.5636 160.103 26.2312 161.021C24.8989 161.938 23.3089 162.41 21.6908 162.366C20.0727 162.323 18.5102 161.768 17.2289 160.78C15.9477 159.792 15.0139 158.424 14.5625 156.872C14.1111 155.32 14.1654 153.665 14.7175 152.146V152.146Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M20.0196 193.795C21.7823 194.433 23.7187 194.401 25.4596 193.706C27.2006 193.011 28.6243 191.7 29.4595 190.024C30.2948 188.348 30.483 186.423 29.9885 184.618C29.4939 182.812 28.3511 181.251 26.7778 180.232L25.8821 183.243L25.247 179.469C25.2407 179.466 25.2342 179.463 25.2275 179.461C24.2847 179.119 23.2835 178.965 22.2812 179.01C21.2789 179.054 20.2952 179.295 19.3862 179.719C18.4772 180.143 17.6608 180.741 16.9837 181.48C16.3066 182.219 15.7819 183.084 15.4398 184.026C15.0977 184.968 14.9448 185.967 14.9898 186.968C15.0348 187.969 15.2769 188.951 15.7022 189.858C16.1275 190.765 16.7278 191.58 17.4686 192.255C18.2094 192.931 19.0762 193.454 20.0196 193.795V193.795Z"
            fill="#57B894"
          />
          <path
            d="M20.3829 192.43C22.1457 193.068 24.082 193.037 25.823 192.342C27.5639 191.646 28.9877 190.335 29.8229 188.659C30.6581 186.983 30.8464 185.059 30.3518 183.253C29.8572 181.447 28.7144 179.886 27.1412 178.868L26.2455 181.879L25.6103 178.104C25.604 178.101 25.5975 178.099 25.5909 178.097C24.6482 177.755 23.6474 177.603 22.6457 177.647C21.6439 177.692 20.6608 177.933 19.7524 178.358C17.9179 179.214 16.4993 180.763 15.8087 182.664C15.1181 184.564 15.212 186.661 16.0699 188.493C16.9277 190.324 18.4792 191.741 20.3829 192.43V192.43Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M12.0697 206.456C12.3728 208.177 13.2596 209.743 14.5815 210.889C15.9034 212.036 17.5799 212.694 19.33 212.752C21.08 212.811 22.797 212.268 24.1932 211.212C25.5893 210.157 26.5796 208.655 26.998 206.958C27.4164 205.26 27.2375 203.471 26.4912 201.889C25.7449 200.308 24.4767 199.03 22.8992 198.272C21.3217 197.513 19.5309 197.319 17.827 197.722C16.1232 198.125 14.61 199.101 13.5412 200.486L16.9435 205.598L12.2947 202.885C11.9343 204.04 11.8571 205.265 12.0697 206.456V206.456Z"
            fill="#57B894"
          />
          <path
            d="M12.3842 205.445C12.6873 207.167 13.5741 208.733 14.896 209.879C16.2179 211.026 17.8944 211.683 19.6444 211.742C21.3944 211.801 23.1115 211.257 24.5076 210.202C25.9038 209.147 26.8941 207.645 27.3125 205.947C27.7309 204.25 27.552 202.46 26.8057 200.879C26.0594 199.297 24.7912 198.02 23.2137 197.261C21.6362 196.503 19.8453 196.309 18.1415 196.712C16.4377 197.115 14.9245 198.09 13.8557 199.475L17.258 204.588L12.6092 201.874C12.2488 203.029 12.1716 204.254 12.3842 205.445V205.445Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M269.587 104.192C274.83 104.192 279.081 98.6998 279.081 91.9253C279.081 85.1509 274.83 79.6591 269.587 79.6591C264.344 79.6591 260.093 85.1509 260.093 91.9253C260.093 98.6998 264.344 104.192 269.587 104.192Z"
            fill="#2F2E41"
          />
          <path
            d="M321.244 104.47C326.487 104.47 330.738 98.9786 330.738 92.2041C330.738 85.4297 326.487 79.9379 321.244 79.9379C316.001 79.9379 311.75 85.4297 311.75 92.2041C311.75 98.9786 316.001 104.47 321.244 104.47Z"
            fill="#2F2E41"
          />
          <path
            d="M271.821 101.404C277.064 101.404 281.314 95.912 281.314 89.1376C281.314 82.3631 277.064 76.8713 271.821 76.8713C266.577 76.8713 262.327 82.3631 262.327 89.1376C262.327 95.912 266.577 101.404 271.821 101.404Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M323.478 101.683C328.721 101.683 332.972 96.1908 332.972 89.4164C332.972 82.6419 328.721 77.1501 323.478 77.1501C318.235 77.1501 313.984 82.6419 313.984 89.4164C313.984 96.1908 318.235 101.683 323.478 101.683Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M271.821 88.58C273.363 88.58 274.613 87.3318 274.613 85.7922C274.613 84.2525 273.363 83.0044 271.821 83.0044C270.279 83.0044 269.028 84.2525 269.028 85.7922C269.028 87.3318 270.279 88.58 271.821 88.58Z"
            fill="white"
          />
          <path
            d="M323.757 88.58C325.299 88.58 326.549 87.3318 326.549 85.7922C326.549 84.2525 325.299 83.0044 323.757 83.0044C322.215 83.0044 320.965 84.2525 320.965 85.7922C320.965 87.3318 322.215 88.58 323.757 88.58Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M326.549 114.785C328.091 114.785 329.342 113.537 329.342 111.997C329.342 110.458 328.091 109.21 326.549 109.21C325.007 109.21 323.757 110.458 323.757 111.997C323.757 113.537 325.007 114.785 326.549 114.785Z"
            fill="#904A2D"
          />
          <path
            opacity="0.3"
            d="M258.418 114.785C259.96 114.785 261.21 113.537 261.21 111.997C261.21 110.458 259.96 109.21 258.418 109.21C256.876 109.21 255.626 110.458 255.626 111.997C255.626 113.537 256.876 114.785 258.418 114.785Z"
            fill="#904A2D"
          />
          <path
            d="M270.183 136.404C291.72 120.263 309.05 135.743 315.022 145.501"
            stroke="#2F2E41"
            strokeWidth="6"
          />
        </svg>
      </div>
    </div>
  </>
);

export default NotFound;
