import axios from 'axios';

/**
 *
 * @param {string} email
 * @param {string} clubName
 * @param {string} displayName
 * @returns
 */
export const sendEmail = (email, subject, html, mailOptions) => {
  let options = {};
  if (!mailOptions) {
    options = {
      from: 'The ShotDeck Team <support@shotdeck.app>', // Something like: Jane Doe <janedoe@gmail.com>
      to: email,
      subject: subject,
      html: html,
    };
  } else {
    options = mailOptions;
  }

  axios.post(
    'https://us-central1-shotdeckapp.cloudfunctions.net/sendEmail',
    options
  );
};
/**
 *
 * @param {string} email
 * @param {string} clubName
 * @param {string} displayName
 * @returns
 */
export const sendInviteEmail = (email, clubName, displayName) => {
  const mailOptions = {
    from: 'The ShotDeck Team <support@shotdeck.app>',
    to: email,
    subject: `ShotDeck — You've been invited to the ${clubName}`,
    html: `<h1>You've been invited to the ${clubName}</h1>
            <p>Hello ${displayName}! You've been invited to join the ${clubName}. Please login or signup <a href="https://shotdeck.app/sign-up?email=${email}">here</a> to respond to this invite!</p>
            <p>- The ShotDeck Team</p>
          `,
  };
  sendEmail(null, null, null, mailOptions);
};
