import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase';
import { ToastContainer } from 'react-toastify';
import Routes from './routing/Routes';
import UserProvider from './providers/UserProvider';
import Loading from './components/Loading/loading';
import CacheBuster from './components/CacheBuster';

import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  const [initializing, setInitializing] = useState(true);
  // useEffect(() => {
  //   if (!('Notification' in window)) {
  //     alert('This browser does not support desktop notification');
  //   }

  //   // Let's check whether notification permissions have already been granted
  //   else if (Notification.permission === 'granted') {
  //     // If it's okay let's create a notification
  //     const notification = new Notification('Hi there!');
  //     const messaging = firebase.messaging();

  //     // Get registration token. Initially this makes a network call, once retrieved
  //     // subsequent calls to getToken will return from cache.
  //     messaging
  //       .getToken({
  //         vapidKey:
  //           'BF6bAQTYSG5wEG56Sg42lzQbqwyFhj0KIo2KCeuCHNPv3OYKsUZF0mCBvWqQIEqz1MNXnwWLEvVWG_OKknt0unE',
  //       })
  //       .then((currentToken) => {
  //         if (currentToken) {
  //           // Send the token to your server and update the UI if necessary
  //           // ...
  //         } else {
  //           // Show permission request UI
  //           console.log(
  //             'No registration token available. Request permission to generate one.'
  //           );
  //           // ...
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('An error occurred while retrieving token. ', err);
  //         // ...
  //       });
  //   }

  //   // Otherwise, we need to ask the user for permission
  //   else if (Notification.permission !== 'denied') {
  //     Notification.requestPermission().then((permission) => {
  //       // If the user accepts, let's create a notification
  //       if (permission === 'granted') {
  //         const notification = new Notification('Hi there!');
  //       }
  //     });
  //   }
  // }, []);
  console.log('VERSION', process.env.REACT_APP_VERSION);
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <UserProvider setInitializing={setInitializing}>
            {initializing ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Loading />
              </div>
            ) : (
              <Router>
                <Routes />
                <script src="/node_modules/bootstrap/dist/js/bootstrap.min.js" />
              </Router>
            )}
            <ToastContainer />
          </UserProvider>
        );
      }}
    </CacheBuster>
  );
};

export default App;
