/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'react-bootstrap';
import styles from './RosterItem.module.scss';

function RosterItem({
  children,
  title,
  desc,
  isButton,
  action,
  ctaText,
  ctaAction,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${
        styles.rosteritem
      } d-flex justify-content-between flex-column flex-sm-row ${
        isButton && styles.rosteritem_button
      }`}
      onClick={() => action && action()}
    >
      <div className="d-flex align-items-center mb-1 w-100">
        <div className={styles.rosteritem_round}>
          {children || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="white"
              className="bi bi-person-fill"
              viewBox="0 0 16 16"
            >
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
          )}
        </div>
        <div className={styles.rosteritem_text_wrapper}>
          <p className={styles.rosteritem_title}>{title}</p>
          {desc && <p className={styles.rosteritem_desc}>{desc}</p>}
        </div>
      </div>
      {ctaText && ctaAction && (
        <Button onClick={() => ctaAction()}>
          <span style={{ whiteSpace: 'nowrap' }}>{ctaText}</span>
        </Button>
      )}
    </div>
  );
}

RosterItem.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  isButton: PropTypes.bool,
  action: PropTypes.func,
  ctaText: PropTypes.string,
  ctaAction: PropTypes.func,
};

RosterItem.defaultProps = {
  children: null,
  desc: null,
  isButton: false,
  action: null,
  ctaText: null,
  ctaAction: null,
};

export default RosterItem;
