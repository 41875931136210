import { firestore, currentTime } from '../../firebase';
import { listQuery } from '../util/pagination';

/**
 * @file Functions for handling a profile's notifications
 */

const PROFILES_REF = firestore.collection('profiles');

/**
 * Get a user profile's notifications by uid
 * @param {number} uid
 * @param {number} limit the amount of items to return, default 5
 * @returns {object} { data, error }
 */
export const listNotifications = async (uid, limit = 5) => {
  const query = PROFILES_REF.doc(uid)
    .collection('notifications')
    .orderBy('time', 'desc')
    .limit(limit);
  const { data, error } = await listQuery(query, 'id', 'notifications');
  return { data, error };
};

export const createNotification = async (uid, contact) => {
  let data = null;
  let error = null;

  const contactDoc = {
    ...contact,
    createdAt: currentTime,
    lastUpdated: currentTime,
  };

  try {
    const docRef = await PROFILES_REF.doc(uid)
      .collection('contacts')
      .add(contactDoc);

    const newContact = await docRef.get();

    if (newContact.exists) {
      data = { [newContact.id]: newContact.data() };
    } else {
      throw new Error(`An error occurred for contact: ${docRef.id}`);
    }
  } catch (err) {
    error = err;
  }

  return { data, error };
};

// export const updateContact = async (uid, contactId, updatedData) => {
//   let data = null;
//   let error = null;

//   const update = { ...updatedData, lastUpdated: currentTime };

//   try {
//     await PROFILES_REF.doc(uid)
//       .collection('contacts')
//       .doc(contactId)
//       .update(update);
//     data = update;
//   } catch (err) {
//     error = err;
//   }

//   return { data, error };
// };

// export const deleteContact = async (uid, contactId) => {
//   let data = null;
//   let error = null;

//   try {
//     await PROFILES_REF.doc(uid).collection('contacts').doc(contactId).delete();
//     data = contactId;
//   } catch (err) {
//     error = err;
//   }

//   return { data, error };
// };
