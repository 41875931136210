import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../../providers/UserProvider';
import { auth, firestore } from '../../firebase';
import { Disciplines } from '../../services/util/enum';
import {
  formatCamelToTitle,
  readDate,
  readDateForDateInput,
} from '../../services/util/formatters';
import {
  getPaginatedQuery,
  getNextPaginatedQuery,
  getPrevPaginatedQuery,
} from '../../services/util/pagination';
import {
  listUserProfiles,
  updateUserProfile,
  getProfileByEmail,
} from '../../services/profile';
import {
  listContacts,
  deleteContact,
  createContact,
} from '../../services/profile/contact';
import styles from './Profile.module.scss';

import FileUpload from '../../components/FileUpload/FileUpload';

import awardIcon from '../../images/award-icon.png';
import userImage from '../../images/user-icon.png';

const REF = firestore.collection('profiles');
const FIELD = 'email';
const PAGESIZE = 2;

const Profile = () => {
  const [user, setUser] = useContext(UserContext);
  const {
    uid,
    photoUrl,
    displayName,
    gender,
    grade,
    classification,
    disciplines,
    categories,
    trapHandicap,
    awards,
    platformRole,
  } = user;

  const history = useHistory();

  const location = useLocation();
  const { pathname, search } = location;

  const [queryResult, setQueryResult] = useState(null);

  useEffect(() => {
    if (['admin', 'superadmin'].includes(platformRole)) {
      const query = getPaginatedQuery(REF, FIELD, PAGESIZE);
      listUserProfiles(query).then((result) => {
        if (result.data) {
          setQueryResult(result);
        } else {
          // setError(result.err)
        }
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // EDIT PROFILE----------------
  const [currentItem, setCurrentItem] = useState(user);

  const [pauseFunc, setPauseFunc] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleLgShow = () => setLgShow(true);

  const getAge = () => {
    const today = new Date();
    if (!currentItem.birthday) return 'Not Set';
    const birthday = readDate(currentItem.birthday);
    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age -= 1;
    }
    return age;
  };

  const getGender = (profileGender) => {
    let genderLetter;
    if (profileGender === 'Male') {
      genderLetter = 'M';
    } else if (profileGender === 'Female') {
      genderLetter = 'F';
    } else {
      genderLetter = 'Not Set';
    }
    return genderLetter;
  };

  const getSelectedValue = (fieldValue) => {
    let selectedValue;
    if (fieldValue === '' || fieldValue === null) {
      selectedValue = '';
    } else {
      selectedValue = fieldValue;
    }
    return selectedValue;
  };

  const getSelections = (fieldName, fieldSelection) => {
    let checkedValue;
    if (fieldName.includes(fieldSelection)) {
      checkedValue = true;
    } else {
      checkedValue = false;
    }
    return checkedValue;
  };

  // const awardsSort = awards.sort((a, b) => b.year - a.year);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    console.log({ [name]: value });
    setCurrentItem({
      ...currentItem,
      [name]: value,
    });
    console.log('currentItem', currentItem);
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    console.log({ currentItem });
    updateUserProfile(currentItem).then((res) => {
      if (res.error) {
        console.error(
          'PROFILE: An error occurred while updating profile: ',
          res.error
        );
      } else {
        setUser(Object.assign(user, currentItem));
      }
      handleClose();
    });
  };

  const setProfilePhotoUrl = (url) => {
    setCurrentItem({
      ...currentItem,
      photoUrl: url,
    });
  };

  // CONTACTS----------------
  const [contacts, setContacts] = useState('');
  const [createContactForm, setCreateContactForm] = useState({
    label: '',
    name: '',
    email: '',
    phone: '',
    address: { street: '', city: '', state: '', zip: '' },
  });
  function handleChange(e) {
    const field = e.target.name;
    const { value } = e.target;

    const compoundField = field.split('.');
    if (compoundField.length > 1) {
      const field1 = compoundField[0];
      const field2 = compoundField[1];
      setCreateContactForm({
        ...createContactForm,
        [field1]: { ...createContactForm.address, [field2]: value },
      });
    } else {
      setCreateContactForm({ ...createContactForm, [field]: value });
    }
  }

  useEffect(() => {
    console.log('Create', JSON.stringify(createContactForm, null, 2));
  }, [createContactForm]);

  const [deleteUid, setDeleteUid] = useState('');

  // Get contacts
  useEffect(() => {
    listContacts(uid).then((result) => {
      if (result.data) {
        setContacts(result.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // -------------------------

  const tabs = [
    {
      id: 0,
      name: 'Profile',
    },
    {
      id: 1,
      name: 'Contacts',
    },
    {
      id: 2,
      name: 'My Tasks',
    },
  ];

  const formNoBorder = {
    backgroundColor: 'transparent',
    border: 'none',
  };

  const formGroupStyle = {
    marginBottom: '20px',
  };

  const formInput = {
    border: '1px solid #414141',
  };

  const formSave = {
    backgroundColor: '#ff4f00',
    border: '1px solid #ff4f00',
  };

  const formClose = {
    backgroundColor: '#414141',
    border: '1px solid #414141',
  };

  return (
    <div>
      <div className={styles.profile}>
        {/* Profile hero */}
        <div className={styles.profile_hero}>
          <div className={styles.profile_hero_image_wrapper}>
            <div
              style={{
                width: 170,
                height: 170,
                overflow: 'hidden',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={photoUrl.replace(/\.(....?)\?alt/, '_340x340.$1?alt')}
                style={{
                  minWidth: 170,
                  minHeight: 170,
                  width: 'auto',
                  height: 'auto',
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = userImage;
                }}
                alt="Profile"
              />
            </div>
          </div>
          <div className={styles.profile_hero_info_wrapper}>
            <h2 className={styles.profile_hero_name}>{displayName}</h2>
            <div className="row">
              <div className="col-6">
                <h6>Grade</h6>
                <p>{grade || 'Not Set'}</p>
              </div>
              <div className="col-6">
                <h6>Age</h6>
                <p>{getAge()}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h6>Gender</h6>
                <p>{getGender(gender)}</p>
              </div>
            </div>
          </div>
          <div>
            <Button
              type="button"
              className={styles.orange_btn}
              onClick={handleLgShow}
            >
              Edit Profile
            </Button>
            <Button
              type="button"
              className={styles.gray_btn}
              onClick={() => {
                auth.signOut();
                history.push('/');
                setUser(null);
              }}
            >
              Logout
            </Button>
          </div>
        </div>

        {/* EDIT PROFILE MODAL */}
        <Modal size="lg" show={lgShow} onHide={handleClose}>
          <Modal.Header style={formNoBorder}>
            <Modal.Title>Edit Profile</Modal.Title>
            <Button variant="outline-danger" onClick={handleClose}>
              Close
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="displayName" style={formGroupStyle}>
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  value={currentItem.displayName}
                  name="displayName"
                  onChange={handleProfileChange}
                  style={formInput}
                />
              </Form.Group>
              <Form.Group controlId="gradeSelect" style={formGroupStyle}>
                <Form.Label>Grade</Form.Label>
                <Form.Control
                  as="select"
                  name="grade"
                  onChange={handleProfileChange}
                  style={formInput}
                  value={getSelectedValue(currentItem.grade)}
                >
                  <option value="">Select</option>
                  <option value="3rd">3rd</option>
                  <option value="4th">4th</option>
                  <option value="5th">5th</option>
                  <option value="6th">6th</option>
                  <option value="7th">7th</option>
                  <option value="8th">8th</option>
                  <option value="9th">9th</option>
                  <option value="10th">10th</option>
                  <option value="11th">11th</option>
                  <option value="12th">12th</option>
                  <option value="College">College</option>
                  <option value="N/A">N/A</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="birthdatePicker" style={formGroupStyle}>
                <Form.Label>Birthdate</Form.Label>
                <br />
                <input
                  type="date"
                  name="birthday"
                  value={readDateForDateInput(currentItem.birthday)}
                  onChange={handleProfileChange}
                  style={formInput}
                  className="form-control"
                />
              </Form.Group>
              <Form.Group controlId="genderSelect" style={formGroupStyle}>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  onChange={handleProfileChange}
                  style={formInput}
                  value={getSelectedValue(currentItem.gender)}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="photoUrl" style={formGroupStyle}>
                <Form.Label>Profile Photo URL</Form.Label>
                <div className="row d-flex">
                  <div className="col">
                    <Form.Control
                      value={currentItem.photoUrl}
                      name="photoUrl"
                      onChange={handleProfileChange}
                      style={formInput}
                    />
                  </div>
                  <div className="col-auto">
                    <FileUpload
                      name="photoUrl"
                      folder="profilePhotos"
                      setFileUrl={setProfilePhotoUrl}
                      setPauseFunc={setPauseFunc}
                    />
                  </div>
                </div>
              </Form.Group>
              {/* TODO - Add to edit screen with proper modeling */}
              {/* <Form.Group controlId="classificationSelect">
                      <Form.Label>Classification</Form.Label>
                      <Form.Control
                        as="select"
                        value={currentItem.classification}
                        name="classification"
                        onChange={handleProfileChange}
                      >
                        <option>A</option>
                        <option>B</option>
                        <option>C</option>
                        <option>D</option>
                      </Form.Control>
                    </Form.Group>
                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={3}>
                          Disciplines
                        </Form.Label>
                        <Col sm={9}>
                          {Disciplines.values().map((discipline) => (
                            <Form.Check
                              key={`discipline-${discipline}`}
                              type="checkbox"
                              label={Disciplines[discipline].name}
                              name={discipline}
                              id={discipline}
                              value={Disciplines[discipline].name}
                              checked={getSelections(disciplines, 'Trap')}
                            />
                          ))}
                        </Col>
                      </Form.Group>
                    </fieldset>
                    <fieldset>
                      <Form.Group as={Row}>
                        <Form.Label as="legend" column sm={3}>
                          Categories
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Check
                            type="checkbox"
                            label="Adult"
                            name="categories"
                            id="category1"
                            checked={getSelections(categories, 'Adult')}
                          />
                          <Form.Check
                            type="checkbox"
                            label="College"
                            name="categories"
                            id="category2"
                            checked={getSelections(categories, 'College')}
                          />
                          <Form.Check
                            type="checkbox"
                            label="High School"
                            name="categories"
                            id="category3"
                            checked={getSelections(categories, 'High School')}
                          />
                          <Form.Check
                            type="checkbox"
                            label="12 Gauge"
                            name="categories"
                            id="category4"
                            checked={getSelections(categories, '12 Gauge')}
                          />
                          <Form.Check
                            type="checkbox"
                            label="20 Gauge"
                            name="categories"
                            id="category5"
                            checked={getSelections(categories, '20 Gauge')}
                          />
                        </Col>
                      </Form.Group>
                    </fieldset>
                    <Form.Group controlId="trapHandicapSelect">
                      <Form.Label>Trap Handicap</Form.Label>
                      <Form.Control
                        as="select"
                        value={currentItem.trapHandicap}
                        name="trapHandicap"
                        onChange={handleProfileChange}
                      >
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                      </Form.Control>
                    </Form.Group> */}
            </Form>
          </Modal.Body>
          <Modal.Footer style={formNoBorder}>
            <Button variant="secondary" onClick={handleClose} style={formClose}>
              Close
            </Button>
            <Button
              className={styles.orange_btn}
              onClick={handleProfileSubmit}
              style={formSave}
              disabled={pauseFunc ? 'disabled' : ''}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Profile info - TODO - add back tabs */}
        {/* <div className={styles.dashboard_profile}>
          <Tabs
            defaultIndex={0}
            selectedTabClassName={styles.profile_body_tab__selected}
          >
            <TabList className={styles.profile_body_tablist}>
              {tabs.map((tab) => (
                <Tab key={tab.id} className={styles.profile_body_tab}>
                  <Link
                    to={`${pathname}?tab=${tab.id}`}
                    key={tab.id}
                    className={styles.profile_body_tab_link}
                  >
                    {tab.name}
                  </Link>
                </Tab>
              ))}
            </TabList> */}

        {/* User Info */}
        {/* <TabPanel>
              <div className="row">
                <div className={`col-lg-7 ${styles.mb_25}`}>
                  <div className={styles.profile_card_wrapper}>
                    <h3 className={styles.profile_card_header}>Member IDs</h3>
                    <table>
                      <tbody>
                        <tr>
                          <td>Organization Name</td>
                          <td>AD1967-34507</td>
                        </tr>
                        <tr>
                          <td>Organization Name</td>
                          <td>AD1967-34507</td>
                        </tr>
                        <tr>
                          <td>Organization Name</td>
                          <td>AD1967-34507</td>
                        </tr>
                        <tr>
                          <td>Organization Name</td>
                          <td>AD1967-34507</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={`col-lg-5 ${styles.mb_25}`}>
                  <div className={styles.profile_card_wrapper}>
                    <h3 className={styles.profile_card_header}>Stats & Info</h3>
                    <h5 className={styles.profile_card_subheader}>
                      Disciplines
                    </h5>
                    <ul>
                      {disciplines.map((result) => (
                        <li key={result}>{result}</li>
                      ))}
                    </ul>
                    <h5 className={styles.profile_card_subheader}>
                      Categories
                    </h5>
                    <ul>
                      <li>{gender}</li>
                      {categories.map((result) => (
                        <li key={result}>{result}</li>
                      ))}
                    </ul>
                    <h5 className={styles.profile_card_subheader}>
                      Trap Handicap
                    </h5>
                    <p>{trapHandicap}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={styles.profile_card_wrapper}>
                    <h3 className={styles.profile_card_header}>
                      Awards & Recognition
                    </h3>
                    <div className="row">
                      {Object.keys(awardsSort).map((property) => {
                        let value = awardsSort[property];
                        return (
                          <div
                            key={property}
                            className={`col-xl-3 col-lg-6 ${styles.mb_25}`}
                          >
                            <div className={styles.profile_awards_wrapper}>
                              <img
                                className={styles.profile_awards_image}
                                src={awardIcon}
                                alt="Award"
                              />
                              <h5 className={styles.profile_awards_title}>
                                {value.name}
                              </h5>
                              <h5 className={styles.profile_awards_year}>
                                {value.year}
                              </h5>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div> */}
        {/* </TabPanel> */}
        {/* Contacts */}
        {/* JOSH'S ADDED CODE */}
        {/* <TabPanel>
              <div className="row">
                <div className={`col-lg-6 ${styles.mb_25}`}>
                  <div className={styles.profile_card_wrapper}>
                    <h3 className={styles.profile_card_header}>
                      Emergency Contact
                    </h3>
                  </div>
                </div>
                <div className={`col-lg-6 ${styles.mb_25}`}>
                  <div className={styles.profile_card_wrapper}>
                    <h3 className={styles.profile_card_header}>
                      Parent/Guardian Contact
                    </h3>
                  </div>
                </div>
              </div> */}

        {/* MATT'S ORIGINAL CONTACT CODE - SAVE FOR LATER -Lines 587-784 */}
        {/* <div className="row">
                <div className="col-sm-6">
                  <h3>Current:</h3>
                  <div>
                    <pre>
                      <code>{JSON.stringify(contacts, null, 2)}</code>
                    </pre>
                  </div>
                  <h3>Delete:</h3>
                  <div className="delete-contact-wrapper">
                    <label htmlFor="deleteContact" className="form-label">
                      Uid to delete:
                    </label>
                    <input
                      name="deleteContact"
                      type="text"
                      id="deleteContact"
                      className="form-control mb-1 p-1"
                      placeholder="Uid"
                      onChange={(e) => {
                        setDeleteUid(e.target.value);
                      }}
                    />
                    <button
                      className={styles.profile_button}
                      type="button"
                      onClick={() =>
                        deleteContact(uid, deleteUid).then(({ data, err }) => {
                          if (data) {
                            const updatedContacts = Object.keys(
                              contacts
                            ).reduce((object, key) => {
                              if (key !== data) {
                                // eslint-disable-next-line no-param-reassign
                                object[key] = contacts[key];
                              }
                              return object;
                            }, {});

                            setContacts(updatedContacts);
                          } else {
                            // setError(err
                            console.log(err);
                          }
                        })
                      }
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="col-sm-6">
                  <h3>Create new:</h3>
                  <div className="form-control-wrapper">
                    <label htmlFor="label" className="form-label">
                      Emergency Contact Label:
                    </label>
                    <input
                      name="label"
                      type="text"
                      id="label"
                      className="form-control mb-1 p-1"
                      placeholder="e.g. Primary"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="name" className="form-label">
                      Name:
                    </label>
                    <input
                      name="name"
                      type="text"
                      id="name"
                      className="form-control mb-1 p-1"
                      placeholder="Mike Smith"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="email" className="form-label">
                      Email:
                    </label>
                    <input
                      name="email"
                      type="email"
                      id="email"
                      className="form-control mb-1 p-1"
                      placeholder="mike@example.com"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="phone" className="form-label">
                      Phone Number:
                    </label>
                    <input
                      name="phone"
                      type="tel"
                      id="phone"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      className="form-control mb-1 p-1"
                      placeholder="111-222-3333"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="street" className="form-label">
                      Street Address:
                    </label>
                    <input
                      name="address.street"
                      type="text"
                      id="street"
                      className="form-control mb-1 p-1"
                      placeholder="1 Memorial Stadium"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="city" className="form-label">
                      City:
                    </label>
                    <input
                      name="address.city"
                      type="text"
                      id="city"
                      className="form-control mb-1 p-1"
                      placeholder="Lincoln"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="state" className="form-label">
                      State:
                    </label>
                    <input
                      name="address.state"
                      type="text"
                      id="state"
                      className="form-control mb-1 p-1"
                      placeholder="NE"
                      onBlur={(e) => handleChange(e)}
                    />
                    <label htmlFor="zip" className="form-label">
                      Zip Code:
                    </label>
                    <input
                      name="address.zip"
                      type="text"
                      id="zip"
                      className="form-control mb-1 p-1"
                      placeholder="68510"
                      onBlur={(e) => handleChange(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className={styles.profile_button}
                    onClick={() =>
                      createContact(uid, createContactForm).then((result) => {
                        if (result.data) {
                          const newContacts = { ...result.data, ...contacts };
                          setContacts(newContacts);
                        } else {
                          // setError(err)
                        }
                      })
                    }
                  >
                    Create
                  </button>
                </div>
              </div> */}

        {/* </TabPanel> */}

        {/* </Tabs> */}
        {/* </div> */}
      </div>
      {/* <div className="max-w-screen-lg my-12 mx-auto">
        {(platformRole === 'superadmin' || platformRole === 'admin') && (
          <div className="mt-12">
            <h2 className="text-2xl mb-4">View and Edit Profile Roles</h2>
            <table className="table-auto shadow-lg bg-white">
              <thead>
                <tr>
                  <th className="bg-blue-100 border text-left px-8 py-4">
                    Email
                  </th>
                  <th className="bg-blue-100 border text-left px-8 py-4">
                    Display Name
                  </th>
                  <th className="bg-blue-100 border text-left px-8 py-4">
                    Created
                  </th>
                  <th className="bg-blue-100 border text-left px-8 py-4">
                    Platform Role
                  </th>
                </tr>
              </thead>
              <tbody>
                {queryResult?.data ? (
                  Object.keys(queryResult.data).map((userId) => {
                    const profile = queryResult.data[userId];
                    return (
                      <tr key={userId}>
                        <td className="border px-8 py-4">{profile.email}</td>
                        <td className="border px-8 py-4">
                          {profile.displayName}
                        </td>
                        <td className="border px-8 py-4">
                          {new Intl.DateTimeFormat('en-US')
                            .format(profile.createdAt.toDate())
                            .toString()}
                        </td>
                        <td className="border px-8 py-4">
                          {profile.platformRole}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="border px-8 py-4" />
                    <td className="border px-8 py-4" />
                    <td className="border px-8 py-4" />
                    <td className="border px-8 py-4" />
                  </tr>
                )}
              </tbody>
            </table>
            <button
              type="button"
              className="bg-gray-600 hover:bg-gray-700 text-gray-200 rounded mt-8 w-52"
              onClick={() => {
                const query = getPrevPaginatedQuery(
                  REF,
                  FIELD,
                  PAGESIZE,
                  queryResult.first
                );
                listUserProfiles(query).then((result) => {
                  if (result.data) {
                    setQueryResult(result);
                  } else {
                    // setError(result.err)
                  }
                });
              }}
            >
              Get Prev Page
            </button>
            <button
              type="button"
              className="bg-green-600 hover:bg-green-700 text-green-200 rounded mt-8 w-52 ml-8"
              onClick={() => {
                const query = getNextPaginatedQuery(
                  REF,
                  FIELD,
                  PAGESIZE,
                  queryResult.last
                );
                listUserProfiles(query).then((result) => {
                  if (result.data) {
                    setQueryResult(result);
                  } else {
                    // setError(result.err)
                  }
                });
              }}
            >
              Get Next Page
            </button>

            <div>
              <h2 className="text-2xl mt-8 mb-4">
                Search for a Profile by Email
              </h2>
              <form>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Profile Email
                </label>
                <div className="flex max-w-screen-sm">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded rounded-r-none m-0"
                    placeholder="shotdeck@gmail.com"
                  />
                  <button
                    type="submit"
                    className="bg-gray-600 hover:bg-gray-700 text-gray-200 rounded rounded-l-none m-0"
                    onClick={(event) => {
                      event.preventDefault();
                      const email = document.getElementById('email').value;
                      getProfileByEmail(email).then((result) => {
                        if (result.data) {
                          console.log('Search Result:', result.data);
                        } else {
                          // setError(result.err)
                          console.log('Search Result:', result.error);
                        }
                      });
                    }}
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>  */}
    </div>
  );
};

export default Profile;
